import { mergeAttributes, Node } from "@tiptap/core";
import { DOMOutputSpec, Node as ProseMirrorNode } from "@tiptap/pm/model";
import { PluginKey } from "@tiptap/pm/state";
import { Suggestion, SuggestionOptions } from "./suggestions";

export type MentionOptions = {
  HTMLAttributes: Record<string, any>;
  /** @deprecated use renderText and renderHTML instead  */
  renderLabel?: (props: {
    options: MentionOptions;
    node: ProseMirrorNode;
  }) => string;
  renderText: (props: {
    options: MentionOptions;
    node: ProseMirrorNode;
  }) => string;
  renderHTML: (props: {
    options: MentionOptions;
    node: ProseMirrorNode;
  }) => DOMOutputSpec;
  suggestion: Omit<SuggestionOptions, "editor">;
};

export const createCustomCommand = ({ name }: { name: string }) => {
  const CustomCommandPluginKey = new PluginKey(name);
  return Node.create<MentionOptions>({
    name: name,
    addOptions() {
      return {
        HTMLAttributes: {},
        renderText({ options, node }) {
          return node.attrs.label ?? node.attrs.id;
        },
        renderHTML({ options, node }) {
          return [
            "span",
            this.HTMLAttributes,
            node.attrs.label ?? node.attrs.id,
          ];
        },
        suggestion: {
          pluginKey: CustomCommandPluginKey,
          command: ({ editor, range, props }) => {
            console.log("command props", editor, range, props);
            // increase range.to by one when the next node is of type "text"
            // and starts with a space character
            const nodeAfter = editor.view.state.selection.$to.nodeAfter;
            const overrideSpace = nodeAfter?.text?.startsWith(" ");

            if (overrideSpace) {
              range.to += 1;
            }

            let selectionRange;
            if (props.id.includes("<")) {
              selectionRange = {
                from: range.from + props.id.indexOf("<"),
                to: range.from + props.id.indexOf(">") + 1,
              };
            } else {
              selectionRange = {
                from: range.from + props.id.length,
                to: range.from + props.id.length,
              };
            }

            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: "text",
                  text: props.id,
                },
                {
                  type: "text",
                  text: " ",
                },
              ])
              .setTextSelection(selectionRange ?? range.to + 1)
              .run();

            // window.getSelection()?.collapseToEnd();
          },
          // allow: ({ state, range }) => {
          //   const $from = state.doc.resolve(range.from);
          //   const type = state.schema.nodes[this.name];
          //   const allow = !!$from.parent.type.contentMatch.matchType(type);
          //
          //   return allow;
          // },
          startOfLine: true,
        },
      };
    },

    group: "inline",

    inline: true,

    selectable: true,

    atom: true,

    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-id"),
          renderHTML: (attributes) => {
            if (!attributes.id) {
              return {};
            }

            return {
              "data-id": attributes.id,
            };
          },
        },

        label: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-label"),
          renderHTML: (attributes) => {
            if (!attributes.label) {
              return {};
            }

            return {
              "data-label": attributes.label,
            };
          },
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: `span[data-type="${this.name}"]`,
        },
      ];
    },

    renderHTML({ node, HTMLAttributes }) {
      if (this.options.renderLabel !== undefined) {
        console.warn(
          "renderLabel is deprecated use renderText and renderHTML instead"
        );
        return [
          "span",
          mergeAttributes(
            { "data-type": this.name },
            this.options.HTMLAttributes,
            HTMLAttributes
          ),
          this.options.renderLabel({
            options: this.options,
            node,
          }),
        ];
      }
      const html = this.options.renderHTML({
        options: this.options,
        node,
      });

      if (typeof html === "string") {
        return [
          "span",
          mergeAttributes(
            { "data-type": this.name },
            this.options.HTMLAttributes,
            HTMLAttributes
          ),
          html,
        ];
      }
      return html;
    },

    renderText({ node }) {
      if (this.options.renderLabel !== undefined) {
        console.warn(
          "renderLabel is deprecated use renderText and renderHTML instead"
        );
        return this.options.renderLabel({
          options: this.options,
          node,
        });
      }
      return this.options.renderText({
        options: this.options,
        node,
      });
    },

    addKeyboardShortcuts() {
      return {
        Backspace: () =>
          this.editor.commands.command(({ tr, state }) => {
            let isMention = false;
            const { selection } = state;
            const { empty, anchor } = selection;

            if (!empty) {
              return false;
            }

            state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
              if (node.type.name === this.name) {
                isMention = true;
                tr.insertText(
                  this.options.suggestion.chars?.[0] ?? "",
                  pos,
                  pos + node.nodeSize
                );

                return false;
              }
            });

            return isMention;
          }),
      };
    },

    addProseMirrorPlugins() {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ];
    },
  });
};
