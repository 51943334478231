import styled from "@emotion/styled";
import { styled as materialStyled } from "@mui/material/styles";
import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import SyntaxHighlighter from "react-syntax-highlighter";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Form, Formik } from "formik";
import { AppiumStatusResponse, DriverFormState } from "../../utils/types";
import { number, object, string } from "yup";
import FormikSelect from "../../formikComponents/formik-select";
import FormikTextInput from "../../formikComponents/formik-text-input";
import axios from "axios";
import { useSetAtom } from "jotai";
import { customAlertStateAtom } from "../../components/custom-alert";
import {
  findElementAtCoordinatesAndroid,
  findElementAtCoordinatesIOS,
  generateSwipeOrScrollAppiumCode,
  generateTapAppiumCodeForAndroid,
  generateTapAppiumCodeForiOS,
  getSlideDirectionAndPercentageFromAssistant,
  parseXml,
} from "../../utils/helpers";
import CropScreenshotDialog from "../../components/cropScreenshotDialog";
import { useAtomValue } from "jotai/index";
import { companyDataAtom } from "../test-run/test-run-atoms";
import { CameraAlt } from "@mui/icons-material";
import { AuthContext } from "../../auth/AuthContext";

interface AppiumSessionData {
  sessionId: string;
  port: number;
  platform: "Android" | "iOS";
  deviceName?: string;
  deviceSize: {
    height: number;
    width: number;
  };
}

const SessionAssistant = () => {
  const simulatorRef = useRef<HTMLDivElement>(null);
  const [generatedCode, setGeneratedCode] = useState<string | null>(null);

  const [appiumSessionData, setAppiumSessionData] =
    useState<AppiumSessionData | null>(null);
  const clickMutation = useMutation({
    mutationKey: ["clickMutation", appiumSessionData],
    mutationFn: async ({ x, y }: { x: number; y: number }): Promise<void> => {
      const elementTreeResponse = await axios.get(
        `http://localhost:${appiumSessionData!.port}/session/${
          appiumSessionData!.sessionId
        }/source`
      );

      const xmlString = elementTreeResponse.data.value;
      const xmlDoc = parseXml(xmlString);
      if (xmlDoc.querySelector("parsererror") != null) {
        throw new Error(
          `Error parsing XML: ${
            xmlDoc.querySelector("parsererror")!.textContent
          }`
        );
      }

      const clickedElement =
        appiumSessionData?.platform === "Android"
          ? findElementAtCoordinatesAndroid(xmlDoc, x, y)
          : findElementAtCoordinatesIOS(xmlDoc, x, y);

      const generatedCode: string =
        appiumSessionData?.platform === "Android"
          ? generateTapAppiumCodeForAndroid(clickedElement)
          : generateTapAppiumCodeForiOS(clickedElement);

      await axios.post(
        `http://localhost:${appiumSessionData!.port}/session/${
          appiumSessionData!.sessionId
        }/actions`,
        {
          actions: [
            {
              type: "pointer",
              id: "finger1",
              parameters: { pointerType: "touch" },
              actions: [
                { type: "pointerMove", duration: 0, x, y },
                { type: "pointerDown", button: 0 },
                { type: "pause", duration: 200 },
                { type: "pointerUp", button: 0 },
              ],
            },
          ],
        }
      );

      setGeneratedCode((prev) => {
        if (prev != null) {
          return `${prev}\n\n${generatedCode}`;
        }
        return generatedCode;
      });
    },
  });

  const swipeMutation = useMutation({
    mutationKey: ["swipeMutation", appiumSessionData],
    mutationFn: async ({
      startX,
      startY,
      endX,
      endY,
      duration,
    }: {
      startX: number;
      startY: number;
      endX: number;
      endY: number;
      duration: number;
    }): Promise<void> => {
      const generatedCode = generateSwipeOrScrollAppiumCode({
        startX,
        startY,
        endX,
        endY,
        duration,
        deviceHeight: appiumSessionData!.deviceSize.height,
        deviceWidth: appiumSessionData!.deviceSize.width,
      });

      await axios.post(
        `http://localhost:${appiumSessionData!.port}/session/${
          appiumSessionData!.sessionId
        }/actions`,
        {
          actions: [
            {
              type: "pointer",
              id: "finger1",
              parameters: { pointerType: "touch" },
              actions: [
                { type: "pointerMove", duration: 0, x: startX, y: startY },
                { type: "pointerDown", button: 0 },
                { type: "pointerMove", duration, x: endX, y: endY }, // Duration can be adjusted to control swipe speed
                { type: "pointerUp", button: 0 },
              ],
            },
          ],
        }
      );

      setGeneratedCode((prev) => {
        if (prev != null) {
          return `${prev}\n\n${generatedCode}`;
        }
        return generatedCode;
      });
    },
  });

  const getSessionScreenshotQuery = useQuery<string>({
    queryKey: ["getSessionScreenshotQuery"],
    queryFn: async () => {
      // Fetch screenshot
      const screenshotResponse = await axios.get(
        `http://localhost:${appiumSessionData!.port}/session/${
          appiumSessionData!.sessionId
        }/screenshot`
      );

      const screenshotData = await screenshotResponse.data;
      return `data:image/png;base64,${screenshotData.value}`;
    },
    enabled: !!appiumSessionData,
    refetchInterval: 500,
  });

  const isSwipingRef = useRef(false);
  const startXRef = useRef<number>(0);
  const startYRef = useRef<number>(0);
  const startTimeRef = useRef<number>(0); // New ref to store swipe start time

  useEffect(() => {
    const clickListener = (event: MouseEvent) => {
      console.log(event);

      const rect = simulatorRef.current!.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const xPercent = x / rect.width;
      const yPercent = y / rect.height;

      const deviceX = Math.round(
        xPercent * appiumSessionData!.deviceSize.width
      );
      const deviceY = Math.round(
        yPercent * appiumSessionData!.deviceSize.height
      );

      clickMutation.mutate({ x: deviceX, y: deviceY });
    };

    const swipeStartListener = (event: MouseEvent | TouchEvent) => {
      const rect = simulatorRef.current!.getBoundingClientRect();
      const startEvent = event instanceof MouseEvent ? event : event.touches[0];

      startXRef.current = startEvent.clientX - rect.left;
      startYRef.current = startEvent.clientY - rect.top;

      isSwipingRef.current = false;
      startTimeRef.current = Date.now(); // Capture the start time
    };

    const swipeMoveListener = (event: MouseEvent | TouchEvent) => {
      isSwipingRef.current = true;
    };

    const swipeEndListener = (event: MouseEvent | TouchEvent) => {
      if (!isSwipingRef.current) {
        clickListener(event as MouseEvent);
        return;
      }

      const rect = simulatorRef.current!.getBoundingClientRect();
      const endEvent =
        event instanceof MouseEvent ? event : event.changedTouches[0];

      const endX = endEvent.clientX - rect.left;
      const endY = endEvent.clientY - rect.top;

      const duration = Date.now() - startTimeRef.current; // Calculate duration

      const startXPercent = startXRef.current / rect.width;
      const startYPercent = startYRef.current / rect.height;
      const endXPercent = endX / rect.width;
      const endYPercent = endY / rect.height;

      const startDeviceX = Math.round(
        startXPercent * appiumSessionData!.deviceSize.width
      );
      const startDeviceY = Math.round(
        startYPercent * appiumSessionData!.deviceSize.height
      );
      const endDeviceX = Math.round(
        endXPercent * appiumSessionData!.deviceSize.width
      );
      const endDeviceY = Math.round(
        endYPercent * appiumSessionData!.deviceSize.height
      );

      swipeMutation.mutate({
        startX: startDeviceX,
        startY: startDeviceY,
        endX: endDeviceX,
        endY: endDeviceY,
        duration, // Pass calculated duration
      });
    };

    if (
      simulatorRef.current != null &&
      getSessionScreenshotQuery.data != null &&
      appiumSessionData != null
    ) {
      simulatorRef.current.addEventListener("click", clickListener);
      simulatorRef.current.addEventListener("mousedown", swipeStartListener);
      simulatorRef.current.addEventListener("mousemove", swipeMoveListener);
      simulatorRef.current.addEventListener("mouseup", swipeEndListener);
      simulatorRef.current.addEventListener("touchstart", swipeStartListener);
      simulatorRef.current.addEventListener("touchmove", swipeMoveListener);
      simulatorRef.current.addEventListener("touchend", swipeEndListener);
    }

    return () => {
      simulatorRef.current?.removeEventListener("click", clickListener);
      simulatorRef.current?.removeEventListener(
        "mousedown",
        swipeStartListener
      );
      simulatorRef.current?.removeEventListener("mousemove", swipeMoveListener);
      simulatorRef.current?.removeEventListener("mouseup", swipeEndListener);
      simulatorRef.current?.removeEventListener(
        "touchstart",
        swipeStartListener
      );
      simulatorRef.current?.removeEventListener("touchmove", swipeMoveListener);
      simulatorRef.current?.removeEventListener("touchend", swipeEndListener);
    };
  }, [clickMutation, getSessionScreenshotQuery.data, appiumSessionData]);

  const [openCropScreenshotDialog, setOpenCropScreenshotDialog] =
    useState(false);
  const [cropScreenshot, setCropScreenshot] = useState<string>("");
  const { user } = useContext<any>(AuthContext);

  const {
    data: companyData,
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
    error: errorCompany,
  } = useAtomValue(companyDataAtom(user.companyId));

  const saveScreenshot = useCallback(async () => {
    setOpenCropScreenshotDialog(true);

    const lastScreenshot = getSessionScreenshotQuery.data;
    setCropScreenshot(lastScreenshot);
  }, [getSessionScreenshotQuery.data]);

  return (
    <>
      {companyData != null && (
        <CropScreenshotDialog
          open={openCropScreenshotDialog}
          setOpen={setOpenCropScreenshotDialog}
          imageSrc={cropScreenshot}
          companyData={companyData}
          deviceName={appiumSessionData?.deviceName ?? "--"}
        />
      )}
      <AssistantContent>
        {getSessionScreenshotQuery.data != null ? (
          <ImageDivWrapper>
            {companyData != null && (
              <ActionButton onClick={saveScreenshot}>
                <CameraAlt />
              </ActionButton>
            )}
            <CustomImage
              ref={simulatorRef}
              src={getSessionScreenshotQuery.data}
            />
          </ImageDivWrapper>
        ) : (
          <SimulatorWrapper>
            <Typography variant={"caption"} textAlign={"center"}>
              Start session to start the assistant
            </Typography>
          </SimulatorWrapper>
        )}
        <ContentWrapper>
          <DeviceConfig
            setAppiumSessionData={setAppiumSessionData}
            setGeneratedCode={setGeneratedCode}
          />
          <GeneratedWrapper
            center={
              clickMutation.isPending ||
              swipeMutation.isPending ||
              generatedCode == null
            }
          >
            {(clickMutation.isPending || swipeMutation.isPending) && (
              <CircularProgress />
            )}
            {generatedCode != null &&
              !clickMutation.isPending &&
              !swipeMutation.isPending && (
                <SyntaxHighlighter
                  customStyle={{
                    minHeight: "100%",
                    background: "white",
                  }}
                  language="javascript"
                  style={stackoverflowLight}
                  // wrapLines={true}
                  // wrapLongLines={true}
                >
                  {generatedCode}
                </SyntaxHighlighter>
              )}
            {generatedCode == null &&
              !clickMutation.isPending &&
              !swipeMutation.isPending && (
                <Typography>
                  Interact with the simulator to generate code
                </Typography>
              )}
          </GeneratedWrapper>
        </ContentWrapper>
      </AssistantContent>
    </>
  );
};

const DeviceConfig = ({
  setAppiumSessionData,
  setGeneratedCode,
}: {
  setAppiumSessionData: Dispatch<SetStateAction<AppiumSessionData | null>>;
  setGeneratedCode: Dispatch<SetStateAction<string | null>>;
}) => {
  return (
    <DeviceConfigWrapper>
      <Formik<DriverFormState>
        initialValues={{
          platform: "iOS",
          platformVersion: "17.5",
          deviceName: "iPhone 15 Pro",
          port: 4723,
        }}
        validationSchema={object({
          platform: string().required(),
          platformVersion: string().nullable(),
          deviceName: string().nullable(),
          port: number().required(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
        }}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({ values, setValues }) => (
          <Form>
            <div className={"pl-4 flex flex-col gap-3"}>
              <FormikSelect
                name={"platform"}
                key={"platform"}
                label={"Platform"}
                options={["Android", "iOS"]}
              />
              {values.platform === "iOS" && (
                <>
                  <FormikTextInput
                    name={`platformVersion`}
                    label={"OS"}
                    type={"text"}
                    required
                  />
                  <FormikTextInput
                    name={`deviceName`}
                    label={"Device Name"}
                    type={"text"}
                    required
                  />
                </>
              )}
              <FormikTextInput
                name={`port`}
                label={"Appium Server Port"}
                type={"text"}
                required
              />
              <ConfigButtons
                setGeneratedCode={setGeneratedCode}
                setAppiumSessionData={setAppiumSessionData}
                values={values}
              />
            </div>
          </Form>
        )}
      </Formik>
    </DeviceConfigWrapper>
  );
};

const ConfigButtons = ({
  values,
  setAppiumSessionData,
  setGeneratedCode,
}: {
  values: DriverFormState;
  setAppiumSessionData: Dispatch<SetStateAction<AppiumSessionData | null>>;
  setGeneratedCode: Dispatch<SetStateAction<string | null>>;
}) => {
  const setCustomAlertState = useSetAtom(customAlertStateAtom);
  const queryClient = useQueryClient();

  const appiumServerCheckStatusQuery = useQuery<AppiumStatusResponse>({
    queryKey: ["appiumServerCheckStatusQuery", values.port],
    queryFn: async () => {
      return (
        await axios.get(`http://localhost:${values.port ?? "4723"}/status`)
      ).data;
    },
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchInterval: 20000,
  });

  const startSessionMutation = useMutation({
    mutationKey: ["startAssistantSession"],
    mutationFn: async () => {
      const response = await axios.post(
        `http://localhost:${values.port}/session`,
        {
          capabilities: {
            alwaysMatch: {
              platformName: values.platform,
              "appium:automationName":
                values.platform === "iOS" ? "XCUITest" : "UiAutomator2",
              ...(values.platform === "iOS" && {
                "appium:deviceName": values.deviceName,
                "appium:platformVersion": values.platformVersion,
              }),
            },
          },
        }
      );

      const rectResponse = await axios.get(
        `http://localhost:${values.port}/session/${response.data.value.sessionId}/window/rect`
      );

      setAppiumSessionData({
        sessionId: response.data.value.sessionId,
        port: values.port,
        ...(values.platform === "iOS" && {
          deviceName: values.deviceName,
        }),
        deviceSize: {
          width: rectResponse.data.value.width,
          height: rectResponse.data.value.height,
        },
        platform: values.platform,
      });

      return response.data;
    },
    onError: (error) => {
      console.log("error", error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        maintainDisplayed: true,
        description:
          error?.response?.data?.value?.message ??
          "An error occurred trying to start session",
      });
    },
  });

  const stopSessionMutation = useMutation({
    mutationKey: ["stopAssistantSession"],
    mutationFn: async ({ appiumSessionId }: { appiumSessionId: string }) => {
      const response = await axios.delete(
        `http://localhost:${values.port}/session/${appiumSessionId}`
      );

      startSessionMutation.reset();
      setAppiumSessionData(null);
      setGeneratedCode(null);
      queryClient.removeQueries({ queryKey: ["getSessionScreenshotQuery"] });

      return response.data;
    },
  });

  const isServerReady =
    appiumServerCheckStatusQuery.data?.value?.ready ?? false;

  return (
    <ConfigButtonsWarningWrapper>
      <ConfigButtonsWrapper>
        <Button
          onClick={() => startSessionMutation.mutate()}
          disabled={!isServerReady || startSessionMutation.isSuccess}
          variant={"contained"}
        >
          Start Session
        </Button>
        <Button
          variant={"contained"}
          color={"error"}
          disabled={!startSessionMutation.isSuccess}
          onClick={() =>
            stopSessionMutation.mutate({
              appiumSessionId: startSessionMutation.data.value.sessionId,
            })
          }
        >
          Stop Session
        </Button>
      </ConfigButtonsWrapper>
      {!isServerReady && (
        <Alert severity="warning">
          Please start an appium server on the specified port
        </Alert>
      )}
    </ConfigButtonsWarningWrapper>
  );
};

const ImageDivWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const ActionButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  padding: 16px;
  border-radius: 9999px;
  background-color: #4c63ff;

  &:disabled {
    color: #455a64;
    cursor: not-allowed;
    background-color: #bdbdbd;
  }

  &:hover {
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }
`;

const CustomImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const ConfigButtonsWarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;

const ConfigButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

// @ts-ignore
const AssistantContent = styled.div(() => ({
  display: "grid",
  gridArea: "content-body",
  gridTemplateAreas: '"simulator-preview generated"',
  gridTemplateColumns: "1fr 3fr",
  gap: "2rem",
  height: "100%",
  padding: "2rem",
}));

const SimulatorWrapper = styled.div`
  grid-area: simulator-preview;
  border-radius: 8px;
  display: grid;
  place-content: center;
  border: 1px solid black;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "config"
    "generated";
  grid-template-rows: auto minmax(0, 1fr);
  gap: 1rem;
  overflow-y: auto;
`;

const DeviceConfigWrapper = materialStyled(Paper)(() => ({
  gridArea: "config",
  padding: "16px",
  paddingTop: "24px",
}));

const GeneratedWrapper = styled.div(({ center }: { center: boolean }) => ({
  overflowY: "auto",
  backgroundColor: "white",
  ...(center && { placeContent: "center", display: "grid" }),
}));

export default SessionAssistant;
