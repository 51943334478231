import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from "react";
import { DeviceScreen, UIElement, UIElementBounds } from "./models";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { appetizeClientAtom, sessionAtom } from "../../atoms";
import { SettingsData } from "../../utils/types";

export interface ElementHint {
  text: string;
  isResourceId: boolean;
}

interface DeviceContextType {
  isLoading: boolean;
  hoveredElement: UIElement | null;
  setHoveredElement: (element: UIElement | null) => void;
  inspectedElement: UIElement | null;
  setInspectedElement: (id: UIElement | null) => void;
  deviceScreen: DeviceScreen | undefined;
  footerHint: ElementHint | null;
  setFooterHint: (footer: ElementHint | null) => void;
  currentCommandValue: string;
  setCurrentCommandValue: (id: string) => void;
}

type ElementFilter = (nodes: TreeNode[]) => TreeNode[];
type ElementLookupPredicate = (node: TreeNode) => boolean;

interface TreeNode {
  attributes?: { [key: string]: string };
  children?: TreeNode[];
  clickable?: boolean | null;
  enabled?: boolean | null;
  focused?: boolean | null;
  checked?: boolean | null;
  selected?: boolean | null;
  bounds?: UIElementBounds;
  path: string[];
}

interface UiElement {
  bounds: { x: number; y: number; width: number; height: number };
  treeNode: TreeNode;
  distanceTo(other: UiElement): number;
}

const Filters = {
  INDEX_COMPARATOR: (a: TreeNode, b: TreeNode) => {
    const aBounds = a?.bounds;
    const bBounds = b?.bounds;
    const yCompare =
      (aBounds?.y ?? Number.MAX_VALUE) - (bBounds?.y ?? Number.MAX_VALUE);
    if (yCompare !== 0) return yCompare;
    return (aBounds?.x ?? Number.MAX_VALUE) - (bBounds?.x ?? Number.MAX_VALUE);
  },

  intersect(filters: ElementFilter[]): ElementFilter {
    return (nodes: TreeNode[]) => {
      const intersected = filters
        .map((filter) => new Set(filter(nodes)))
        .reduce((a, b) => new Set([...a].filter((x) => b.has(x))));
      return Array.from(intersected) || nodes;
    };
  },

  compose(first: ElementFilter, second: ElementFilter): ElementFilter {
    return Filters.compose2([first, second]);
  },

  compose2(filters: ElementFilter[]): ElementFilter {
    return (nodes: TreeNode[]) =>
      filters.reduce((acc, filter) => filter(acc), nodes);
  },

  asFilter(predicate: ElementLookupPredicate): ElementFilter {
    return (nodes: TreeNode[]) => nodes.filter(predicate);
  },

  nonClickable(): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.clickable === false);
  },

  textMatches(regex: RegExp): ElementFilter {
    return (nodes: TreeNode[]) => {
      const filterText = (attr: string) =>
        nodes.filter((node) => {
          const value = node.attributes?.[attr];
          const strippedValue = value?.replace(/\n/g, " ");
          return value && (regex.test(value) || regex.test(strippedValue));
        });

      const textMatches = filterText("text");
      const hintTextMatches = filterText("hintText");
      const accessibilityTextMatches = filterText("accessibilityText");

      return Array.from(
        new Set([
          ...textMatches,
          ...hintTextMatches,
          ...accessibilityTextMatches,
        ])
      );
    };
  },

  idMatches(regex: RegExp): ElementFilter {
    return (nodes: TreeNode[]) => {
      const exactMatches = nodes.filter(
        (node) =>
          node.attributes?.["resource-id"] &&
          regex.test(node.attributes["resource-id"])
      );
      const idWithoutPrefixMatches = nodes.filter(
        (node) =>
          node.attributes?.["resource-id"] &&
          regex.test(node.attributes["resource-id"].split("/").pop() || "")
      );
      return Array.from(new Set([...exactMatches, ...idWithoutPrefixMatches]));
    };
  },

  sizeMatches(
    width: number | null = null,
    height: number | null = null,
    tolerance: number | null = null
  ): ElementLookupPredicate {
    return (node: TreeNode) => {
      const bounds = node?.bounds;
      const finalTolerance = tolerance ?? 0;
      if (!bounds) return false;

      if (width !== null && Math.abs(bounds.width - width) > finalTolerance)
        return false;
      if (height !== null && Math.abs(bounds.height - height) > finalTolerance)
        return false;

      return true;
    };
  },

  // below(otherFilter: ElementFilter): ElementFilter {
  //   return Filters.relativeTo(
  //     otherFilter,
  //     (it, other) => it.bounds.y > other.bounds.y
  //   );
  // },
  //
  // above(otherFilter: ElementFilter): ElementFilter {
  //   return Filters.relativeTo(
  //     otherFilter,
  //     (it, other) => it.bounds.y < other.bounds.y
  //   );
  // },
  //
  // leftOf(otherFilter: ElementFilter): ElementFilter {
  //   return Filters.relativeTo(
  //     otherFilter,
  //     (it, other) => it.bounds.x < other.bounds.x
  //   );
  // },
  //
  // rightOf(otherFilter: ElementFilter): ElementFilter {
  //   return Filters.relativeTo(
  //     otherFilter,
  //     (it, other) => it.bounds.x > other.bounds.x
  //   );
  // },

  // relativeTo(
  //   otherFilter: ElementFilter,
  //   predicate: (it: UiElement, other: UiElement) => boolean
  // ): ElementFilter {
  //   return (nodes: TreeNode[]) => {
  //     const matchingOthers = otherFilter(nodes)
  //       .map((node) => node.toUiElementOrNull())
  //       .filter(Boolean);
  //
  //     return nodes
  //       .map((node) => node.toUiElementOrNull())
  //       .filter(Boolean)
  //       .flatMap((element) =>
  //         matchingOthers
  //           .filter((other) => predicate(element!, other!))
  //           .map((other) => ({
  //             element,
  //             distance: element!.distanceTo(other!),
  //           }))
  //       )
  //       .sort((a, b) => a.distance - b.distance)
  //       .map(({ element }) => element.treeNode);
  //   };
  // },

  containsChild(other: UiElement): ElementLookupPredicate {
    return (node: TreeNode) => node.children?.includes(other.treeNode) ?? false;
  },

  containsDescendants(filters: ElementFilter[]): ElementFilter {
    return (nodes: TreeNode[]) => {
      return nodes.filter((node) =>
        filters.every((filter) =>
          node.children?.some((child) => filter([child]).length > 0)
        )
      );
    };
  },

  hasText(): ElementLookupPredicate {
    return (node: TreeNode) => !!node.attributes?.["text"];
  },

  // isSquare(): ElementLookupPredicate {
  //   return (node: TreeNode) => {
  //     const element = node.toUiElementOrNull();
  //     if (!element) return false;
  //     return (
  //       Math.abs(1.0 - element.bounds.width / element.bounds.height) < 0.03
  //     );
  //   };
  // },

  hasLongText(): ElementLookupPredicate {
    return (node: TreeNode) => (node.attributes?.["text"]?.length ?? 0) > 200;
  },

  index(idx: number): ElementFilter {
    return (nodes: TreeNode[]) =>
      [nodes.sort(Filters.INDEX_COMPARATOR)[idx]].filter(Boolean);
  },

  clickableFirst(): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.sort((a, b) => Number(b.clickable) - Number(a.clickable));
  },

  enabled(expected: boolean): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.enabled === expected);
  },

  selected(expected: boolean): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.selected === expected);
  },

  checked(expected: boolean): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.checked === expected);
  },

  focused(expected: boolean): ElementFilter {
    return (nodes: TreeNode[]) =>
      nodes.filter((node) => node.focused === expected);
  },

  deepestMatchingElement(filter: ElementFilter): ElementFilter {
    return (nodes: TreeNode[]) =>
      filter(nodes).map((node) => {
        const matchingChildren = Filters.deepestMatchingElement(filter)(
          node.children ?? []
        );
        return matchingChildren[matchingChildren.length - 1] || node;
      });
  },
};

const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

interface DeviceProviderProps {
  children: ReactNode;
  defaultInspectedElement?: UIElement;
  settingsData: SettingsData;
}

function createElementId(uiElement: UIElement, ids: Map<string, number>) {
  const parts = [
    uiElement.resourceId,
    uiElement.resourceIdIndex,
    uiElement.text,
    uiElement.textIndex,
  ].filter(Boolean);
  const fallbackId = uiElement.bounds
    ? `${uiElement.bounds.x},${uiElement.bounds.y},${uiElement.bounds.width},${uiElement.bounds.height}`
    : crypto.randomUUID();

  const id = parts.length === 0 ? fallbackId : parts.join("-");

  const index = ids.has(id) ? ids.get(id)! + 1 : 1;
  ids.set(id, index);

  return index === 1 ? id : `${id}-${index}`;
}

const gatherElements = (tree: TreeNode, list: TreeNode[]): TreeNode[] => {
  if (tree.children) {
    tree.children.forEach((child: any) => {
      gatherElements(child, list);
    });
  }
  list.push(tree);
  return list;
};

export const DeviceProvider: React.FC<DeviceProviderProps> = ({
  children,
  defaultInspectedElement = null,
  settingsData,
}) => {
  const session = useAtomValue(sessionAtom);
  const client = useAtomValue(appetizeClientAtom);
  const {
    data,
    isLoading,
    error: fetchError,
  } = useQuery<DeviceScreen>({
    queryKey: ["deviceScreen"],
    queryFn: async () => {
      try {
        if (session?.data != null && settingsData.useAppetizeCommands) {
          const unparsedUi = await session.data.getUI();
          const elements = gatherElements(unparsedUi.at(0), []).sort(
            Filters.INDEX_COMPARATOR
          );

          const uiElements = elements.map<UIElement>((element) => {
            const bounds = element.bounds;
            const text = element?.attributes?.["text"];
            const path = element.path;
            const hintText = element?.attributes?.["hintText"];
            const accessibilityText =
              element?.attributes?.["accessibilityText"];
            const resourceId = element?.attributes?.["resource-id"];

            const id = [path, resourceId, accessibilityText, text, hintText]
              .filter(Boolean)
              .join("-");

            return {
              id,
              bounds,
              text,
              hintText,
              accessibilityText,
              resourceId,
            };
          });

          return {
            width: session.data.device.screen.width,
            height: session.data.device.screen.height,
            devicePixelRatio: session.data.device.screen.devicePixelRatio,
            offset: session.data.device.embed.screen.offset,
            elements: uiElements,
          };
        } else {
          return {
            width: client.device.screen.width,
            height: client.device.screen.height,
            elements: [],
          };
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    enabled: client != null,
    refetchInterval: settingsData.useAppetizeCommands ? 1000 : false,
  });

  const [hoveredElement, setHoveredElement] = useState<UIElement | null>(null);
  const [inspectedElement, setInspectedElement] = useState<UIElement | null>(
    defaultInspectedElement
  );
  const [footerHint, setFooterHint] = useState<ElementHint | null>(null);
  const [currentCommandValue, setCurrentCommandValue] = useState<string>("");

  return (
    <DeviceContext.Provider
      value={{
        isLoading,
        hoveredElement,
        setHoveredElement,
        inspectedElement,
        setInspectedElement,
        footerHint,
        setFooterHint,
        deviceScreen: data,
        currentCommandValue,
        setCurrentCommandValue,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export const useDeviceContext = () => {
  const context = useContext(DeviceContext);
  if (context === undefined) {
    throw new Error("useDeviceContext must be used within a DeviceProvider");
  }
  return context;
};
