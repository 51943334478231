import { PlainCard } from "./settings_components";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { deleteTemplate } from "../utils/firebase_operations";
import { type TemplateWithLabel } from "../utils/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import RemoveButton from "./removeButton";
import { AuthContext } from "../auth/AuthContext";
import { Form, Formik } from "formik";
import { object, string, ValidationError } from "yup";
import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Tooltip,
} from "@mui/material";
import { getCompanies } from "../auth/operations";
import { FileCopy } from "@mui/icons-material";
import FormikSelect from "../formikComponents/formik-select";
import { transferTemplatesHandler } from "../firebase";
import { useSetAtom } from "jotai/index";
import { customAlertStateAtom } from "./custom-alert";
import { removeItemFromArray, trackSentryException } from "../utils/helpers";
import { useAtomValue } from "jotai";
import { companyDataAtom } from "../routes/test-run/test-run-atoms";

function TemplatesContent() {
  const queryClient = useQueryClient();
  const { user } = useContext<any>(AuthContext);

  const {
    data: companyData,
    isError,
    error,
    isLoading,
  } = useAtomValue(companyDataAtom(user.companyId));

  const handleDeletingTemplate = useCallback(
    async (imageUrl: string) => {
      try {
        await deleteTemplate(companyData?.name, imageUrl);

        await queryClient.setQueryData(
          ["getCompanyData", companyData!.organisationId],
          (oldData: any) => {
            const oldTemplates: TemplateWithLabel[] = oldData?.templates ?? [];
            const removedTemplateIndex = oldTemplates.findIndex(
              (template) => template.imageUrl === imageUrl
            );

            const newTemplates = removeItemFromArray(
              oldTemplates,
              removedTemplateIndex
            );

            return {
              ...oldData,
              templates: newTemplates,
            };
          }
        );

        toast.info(`Deleted template`);
      } catch (error: any) {
        trackSentryException(error);
        console.log("Error occurred during deleting template", error);
        toast.error("An error occurred during deleting template");
      }
    },
    [queryClient, companyData]
  );

  return (
    <>
      <div className="relative overflow-x-auto sm:rounded-lg h-full">
        <table className="w-full text-sm text-left text-gray-600">
          <thead className="text-xs text-gray-700 uppercase bg-sky-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Template image
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Label
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Device
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {companyData?.templates &&
              companyData.templates
                .reverse()
                .map((template: TemplateWithLabel, index: number) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-sky-50"
                    >
                      <td className="px-6 py-4 text-center">
                        <img
                          width={100}
                          src={template.imageUrl}
                          alt={"template_image"}
                        />
                      </td>
                      <td className="px-6 py-4 text-center">
                        {template.label}
                      </td>
                      <td className="px-6 py-4 text-center">
                        {template?.device ?? "--"}
                      </td>
                      <td className="px-6 py-4 text-center">
                        <div>
                          <RemoveButton
                            buttonTitle={"Remove"}
                            dialogTitle={`Deleting template ${template.label}`}
                            handleSubmit={() =>
                              handleDeletingTemplate(template.imageUrl)
                            }
                            innerButtonClassname={
                              "w-20 h-8 shadow bg-red-50 rounded-full font-medium text-center text-red-500 hover:bg-red-100 hover:text-red-600 hover:shadow-lg"
                            }
                            outerButtonClassname={
                              "w-20 h-8 shadow bg-red-50 rounded-full font-medium text-center text-red-500 hover:bg-red-100 hover:text-red-600 hover:shadow-lg"
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {(isError ||
          isLoading ||
          (!isLoading && companyData?.templates?.length === 0)) && (
          <div
            className={
              "flex flex-col h-full items-center justify-center w-full"
            }
          >
            {isLoading && <CircularProgress />}
            {isError && (
              <p className="mt-4 flex justify-center font-semibold text-red-400">
                An error occurred while fetching templates:{" "}
                {(error as any)?.message}
              </p>
            )}
            {!isLoading && companyData?.templates?.length === 0 && (
              <p className="font-bold flex items-center justify-center pt-8 pb-6">
                No templates found
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
}

function TemplatesPanel() {
  const { user } = useContext<any>(AuthContext);

  return (
    <PlainCard>
      <div className={"flex h-full"}>
        <div className={"flex-[4_1_0%]"}>
          <div className="grid grid-cols-2">
            <h1 className="text-3xl pl-4 pb-6 font-bold">Templates</h1>
          </div>
          <div className="pl-4 pr-4 overflow-auto h-[calc(100%-50.25px)]">
            <TemplatesContent />
          </div>
        </div>
        {user.role === "superUser" && <TransferTemplates />}
      </div>
    </PlainCard>
  );
}

const TransferTemplates = () => {
  const { user } = useContext<any>(AuthContext);
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["companyNames"],
    queryFn: () => getCompanies(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const companies = useMemo(() => {
    return data?.map((company) => company.name);
  }, [data]);

  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  return (
    <>
      {data != null && (
        <div className={"flex flex-col"}>
          <Tooltip title={"Transfer templates"}>
            <IconButton onClick={toggleDrawer} aria-label="delete" size="small">
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Drawer open={openDrawer} anchor={"right"} onClose={closeDrawer}>
            <div className={"flex-1 flex flex-col p-8 min-w-[420px]"}>
              <p className="text-xl pl-4 pb-6 font-bold">Transfer templates</p>
              <Formik
                initialValues={{
                  sourceCompany: user.companyName,
                  targetCompany: (data as any[]).at(-1).name,
                }}
                validationSchema={object()
                  .shape({
                    sourceCompany: string().required(),
                    targetCompany: string().required(),
                  })
                  .test(
                    "source-target",
                    "Source and Target companies must be different",
                    function (obj) {
                      const { sourceCompany, targetCompany } = obj;
                      if (sourceCompany === targetCompany) {
                        return new ValidationError(
                          "Source and Target companies must be different",
                          null,
                          "targetCompany"
                        );
                      }
                      return true;
                    }
                  )}
                onSubmit={async (values) => {
                  const sourceOrganisationId = data?.find(
                    (company) => company.name === values.sourceCompany
                  ).id;
                  const targetOrganisationId = data?.find(
                    (company) => company.name === values.targetCompany
                  ).id;

                  try {
                    await transferTemplatesHandler({
                      sourceOrganisationId,
                      targetOrganisationId,
                    });
                    setCustomAlertState({
                      open: true,
                      type: "success",
                      title: "Success",
                      description: "Templates transferred successfully",
                    });
                  } catch (e) {
                    trackSentryException(e);
                    setCustomAlertState({
                      open: true,
                      type: "error",
                      title: "Something went wrong",
                      description:
                        "An error occurred while transferring templates",
                    });
                  }
                  setOpenDrawer(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form className={"flex flex-col gap-5"}>
                    <FormikSelect
                      name={"sourceCompany"}
                      key={"sourceCompany"}
                      label={"Source"}
                      options={companies}
                    />
                    <FormikSelect
                      name={"targetCompany"}
                      key={"targetCompany"}
                      label={"Target"}
                      options={companies}
                    />
                    <Button
                      type={"submit"}
                      disabled={isSubmitting}
                      variant={"contained"}
                      startIcon={<FileCopy />}
                    >
                      Transfer
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Drawer>
        </div>
      )}
    </>
  );
};

export default TemplatesPanel;
