import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Box from "@mui/material/Box";

export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.autocompleteTemplate });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        backgroundColor: "white",
        ".MuiListItemIcon-root": {
          color: "black",
        },
        maxHeight: "256px",
        overflow: "auto",
      }}
    >
      {props.items.length ? (
        <List>
          {props.items.map((item, index) => (
            <ListItem disablePadding key={index}>
              <ListItemButton
                selected={index === selectedIndex}
                onClick={() => selectItem(index)}
              >
                {!item.imageUrl && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.title} />
                {item.imageUrl && (
                  <img className={"h-4 ml-2"} src={item.imageUrl} />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <div className="item">No result</div>
      )}
    </Box>
  );
});
