import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { customFirestore } from "../firebase";

async function getCompanyFromUsersTable(
  loggedUserEmail: string | undefined | null
): Promise<string | undefined> {
  // console.log("Getting company from Users collection for user", loggedUserEmail);
  const userQuery = query(
    collection(customFirestore, "users"),
    where("email", "==", loggedUserEmail)
  );
  const result = await getDocs(userQuery);

  if (!result.empty) {
    const snapshot = result.docs[0];
    const data = snapshot.data();
    return data.company !== "Not assigned" ? data.company : undefined;
  }

  return undefined;
}

const getCompanyFromEmailDomain = async (
  email: string
): Promise<string | undefined> => {
  // console.log(`Getting company from email domain: ${email}`);

  const emailDomain = email.split("@")[1];
  const companyQuery = query(
    collection(customFirestore, "companies"),
    where("domain", "==", emailDomain)
  );
  const result = await getDocs(companyQuery);

  if (!result.empty) {
    return result.docs[0].id;
  }

  return undefined;
};

const getInvitationCompany = async (
  email: string
): Promise<string | undefined> => {
  // console.log("Getting company from invitations for email ", email);
  const companyNames = await getCompanies();
  for (const companyName of companyNames) {
    const invites = await getInvites(companyName);
    if (invites.includes(email)) {
      return companyName;
    }
  }

  return undefined;
};

const getCompanies = async (): Promise<any[]> => {
  console.log("Getting company names...");
  const companies = await getDocs(collection(customFirestore, "companies"));
  return companies.docs.map((doc) => ({ name: doc.id, ...doc.data() }));
};

async function getInvites(companyName: string | undefined): Promise<string[]> {
  if (companyName) {
    // console.log("Getting invites for company", companyName);

    const companyDoc = await getDoc(
      doc(customFirestore, "companies", companyName)
    );
    return companyDoc.data()?.invites ?? [];
  } else {
    return [];
  }
}

const getCompanyIdFromName = async (
  companyName: string | undefined
): Promise<string | undefined> => {
  if (!companyName) {
    return undefined;
  }
  const companyDoc = await getDoc(
    doc(customFirestore, "companies", companyName)
  );

  if (!companyDoc.data()?.id) {
    throw Error(`No company Id found for company ${companyName}`);
  }

  return companyDoc.data()?.id;
};

export { getCompanyIdFromName, getCompanies };
