import React, { useCallback, useContext, useState } from "react";
import {
  getCompanySettings,
  getModelVersionForCompany,
  saveSettingsToFirestore,
} from "../utils/firebase_operations";
import { PlainCard } from "./settings_components";
import { CompanySettings, CustomHeader, SettingField } from "../utils/types";
import { DEFAULT_SETTINGS } from "../constants/default-settings";
import { toast } from "react-toastify";
import { Tooltip } from "@material-tailwind/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AddCircle, ContentCopy, Delete } from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
  removeItemFromArray,
  replaceItemFomArray,
  trackSentryException,
} from "../utils/helpers";
import {
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
} from "@mui/material";
import IOSSwitch from "./ios-switch";
import { AuthContext } from "../auth/AuthContext";
import { companyDataAtom } from "../routes/test-run/test-run-atoms";

const SettingInputField = ({
  settingKey,
  field,
  onChangeHandler,
}: {
  settingKey: string;
  field: SettingField;
  onChangeHandler?: (
    key: string,
    newValue: string | boolean | undefined | CustomHeader[]
  ) => void;
}) => {
  switch (field.type) {
    case "dropdown":
      return (
        <Select
          fullWidth
          autoWidth={false}
          value={field.value.toString()}
          onChange={(event) =>
            onChangeHandler?.(settingKey, event.target.value)
          }
          size={"small"}
          labelId={field.label}
        >
          {field.options?.map((option: string) => (
            <MenuItem key={option} id={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      );

    case "boolean":
      return (
        <IOSSwitch
          checked={
            field.inverted ? Boolean(!field.value) : Boolean(field.value)
          }
          onChange={(event) =>
            onChangeHandler?.(
              settingKey,
              field.inverted ? !event.target.checked : event.target.checked
            )
          }
          disabled={field.disabled}
          defaultChecked
        />
      );

    case "number":
      return (
        <div className="">
          <TextField
            variant="outlined"
            size={"small"}
            fullWidth
            value={field.value.toString()}
            disabled={field.disabled}
            onChange={(event) =>
              onChangeHandler?.(settingKey, event.target.value)
            }
          />
        </div>
      );

    case "string":
      const inputField = (
        <TextField
          value={field.value.toString()}
          size={"small"}
          type={field.masked ? "password" : "text"}
          onChange={(event) =>
            onChangeHandler?.(settingKey, event.target.value)
          }
          disabled={field.disabled}
          fullWidth
          variant={"outlined"}
        />
      );
      return (
        <div>
          {field.tooltip ? (
            <Tooltip content={field.tooltip}>{inputField}</Tooltip>
          ) : (
            inputField
          )}
        </div>
      );
    case "map":
      const customHeaders = field.value as CustomHeader[];
      return (
        <div className={"flex flex-col items-start gap-4"}>
          {customHeaders.map((customHeader, index) => (
            <div className={"flex flex-row gap-2"} key={index}>
              <TextField
                className="pr-20 "
                label={"Header"}
                size={"small"}
                value={customHeader.header}
                onChange={(event) => {
                  onChangeHandler?.(
                    settingKey,
                    replaceItemFomArray(customHeaders, index, {
                      header: event.target.value,
                      value: customHeader.value,
                      masked: customHeader.masked,
                    })
                  );
                }}
              />
              <TextField
                className="pr-20 "
                label={"Value"}
                size={"small"}
                type={customHeader.masked ? "password" : "text"}
                value={customHeaders[index].value}
                onChange={(event) => {
                  onChangeHandler?.(
                    settingKey,
                    replaceItemFomArray(customHeaders, index, {
                      header: customHeader.header,
                      value: event.target.value,
                      masked: customHeader.masked,
                    })
                  );
                }}
              />
              <IconButton
                aria-label="delete"
                sx={{ marginLeft: "-4px" }}
                onClick={() => {
                  onChangeHandler?.(
                    settingKey,
                    removeItemFromArray(customHeaders, index)
                  );
                }}
              >
                <Delete />
              </IconButton>
            </div>
          ))}
          <Button
            variant="text"
            endIcon={<AddCircle />}
            sx={{ color: "#0000008a" }}
            onClick={() => {
              if (!customHeaders.find((item) => item.header === "")) {
                onChangeHandler?.(settingKey, [
                  ...customHeaders,
                  { header: "", value: "", masked: false },
                ]);
              } else {
                toast.warning(`Please enter a valid header`);
              }
            }}
          >
            Add header
          </Button>
        </div>
      );
    default:
      return <p>missing field implementation</p>;
  }
};

const CompanySettingsPanel = ({
  companyId,
}: {
  companyId: string | undefined;
}) => {
  const { user } = useContext<any>(AuthContext);

  const [settings, setSettings] = useState<CompanySettings>(
    JSON.parse(JSON.stringify(DEFAULT_SETTINGS))
  );

  const [additionalSettings, setAdditionalSettings] = useState<object>({});

  const queryClient = useQueryClient();
  const { isLoading, isError, error } = useQuery({
    queryKey: ["getParsedCompanySettings", companyId],
    queryFn: async () => {
      const modelsConfig = await getModelVersionForCompany(companyId);

      const firestoreSettings = await getCompanySettings(companyId);
      const additionalSettings = {
        ...firestoreSettings,
        model_version_prompting: modelsConfig?.prompting,
        model_version_execution: modelsConfig?.execution,
        model_version_fallback: modelsConfig?.fallback,
      };
      const additionalSettingsSorted = Object.fromEntries(
        Object.entries(additionalSettings).sort((a, b) =>
          a[0].localeCompare(b[0])
        )
      );

      const settingsCopy: CompanySettings = { ...settings };

      if (firestoreSettings) {
        for (const key in settings) {
          if (
            Object.hasOwn(firestoreSettings, key) &&
            firestoreSettings[key].toString()
          ) {
            if (key === "webhookAuthHeader") {
              settingsCopy[key].value = firestoreSettings[key];
              settingsCopy[key].masked = true;
            } else if (key === "customHeaders") {
              settingsCopy[key].value = (
                firestoreSettings[key] as CustomHeader[]
              ).map((customHeader) => ({ ...customHeader, masked: true }));
            } else {
              settingsCopy[key].value = firestoreSettings[key];
            }
          }
        }
      }

      setAdditionalSettings(additionalSettingsSorted);
      setSettings(settingsCopy);
      return settingsCopy;
    },
    refetchOnWindowFocus: false,
  });

  const handleSettingChange = useCallback(
    (key: string, newValue: string | boolean | undefined | CustomHeader[]) => {
      if (newValue !== undefined) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          [key]: {
            ...prevSettings[key],
            value: newValue,
          },
        }));
      }
    },
    []
  );

  const handleSaving = useCallback(async () => {
    try {
      settings.customHeaders.value = (
        settings.customHeaders.value as CustomHeader[]
      ).map((customHeader) => {
        const { masked, ...rest } = customHeader;
        return rest;
      });
      await saveSettingsToFirestore(companyId, settings);
      await queryClient.invalidateQueries({
        queryKey: ["getParsedCompanySettings", companyId],
      });
      const companyData = queryClient.getQueriesData({
        queryKey: ["getCompanyData"],
      });

      const companyDataIds = companyData.map((buildArray) => buildArray[0][1]);
      companyDataIds.forEach((companyDataId) => {
        companyDataAtom.remove(companyDataId);
      });
      queryClient.removeQueries({ queryKey: ["getCompanyData"] });

      toast.success(`Settings saved successfully`);
    } catch (error: any) {
      trackSentryException(error);
      console.error("An error occurred during settings saving", error);
      toast.error("An error occurred during settings saving");
    }
  }, [settings, queryClient, companyId]);

  return (
    <PlainCard>
      <div className="flex justify-between">
        <h1 className="text-3xl pl-4 font-bold">Settings</h1>
        <Button variant="outlined" onClick={handleSaving} size={"large"}>
          Save
        </Button>
      </div>
      <div className="pl-4 pr-4 pt-4 relative sm:rounded-lg overflow-auto h-[calc(100%-50.25px)]">
        {isLoading && (
          <div
            className={
              "flex flex-col h-full items-center justify-center w-full"
            }
          >
            <CircularProgress />
          </div>
        )}
        {isError && (
          <div className={"mt-4 flex justify-center items-center h-full"}>
            <p className=" font-semibold text-red-400">
              An error occurred while fetching settings:{" "}
              {(error as any)?.message}
            </p>
          </div>
        )}
        {!isLoading && !isError && (
          <div className={"flex flex-col gap-4"}>
            <table className="w-full text-sm text-left ">
              <thead className="text-xs text-gray-700 uppercase ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Field
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="px-6 py-4">Organization ID</td>
                  <td className="px-6 py-4">
                    <div className="relative">
                      <OutlinedInput
                        id="filled-adornment-password"
                        value={companyId}
                        fullWidth
                        size={"small"}
                        disabled
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                companyId &&
                                  navigator.clipboard.writeText(companyId);
                              }}
                              edge="end"
                            >
                              <ContentCopy />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div>
                  </td>
                </tr>
                {Object.entries(settings).map(([key, field], index: number) => {
                  return (
                    <tr key={index} className=" border-b last:border-b-0">
                      <td className="px-6 py-4">{field.label}</td>
                      <td className="px-6 py-4">
                        <SettingInputField
                          field={field}
                          settingKey={key}
                          onChangeHandler={handleSettingChange}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {user.role === "superUser" && (
              <div className={"mb-2.5"}>
                <Accordion elevation={2}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="additional-settings-content"
                    id="additional-settings-header"
                    sx={{ paddingInline: "24px" }}
                  >
                    <Typography variant={"h6"} className="py-5">
                      Additional settings
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ padding: 0 }}
                    className={"flex flex-col"}
                  >
                    {Object.entries(additionalSettings).map(
                      ([key, field], index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center border-b last:border-b-0"
                        >
                          <p className="px-6 py-4 flex-1 text-sm">{key}</p>
                          <div className="px-6 py-4 flex-1">
                            <SettingInputField
                              field={{
                                type: "string",
                                value: JSON.stringify(field, null, 2),
                                label: key,
                                disabled: true,
                              }}
                              settingKey={key}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </div>
        )}
      </div>
    </PlainCard>
  );
};

export default CompanySettingsPanel;
