import React, { useEffect, useState } from "react";
import FileUpload from "../components/file_upload";
import gptDriverLogoWhite from "../assets/images/gpt-driver-logo-white.png";
import { Progress } from "@material-tailwind/react";
import Footer from "../components/footer_onboarding";

const OnboardingCallPage = () => {
  const [uploadComplete, setUploadComplete] = useState(false);

  useEffect(() => {
    // Load Calendly CSS
    const link = document.createElement("link");
    link.href = "https://assets.calendly.com/assets/external/widget.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    // Load Calendly JS
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  const handleOnClick = (e) => {
    e.preventDefault();
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/mobileboost-chat/onboarding?hide_gdpr_banner=1",
    });
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="bg-[#0E021D] py-4">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-2">
              <img src={gptDriverLogoWhite} alt="GPT Driver" className="w-[320px]" />
              {/* <a
                href="https://www.producthunt.com/posts/gpt-driver?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-gpt&#0045;driver"
                className="inline-block"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=389897&theme=light"
                  alt="GPT&#0032;Driver - Let&#0032;AI&#0032;do&#0032;your&#0032;mobile&#0032;app&#0032;QA | Product Hunt"
                  style={{ width: "250px", height: "54px" }}
                  width="250"
                  height="54"
                />
              </a> */}
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 mt-8">
            <div>
              <h1 className="text-[24px] font-medium">Onboarding Call</h1>
              <div className="w-[80%]">
                <p className="mt-4">
                  We are excited to get you started with GPT Driver - our native AI tool for mobile app QA!
                </p>
                <p className="mt-4">
                  We kindly ask you to complete 2 steps to get started:
                  <ol className="list-decimal ml-4">
                    <li className="my-4"><b>Upload app file</b><br />This allows our team to prepare the call in which we will test GPT Driver on your app together.</li>
                    <li><b>Schedule a call</b><br />Spaces are limited and given on a first-come, first-served basis.</li>
                  </ol>
                </p>
              </div>
            </div>
            <div className="mt-8 md:mt-0">
              <div className="grid grid-cols-2 mb-1">
                <p className="text-center">Step 1: Upload app file</p>
                <p className="text-center">Step 2: Schedule call</p>
              </div>
              <Progress value={uploadComplete ? 100 : 50} color="blue" />
              {uploadComplete && (
                <div className="mt-8">
                  <p>
                    <b>Upload successful!</b><br />You can now schedule a call with our team to get you onboarded.
                  </p>
                  <button
                    onClick={handleOnClick}
                    className="rounded-full bg-blue-500 text-white font-semibold hover:shadow-lg py-2 px-4 mt-4"
                  >
                    Book onboarding call
                  </button>
                </div>
              )}
              {!uploadComplete && (
                <FileUpload setUploadComplete={setUploadComplete} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OnboardingCallPage;
