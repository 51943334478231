import React from "react";
import { useAtomValue } from "jotai";
import { TestSuiteRunRecordingData } from "../../utils/types";
import { playbackTimestampAtomFromRunId } from "../../atoms/playback-timestamp-atom";
import "react-virtualized/styles.css";
import { Tab } from "@mui/material";
import styled from "@emotion/styled";
import { TabContext, TabList } from "@mui/lab";
import InteractionLogsTab from "./interactionLogsTab";
import NetworkTab from "./networkLogsTab";
import ConsoleTab from "./consoleLogsTab";

const SessionInfoTabs = ({
  runRecordingData,
}: {
  runRecordingData: TestSuiteRunRecordingData;
}) => {
  const parentRun = runRecordingData.runs[runRecordingData.runs.length - 1];
  const playbackTimestampAtom = playbackTimestampAtomFromRunId(parentRun.id!);
  const playbackTimestamp = useAtomValue(playbackTimestampAtom);

  const [value, setValue] = React.useState("0");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabSection>
        <TabList onChange={handleChange} aria-label="Logs Tabs" centered>
          <Tab value={"0"} label="Interaction" />
          <Tab value={"1"} label="Network" />
          <Tab value={"2"} label="Console" />
        </TabList>
      </TabSection>
      <InteractionLogsTab
        setTabValue={setValue}
        interactionLogs={runRecordingData?.recording.interactionLogs ?? []}
        playbackTimestamp={playbackTimestamp}
        display={value == "0"}
      />
      <NetworkTab
        playbackTimestamp={playbackTimestamp}
        networkLogs={runRecordingData?.recording.networkLogs ?? []}
        display={value == "1"}
      />
      <ConsoleTab
        consoleLogs={runRecordingData?.recording.logs ?? []}
        playbackTimestamp={playbackTimestamp}
        display={value == "2"}
      />
    </TabContext>
  );
};

const TabSection = styled.div`
  grid-area: tabs;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  margin-inline: 16px;
`;

export function getNextReplayFrameIndex({
  frames,
  targetOffsetMs,
  allowExact = false,
}: {
  frames: any[];
  targetOffsetMs: number;
  allowExact?: boolean;
}): number | undefined {
  return frames.reduce<number | undefined>((foundIndex, item, currentIndex) => {
    if (
      item.relativeTimestamp < targetOffsetMs ||
      (!allowExact && item.relativeTimestamp === targetOffsetMs)
    ) {
      return foundIndex;
    }
    if (
      (allowExact && item.relativeTimestamp === targetOffsetMs) ||
      foundIndex === undefined ||
      item.relativeTimestamp < frames[foundIndex].relativeTimestamp
    ) {
      return currentIndex;
    }
    return foundIndex;
  }, undefined);
}
export { SessionInfoTabs };
