import React, { useEffect, useMemo } from "react";
import { appetizeClientAtom, sessionAtom } from "../atoms";
import { Breadcrumbs } from "@material-tailwind/react";
import { useAtom, useAtomValue } from "jotai";
import { PlayIcon, StopIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useLocation, useParams } from "react-router-dom";
import {
  BuildData,
  CompanyData,
  RunExecutionData,
  TestSuiteData,
} from "../utils/types";
import {
  getErrorMessage,
  getPromptStepsText,
  trackExceptionOnFirestore,
  trackSentryException,
} from "../utils/helpers";
import useExecuteSteps from "../routes/test-run/use-execute-steps";
import { useQuery } from "@tanstack/react-query";
import Timeout from "await-timeout";
import { CircularProgress } from "@mui/material";
import {
  createAppetizeCloneSession,
  sessionMutationAtom,
} from "../utils/appetize-clone";

const kRetryTimeout = 60000;

export default function TestRunGptEditor({
  runs,
  buildData,
  companyData,
}: {
  runs: RunExecutionData[];
  companyData: CompanyData;
  buildData: BuildData;
}) {
  const location = useLocation();

  const shouldAutoRun = useMemo<boolean>(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const autoRunParam = urlSearchParams.get("autorun");

    return autoRunParam === "true";
  }, [location]);

  const { testSuiteId } = useParams();
  const session = useAtomValue(sessionAtom);
  const appetizeClient = useAtomValue(appetizeClientAtom);

  const {
    executionState,
    startExecution,
    stopExecution,
    concludedParentRunMutation,
  } = useExecuteSteps({
    testSuiteId: testSuiteId as string,
    buildData,
    companyData,
  });

  const { mutateAsync } = useAtomValue(sessionMutationAtom);

  const autoRunQueryData = useQuery({
    queryKey: ["autorun"],
    queryFn: async () => {
      const date = new Date();
      const heartbeatElement = document.getElementById("heartbeat");
      heartbeatElement.innerText = `STARTING_SESSION_FROM_AUTORUN_QUERY,${date.getTime()}`;

      if (companyData.settings.enableAppetizeClone) {
        const parentRun = runs.at(-1)!;

        await mutateAsync({
          buildId: buildData.id,
          bundleId: buildData.bundle!,
          storagePath: buildData.storage_path,
          device: parentRun.device,
          os: parentRun.os,
          platform: parentRun.platform,
          language: parentRun.locale?.split("_")?.at(0),
          countryCode: parentRun.locale?.split("_")?.at(1),
          proxy: companyData.settings.proxy,
        });
      } else {
        if (appetizeClient.session == null) {
          await Timeout.wrap(appetizeClient.startSession(), kRetryTimeout);
        }
        if (appetizeClient.session == null) {
          throw Error("Could not initialize emulator session");
        } else if (!appetizeClient.session.ready) {
          throw Error(
            `Could not initialize emulator session after ${
              kRetryTimeout / 1000
            } seconds.`
          );
        }
      }

      const secondDate = new Date();
      heartbeatElement.innerText = `SESSION_STARTED_FROM_AUTORUN_QUERY,${secondDate.getTime()}`;

      startExecution({ runs, enableRetry: true });
      return true;
    },
    retry: 2,
    retryDelay: kRetryTimeout,
    enabled:
      (companyData.settings.enableAppetizeClone ||
        (!companyData.settings.enableAppetizeClone &&
          appetizeClient != null)) &&
      shouldAutoRun,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (autoRunQueryData.isError) {
      trackSentryException(autoRunQueryData.error);
      trackExceptionOnFirestore({
        error: getErrorMessage(autoRunQueryData.error),
        source: "appetize",
      });
      concludedParentRunMutation.mutate({
        status: "blocked",
        statusMessage: getErrorMessage(autoRunQueryData.error),
        run: runs[runs.length - 1],
      });
    }
  }, [autoRunQueryData.error]);

  return (
    <>
      <Breadcrumbs
        separator={<ChevronRightIcon className={"text-[#7C7C7C] h-5 w-5"} />}
        className={"bg-transparent  pl-0 ml-0"}
      >
        <a href="#" className="text-[#7C7C7C] text-[18px]">
          All tests
        </a>
        <a href="#" className="text-[#7C7C7C] text-[18px]">
          Test builder
        </a>
      </Breadcrumbs>
      <div className={"flex gap-2 items-center mb-8"}>
        <h1 className="text-white text-lg ">Test Title</h1>
        <BorderColorIcon fontSize={"small"} className={"text-[#7C7C7C]"} />
      </div>
      <h3 className="text-[#ccc] text-sm mb-4">Test Prompt</h3>
      <textarea
        className="w-full p-4 bg-black text-[#eee] rounded-[16px] h-[120px]"
        value={runs
          .map(
            (run, index) =>
              `Test #${index + 1}\n${getPromptStepsText(run.promptSteps)}`
          )
          .join("\n\n")}
        readOnly={true}
      ></textarea>
      <div className="flex gap-4 pt-4">
        <div className="mr-4">
          {!executionState.isRunning && (
            <button
              className="text-white font-light py-2 px-4 enabled:hover:bg-[#232323] disabled:bg-gray-800"
              style={{ border: "1px solid #656565" }}
              onClick={() => {
                startExecution({ runs: runs, enableRetry: true });
              }}
              disabled={session == null}
            >
              <PlayIcon strokeWidth={2} className="h-5 w-5 inline" /> Execute
            </button>
          )}
          {executionState.isRunning && (
            <div className={"flex gap-4"}>
              <button
                className="text-[#1C1C1E] font-light py-2 px-4 bg-[#0DFF25]"
                style={{ border: "1px solid #656565" }}
                onClick={stopExecution}
              >
                <StopIcon strokeWidth={2} className="h-5 w-5 inline" /> Stop
              </button>
              <CircularProgress />
            </div>
          )}
        </div>
        <div className="inline text-[#ccc] text-sm">
          {executionState.isRunning ? "Executing" : ""}
        </div>
      </div>

      {executionState.result && (
        <div className="mt-8">
          <div
            className={`${
              executionState.result === "Success"
                ? `bg-green-800`
                : `bg-red-800`
            } p-2 text-white rounded-lg rounded-b-none`}
          >
            {executionState.result}
          </div>
          <div
            className={`${
              executionState.result === "Success"
                ? `bg-green-200`
                : `bg-red-200`
            } p-2 rounded-lg rounded-t-none`}
          >
            {executionState.resultContent}
          </div>
        </div>
      )}

      <h3 className="text-[#7C7C7C] text-[18px] mt-8 mb-4">Execution Log</h3>

      <div className="flex flex-col w-full flex-grow-1 rounded-t-[16px] overflow-auto">
        <div className="grid grid-cols-2 text-left text-[#5C5C5C] bg-[#1E1E1E] ">
          <div className="font-semibold mx-auto p-4">Screen</div>
          <div className="font-semibold mx-auto p-4">Actions</div>
        </div>
        {executionState.runs.length > 0 && (
          <div
            className={
              "flex flex-col gap-1 flex-grow-1 rounded-b-[16px] bg-black"
            }
          >
            {executionState.runs.map((run, runIndex) => (
              <>
                {run.executionSteps.map((step, stepIndex) => (
                  <div
                    className="grid grid-cols-2 text-left text-[#232323]  divide-x divide-[#656565]"
                    key={`${runIndex}-${stepIndex}`}
                  >
                    <div className={"p-5"}>
                      <img src={step.image} />
                    </div>
                    <div className={"p-5 text-white"}>
                      {step.gptCommands.map((gptStep, gptCommandIndex) => (
                        <div key={gptCommandIndex}>{gptStep}</div>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
