function removeCoordinates(strings: string[]): string[] {
  const modifiedStrings: string[] = [];
  const pattern = /\s*\[\d+\s*,\s*\d+\]\s*/;
  for (const string of strings) {
    const modifiedString = string.replace(pattern, "");
    modifiedStrings.push(modifiedString);
  }
  return modifiedStrings;
}

export { removeCoordinates };
