import React, { useCallback, useEffect, useRef, useState } from "react";
import LogParsingWorker from "../../utils/log-parsing-worker.worker?worker";
import DetailModal from "./detailModal";
import { consoleLogsCache, CustomFab, CustomList, Title } from "./components";
import { CircularProgress, Divider, Zoom } from "@mui/material";
import { North, South } from "@mui/icons-material";
import { AutoSizer, CellMeasurer } from "react-virtualized";
import { styled as materialStyled } from "@mui/material/styles";
import styled from "@emotion/styled";
import { TabPanel } from "@mui/lab";
import { DateTime } from "luxon";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { atom, useAtom } from "jotai/index";

const ConsoleTab = ({ consoleLogs, playbackTimestamp, display }) => {
  const [parsedLogs, setParsedLogs] = useState<null | []>(null);
  const [selectedLog, setSelectedLog] = useState(null);

  useEffect(() => {
    const worker = new LogParsingWorker();

    worker.onmessage = (event) => {
      setParsedLogs(event.data);
    };

    worker.onerror = (error) => {
      console.error("Web Worker error:", error);
    };

    worker.postMessage(consoleLogs);

    return () => {
      worker.terminate();
    };
  }, [consoleLogs]);

  const [jumpToTimestamp, setJumpToTimestamp] = useState<
    "hidden" | "top" | "bottom"
  >("hidden");

  const [scrollTop, setScrollTop] = useState(0);
  const listRef = useRef(null);
  const [scrollToIndex, setScrollToIndex] = useState<undefined | number>(
    undefined
  );

  useEffect(() => {
    if (
      parsedLogs?.at(0)?.relativeTimestamp != null &&
      playbackTimestamp <= parsedLogs?.at(0)?.relativeTimestamp!
    ) {
      setJumpToTimestamp("hidden");
    } else {
      const startIndex = listRef.current?.Grid._renderedRowStartIndex;
      const stopIndex = listRef.current?.Grid._renderedRowStopIndex;

      const someLogRenderedBeforeCurrentTimestamp = parsedLogs
        ?.slice(startIndex, stopIndex + 1)
        ?.some((log) => log.relativeTimestamp <= playbackTimestamp);

      const someLogRenderedAfterCurrentTimestamp = parsedLogs
        ?.slice(startIndex, stopIndex + 1)
        ?.some((log) => log.relativeTimestamp > playbackTimestamp);

      const lastItemDisplayed = stopIndex === parsedLogs?.length - 1;
      if (
        (someLogRenderedBeforeCurrentTimestamp &&
          someLogRenderedAfterCurrentTimestamp) ||
        (playbackTimestamp > parsedLogs?.at(-1)?.relativeTimestamp &&
          lastItemDisplayed)
      ) {
        setJumpToTimestamp("hidden");
      } else {
        const everyLogRenderedBeforeCurrentTimestamp = parsedLogs
          ?.slice(startIndex, stopIndex + 1)
          ?.every((log) => log.relativeTimestamp <= playbackTimestamp);
        setJumpToTimestamp(
          everyLogRenderedBeforeCurrentTimestamp ? "bottom" : "top"
        );
        setScrollToIndex(undefined);
      }
    }
  }, [playbackTimestamp, parsedLogs, scrollTop]);

  const jumpToTimestampHandler = useCallback(() => {
    const indexToJump = parsedLogs?.findLastIndex(
      (log) => playbackTimestamp >= log.relativeTimestamp
    );

    const extraItemExist = !!parsedLogs?.[indexToJump + 1];

    setScrollToIndex(
      jumpToTimestamp === "bottom"
        ? indexToJump + (extraItemExist ? 1 : 0)
        : indexToJump
    );
  }, [parsedLogs, playbackTimestamp, jumpToTimestamp]);

  const [forceMoveToTimestampDeviceTab, setForceMoveToTimestampDeviceTab] =
    useAtom(forceMoveToTimestampDeviceTabAtom);

  useEffect(() => {
    if (forceMoveToTimestampDeviceTab != null) {
      const indexToJump = parsedLogs?.findLastIndex(
        (log) => forceMoveToTimestampDeviceTab >= log.relativeTimestamp
      );
      setScrollToIndex(indexToJump);
      setForceMoveToTimestampDeviceTab(null);
    }
  }, [forceMoveToTimestampDeviceTab]);

  return (
    <>
      <DetailModal
        open={!!selectedLog}
        handleClose={() => {
          setSelectedLog(null);
        }}
        title={"Log details"}
      >
        <>{selectedLog && <p>{selectedLog.message}</p>}</>
      </DetailModal>
      <ConsoleLogsTable keepMounted value={"2"} display={display}>
        <Title style={{ paddingLeft: "16px" }}>Time</Title>
        <Title>Level</Title>
        <Title>Message</Title>
        <Divider
          style={{
            gridArea: "divider",
            width: "100%",
            marginTop: "7.5px",
          }}
        />
        <ConsoleLogsTableContent>
          <Zoom in={jumpToTimestamp != "hidden"} unmountOnExit>
            <CustomFab
              variant={"extended"}
              jumpToTimestamp={jumpToTimestamp}
              onClick={jumpToTimestampHandler}
            >
              {jumpToTimestamp !== "top" ? <South /> : <North />}
              Jump to current timestamp
            </CustomFab>
          </Zoom>
          {parsedLogs == null ? (
            <div
              className={
                "flex flex-col h-full items-center justify-center w-full"
              }
            >
              <CircularProgress />
            </div>
          ) : (
            <AutoSizer>
              {({ width, height }) => (
                <CustomList
                  onScroll={(params) => {
                    setScrollTop(params.scrollTop);
                  }}
                  ref={listRef}
                  // onRowsRendered={onRowsRendered}
                  rowCount={parsedLogs.length}
                  width={width}
                  height={height}
                  deferredMeasurementCache={consoleLogsCache}
                  rowHeight={consoleLogsCache.rowHeight}
                  scrollToIndex={scrollToIndex}
                  rowRenderer={({ index, key, style, parent }) => {
                    const currentLog = parsedLogs[index];
                    const className =
                      index === parsedLogs.length - 1 &&
                      playbackTimestamp > currentLog.relativeTimestamp
                        ? "afterLastItem"
                        : currentLog.relativeTimestamp <= playbackTimestamp
                        ? "beforeCurrentTime"
                        : "afterCurrentTime";

                    return (
                      <CellMeasurer
                        key={key}
                        cache={consoleLogsCache}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        {({ registerChild }) => (
                          <div
                            className={className}
                            style={{
                              ...style,
                              paddingBottom: "16px",
                              paddingTop: index === 0 ? "15.5px" : 0,
                            }}
                            ref={registerChild}
                            key={`consoleLogRows-${index}`}
                          >
                            <ConsoleLogRow
                              consoleLog={currentLog}
                              onSelectLog={() => {
                                setSelectedLog(currentLog);
                              }}
                              playbackTimestamp={playbackTimestamp}
                            />
                          </div>
                        )}
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          )}
        </ConsoleLogsTableContent>
      </ConsoleLogsTable>
    </>
  );
};

const forceMoveToTimestampDeviceTabAtom = atom<null | number>(null);

const ConsoleLogsTableContent = styled.div`
  grid-area: table-content;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: var(--network-logs-table-gap);
  width: 100%;
`;

const ConsoleLogsTable = materialStyled(TabPanel)(
  ({ display }: { display: boolean }) =>
    display
      ? {
          display: "grid",
          gridTemplateAreas: `
    "time level message"
    "divider divider divider"
    "table-content table-content table-content"
  `,
          "--console-logs-table-grid-template-columns":
            "12ch 10ch minmax(0, 1fr)",
          gridTemplateColumns:
            "var(--console-logs-table-grid-template-columns)",
          gridTemplateRows: "auto auto minmax(0, 1fr)",
          "--network-logs-table-gap": "16px",
          columnGap: "var(--network-logs-table-gap)",
          marginTop: "var(--network-logs-table-gap)",
          justifyItems: "start",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07))",
          boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
          marginInline: "16px",
          marginBottom: "16px",
          padding: "16px 0px 0px",
        }
      : {}
);

function ConsoleLogRow({ consoleLog, onSelectLog, playbackTimestamp }) {
  function handleClick() {
    onSelectLog(consoleLog);
  }

  return (
    <ConsoleLogContent onClick={handleClick}>
      <div style={{ paddingLeft: "16px" }}>
        {DateTime.fromMillis(consoleLog.time).toFormat("hh:mm:ss:S")}
      </div>
      <div>
        <span
          className="bg-black p-2"
          style={{ "background-color": "rgba(255, 255, 255, 0.05)" }}
        >
          {consoleLog.type}
        </span>
      </div>
      <div>{consoleLog.message}</div>
    </ConsoleLogContent>
  );
}

const ConsoleLogContent = styled.div`
  display: grid;
  grid-template-areas: "time level message";
  grid-template-columns: var(--console-logs-table-grid-template-columns);
  justify-items: start;
  gap: var(--network-logs-table-gap);
`;

export { forceMoveToTimestampDeviceTabAtom };

export default ConsoleTab;
