import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { CheckIcon } from "@heroicons/react/24/solid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Node } from "prosemirror-model";

import {
  annotateScreenshot,
  arrayToCsv,
  b64toBlob,
  bufferToBase64,
  capitalize,
  createSuccessPromptAPIWrapper,
  downloadBlob,
  enrichLogTextAPIWrapper,
  generateEnrichedPrompts,
  getDigitsAfterFirstSemicolon,
  getErrorMessage,
  getImageDimensions,
  getPromptAssistantDeviceCoordinates,
  getPromptStepsFromTipTapNode,
  getPromptStepsText,
  getSlideDirectionAndPercentage,
  invertDirection,
  parsePressedKeys,
  prepareCachedFlow,
  replaceItemFomArray,
  replaceTemplateItem,
  trackAmplitudeEvent,
  trackSentryException,
  uploadScreenshotToStorage,
} from "../../utils/helpers";
import { testEditorPropertiesAtom } from "../../atoms/test-editor-atom";
import { customFirestore, customStorage } from "../../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  runTransaction,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import useExecuteSteps, {
  ExecutionStepState,
} from "../../routes/test-run/use-execute-steps";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { getNestedDependencies } from "../../atoms/test-nested-dependecies-atom";
import { customAlertStateAtom } from "../custom-alert";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import TestEditorSettings from "./testEditorSettings";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tab,
  Tabs,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import {
  AutoAwesome,
  Close,
  Compare,
  Notes,
  Save,
  Settings,
} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import TipTapEditor from "./tip-tap-editor/tipTapEditor";
import TestEditorAssistant from "./testEditorAssistant";
import { buildLogsAtomWithId, sessionAtom } from "../../atoms";
import {
  BuildData,
  CachedScreenData,
  CompanyData,
  DependencyFormState,
  DependencyInputValues,
  LoaderApp,
  PromptStep,
  RunExecutionData,
  SessionConfigData,
  Test,
  TestFolder,
  TestSettings,
} from "../../utils/types";
import { AuthContext } from "../../auth/AuthContext";
import { deleteCachedSteps } from "../../utils/firebase_operations";
import { RenderSpinner } from "../settings_components";
import { TiptapCollabProvider } from "@hocuspocus/provider";
import * as Y from "yjs";
import TestEditorExecutionLogs from "./execution-logs/testEditorExecutionLogs";
import { testBuilderCustomAlertStateAtom } from "../../routes/test-builder-page";
import { AsyncConfirmationModal } from "../asyncConfirmationModal";
import { Form, Formik, FormikProps } from "formik";
import FormikTextInput from "../../formikComponents/formik-text-input";
import { array, object, string } from "yup";
import { darkTheme, lightTheme } from "../../themes";
import { forceUpdateManualConditionsStateAtom } from "../../routes/force-update-dialog";
import FormikSelect from "../../formikComponents/formik-select";

import { useBlocker } from "react-router-dom";
import { getTestsAtom } from "../../atoms/testing-settings-atoms";
import dependenciesForTestAtom from "../../atoms/tests-atom";
import { kAllTestsId } from "../../routes/tests-home/tests-tree-view";

const succeedingStep: PromptStep = {
  text: "Succeed the test once you finish the above step(s).",
  plainText: "Succeed the test once you finish the above step(s).",
};

export default function TestEditorGpt({
  test,
  tipTapJWTData,
  buildData,
  companyData,
  sessionConfig,
}: {
  test?: Test;
  tipTapJWTData: { jwt: string };
  buildData: BuildData;
  companyData: CompanyData;
  sessionConfig: SessionConfigData;
}) {
  const [session, setSession] = useAtom(sessionAtom);
  const [isEditing, setIsEditing] = useState(false);
  const [isExecutingFullTest, setIsExecutingFullTest] = useState(false);
  const [promptGeneratorEnabled, setPromptGeneratorEnabled] = useState(false);
  const [promptGenerationScreenshots, setPromptGenerationScreenshots] =
    useState<{ [id: number]: string[] }>(
      test != null ? test!.promptGenerationScreenshots! : {}
    );
  const [enrichedInteractions, setEnrichedInteractions] = useState<{
    [id: number]: { rawLog: string; enrichedLog: string }[];
  }>(test != null ? test!.enrichedInteractionsLogs! : {});

  const [enrichedFromApiInteractions, setEnrichedFromApiInteractions] =
    useState<{ [id: number]: { rawLog: string; enrichedLog: string }[] }>(
      test != null ? test!.enrichedInteractionsLogs! : {}
    );

  const firstPressedKeyTimestamp = useRef(0);
  const [localLastSuccessfulRun, setLocalLastSuccessfulRun] = useState<
    CachedScreenData[]
  >([]);

  const [auxLocalLastSuccessfulRun, setAuxLocalLastSuccessfulRun] = useState<
    CachedScreenData[] | null
  >(null);

  const setTestBuilderCustomAlertState = useSetAtom(
    testBuilderCustomAlertStateAtom
  );

  const setForceUpdateManualConditionsState = useSetAtom(
    forceUpdateManualConditionsStateAtom
  );

  const [pressedKeys, setPressedKeys] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState("prompt");
  const [auxDisplayPasteStep, setAuxDisplayPasteStep] = useState(false);

  const [openSuccessPromptDialog, setOpenSuccessPromptDialog] = useState(false);

  const { user } = useContext<any>(AuthContext);
  const { buildId } = useParams();

  const loaderData = useLoaderData() as LoaderApp;
  const { videoFrames, interactionLogs, getUIElements } = useAtomValue(
    loaderData.buildLogsData
  );

  const customTests = useAtomValue(
    dependenciesForTestAtom({
      organizationId: buildData!.organisationId,
      testId: test?.id,
    })
  );
  const scrollableRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [testEditorProperties, setTestEditorProperties] = useAtom(
    testEditorPropertiesAtom
  );

  const [selectedDependencyId, setSelectedDependencyId] = useState(
    test?.dependencies?.at(0)?.testId ?? null
  );
  const dependencyFormRef = useRef<FormikProps<DependencyFormState>>(null);
  const dependencyFormInitialState = useMemo(() => {
    const dependencyFormState = dependencyFormRef.current;

    if (
      dependencyFormState != null &&
      dependencyFormState?.values?.dependencyId == null
    ) {
      return { selectedDependencyId, dependencyInputs: [] };
    }

    const alreadySavedDependencyInputs: DependencyInputValues =
      test?.dependencyInputValues ?? {};

    const currentDependencyId =
      dependencyFormState?.values?.dependencyId ?? selectedDependencyId;

    const dependencyTest = customTests?.data?.find(
      (test) => test.id === currentDependencyId
    );

    return {
      selectedDependencyId,
      dependencyInputs:
        dependencyTest?.settings?.inputKeys
          ?.filter((inputKey) => inputKey.key != null)
          .map((inputKey) => {
            return {
              key: inputKey.key,
              value:
                alreadySavedDependencyInputs?.[currentDependencyId!]?.find(
                  (savedInput) => savedInput.key === inputKey.key
                )?.value ?? "",
            };
          }) ?? [],
    };
  }, [test, customTests, selectedDependencyId]);

  const promptSteps = useMemo(
    () =>
      getPromptStepsFromTipTapNode({
        node: testEditorProperties.testCommands as Node,
      }),
    [testEditorProperties.testCommands]
  );

  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const [testCommandsUpdated, setTestCommandsUpdated] = useState(false);

  const newTestDocRef = useMemo(() => {
    return test == null
      ? doc(collection(customFirestore, "custom-tests"))
      : null;
  }, []);

  const [provider, setProvider] = useState<TiptapCollabProvider | null>(null);
  const [document, setDocument] = useState<Y.Doc | null>(null);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const document = new Y.Doc();
    const provider = new TiptapCollabProvider({
      name: `${test?.organisationId ?? buildData!.organisationId}/${
        test?.id ?? newTestDocRef!.id
      }`,
      appId: "zk5ww0k5",
      token: tipTapJWTData.jwt,
      document,
      onConnect: () => {
        setConnected(true);
      },
      onAuthenticationFailed: (data) => {
        trackSentryException(data);
      },
    });

    setProvider(provider);
    setDocument(document);
    return () => {
      provider.destroy();
    };
  }, []);

  useEffect(() => {
    if (promptGeneratorEnabled) {
      const lastEvent = interactionLogs.at(-1);

      const handleEnrichingLogs = async (event: any) => {
        trackAmplitudeEvent({
          eventType: "Assistant interaction log",
          eventProperties: {
            type: event.type,
          },
          groups: { organisation: companyData.name },
        });

        setTestEditorProperties((prev) => ({
          ...prev,
          hasGeneratedEnrichedLogs: true,
        }));

        const disabledCharacters = [
          "Backspace",
          "ArrowLeft",
          "ArrowRight",
          "ArrowDown",
          "ArrowUp",
        ];
        if (
          event.type === "keypress" &&
          !disabledCharacters.includes(event.character)
        ) {
          setPressedKeys((prev) => [...prev, event.character]);
          if (firstPressedKeyTimestamp.current === 0) {
            firstPressedKeyTimestamp.current = event.absoluteTimestamp;
          }
          return;
        }

        const commands: string[] = [];
        const lastVideoFrame = videoFrames
          .filter(
            (value) => value?.absoluteTimestamp < event.absoluteTimestamp - 200
          )
          .at(-1);
        const lastScreenshot = `data:image/jpeg;base64,${bufferToBase64(
          lastVideoFrame?.buffer
        )}`;
        const screenshotDimensions = await getImageDimensions(lastScreenshot);

        const lastEditTexts =
          getUIElements
            .filter(
              (value) => value?.absoluteTimestamp < event.absoluteTimestamp
            )
            .at(-1)?.EditText_elements ?? [];

        if (event.type === "typeText") {
          commands.push(
            `type ${event.text.replace("\r", "").replace("\n", "")}`
          );
        }

        if (
          pressedKeys.length > 0 &&
          (event.type !== "keypress" ||
            ["Enter", "Tab"].includes(event.character))
        ) {
          commands.push(`type ${parsePressedKeys(pressedKeys)}`);
          setPressedKeys([]);
        }

        if (event.type === "tap" || event.type === "swipe") {
          const { deviceX, deviceY } = getPromptAssistantDeviceCoordinates(
            event.coordinates.x,
            event.coordinates.y,
            session.data?.device,
            screenshotDimensions
          );

          if (event.type === "tap") {
            commands.push(`tap at x=${deviceX} y=${deviceY}`);
          } else {
            const { direction, percentage } = getSlideDirectionAndPercentage(
              event.moves.at(-1).x,
              event.moves.at(-1).y
            );

            const directionInverted = invertDirection(direction);

            let commandText: string = "";

            if (direction == "up" || direction == "down") {
              commandText = `scroll ${directionInverted}`;
            } else {
              if (
                deviceY < 0.2 * screenshotDimensions.height ||
                deviceY > 0.8 * screenshotDimensions.height ||
                percentage < 20
              ) {
                commandText = `slide ${direction} ${percentage}% ; at x=${deviceX} y=${deviceY}`;
              } else {
                commandText = `swipe ${direction}`;
              }
            }

            commands.push(commandText);
          }
        }

        const newData = commands.reduce<any>((prev, current) => {
          let preTypingScreenshot: string | undefined;
          if (firstPressedKeyTimestamp.current !== 0) {
            const preTypingVideoFrame = videoFrames
              .filter(
                (value) =>
                  value?.absoluteTimestamp < firstPressedKeyTimestamp.current
              )
              .at(-1);
            preTypingScreenshot = `data:image/jpeg;base64,${bufferToBase64(
              preTypingVideoFrame?.buffer
            )}`;

            firstPressedKeyTimestamp.current = 0;
          }

          const commandAlreadyEnriched =
            current.startsWith("type") ||
            current.startsWith("swipe") ||
            current.startsWith("scroll");

          return {
            enrichedInteractions: [
              ...(prev?.enrichedInteractions != null
                ? prev?.enrichedInteractions
                : []),
              {
                rawLog: current,
                enrichedLog: commandAlreadyEnriched ? capitalize(current) : "",
              },
            ],
            promptGenerationScreenshots: [
              ...(prev?.promptGenerationScreenshots != null
                ? prev?.promptGenerationScreenshots
                : []),
              preTypingScreenshot ?? lastScreenshot,
            ],
          };
        }, {});

        const newEnrichedInteractions = newData.enrichedInteractions;
        const newPromptGenerationScreenshots = await Promise.all(
          newData.promptGenerationScreenshots
        );

        const logId = event.absoluteTimestamp;

        setPromptGenerationScreenshots((prev) => ({
          ...prev,
          [logId]: newPromptGenerationScreenshots,
        }));

        setEnrichedInteractions((prev) => ({
          ...prev,
          [logId]: newEnrichedInteractions,
        }));

        const newEnrichedFromApiInteractions = await Promise.all(
          newEnrichedInteractions.map(async (newEnrichedInteraction: any) => {
            const enrichedTextFromApi = await enrichLogTextAPIWrapper(
              buildId,
              lastScreenshot,
              newEnrichedInteraction.rawLog,
              lastEditTexts,
              companyData.settings.utilizeFullTextAnnotation ?? false,
              Object.keys(enrichedInteractions).length === 0,
              companyData.settings.simplePromptAssistantEnabled ?? false
            );
            return {
              ...newEnrichedInteraction,
              enrichedLog: enrichedTextFromApi,
            };
          })
        );

        setEnrichedFromApiInteractions((prev) => ({
          ...prev,
          [logId]: newEnrichedFromApiInteractions,
        }));
      };

      lastEvent && handleEnrichingLogs(lastEvent);
    }
  }, [interactionLogs]);

  const enrichedPrompts = useMemo(() => {
    const parsedInteractions = Object.keys(enrichedInteractions)
      .sort()
      .reduce<{
        rawInteractions: string[];
        enrichedInteractions: any[];
      }>(
        (prev, currentInteractionId) => {
          const parsedInteractionId = parseInt(currentInteractionId);

          return {
            rawInteractions: [
              ...(prev.rawInteractions != null ? prev.rawInteractions : []),
              ...enrichedInteractions[parsedInteractionId].map(
                (enrichedInteraction) => enrichedInteraction.rawLog
              ),
            ],
            enrichedInteractions: [
              ...(prev.enrichedInteractions != null
                ? prev.enrichedInteractions
                : []),
              ...enrichedInteractions[parsedInteractionId].map(
                (enrichedInteraction, index) =>
                  enrichedInteraction.enrichedLog !== null &&
                  enrichedInteraction.enrichedLog.length > 0
                    ? enrichedInteraction.enrichedLog
                    : enrichedFromApiInteractions[parsedInteractionId]?.[index]
                        .enrichedLog ?? "Generating..."
              ),
            ],
          };
        },
        { rawInteractions: [], enrichedInteractions: [] }
      );

    return generateEnrichedPrompts(
      parsedInteractions.rawInteractions,
      parsedInteractions.enrichedInteractions
    );
  }, [enrichedInteractions, enrichedFromApiInteractions]);

  const createSuccessPrompt = useCallback(async () => {
    const currentTimestamp = Date.now();
    const lastVideoFrame = videoFrames
      .filter((value) => value?.absoluteTimestamp < currentTimestamp)
      .at(-1);
    const logId = currentTimestamp;
    const lastScreenshot = `data:image/jpeg;base64,${bufferToBase64(
      lastVideoFrame?.buffer
    )}`;
    const lastEditTexts =
      getUIElements
        .filter((value) => value?.absoluteTimestamp < currentTimestamp)
        .at(-1)?.EditText_elements ?? [];

    setPromptGenerationScreenshots((prev) => ({
      ...prev,
      [logId]: [lastScreenshot],
    }));

    setEnrichedInteractions((prev) => ({
      ...prev,
      [logId]: [{ rawLog: "-", enrichedLog: "" }],
    }));

    const successPrompt = await createSuccessPromptAPIWrapper(
      buildId,
      lastScreenshot,
      lastEditTexts,
      companyData.settings.utilizeFullTextAnnotation ?? false
    );
    // console.log("Success prompt", successPrompt);
    const taskCompleteText =
      "task complete: test finished based on reference screen";
    setEnrichedFromApiInteractions((prev) => ({
      ...prev,
      [logId]: [{ rawLog: taskCompleteText, enrichedLog: successPrompt }],
    }));

    trackAmplitudeEvent({
      eventType: "Assistant recording stopped",
      eventProperties: {
        testId: test?.id,
        successCriteria: true,
        interactions: enrichedPrompts.length + 1,
      },
      groups: { organisation: companyData.name },
    });
  }, [videoFrames, getUIElements, enrichedPrompts, buildData, companyData]);
  const queryClient = useQueryClient();
  const settingsFormRef = useRef<FormikProps<TestSettings>>(null);
  const [isDirty, setIsDirty] = useState(false);

  const {
    startExecution,
    stopExecution,
    resetEditor,
    parsedExecutionLogs,
    executionState,
  } = useExecuteSteps({ companyData, buildData });

  const stopExecutionHandler = useCallback(() => {
    if (stopExecution != null) {
      stopExecution();
      trackAmplitudeEvent({
        eventType: `Run execution manually stopped`,
        eventProperties: {
          testId: test?.id,
        },
        groups: { organisation: companyData.name },
      });
    }
  }, [stopExecution, companyData]);

  const sortedInteractionIds = useMemo(
    () => Object.keys(promptGenerationScreenshots).sort().reverse(),
    [promptGenerationScreenshots]
  );

  const promptGenerationRecordedActions: CachedScreenData[] = useMemo(() => {
    return sortedInteractionIds
      .map((interactionId) => {
        const parsedInteractionId = parseInt(interactionId);

        return promptGenerationScreenshots[parsedInteractionId]?.map(
          (_, index) => {
            const invertedIndex =
              promptGenerationScreenshots[parsedInteractionId].length -
              1 -
              index;
            const enrichedInteraction =
              enrichedFromApiInteractions[parsedInteractionId]?.[invertedIndex];
            const appetizeCommand = enrichedInteraction?.rawLog
              .replace("tap at", "tabOn:")
              .replace("type", "type:");
            const stepPrompt = enrichedInteraction?.enrichedLog;
            const stepNumber = enrichedPrompts.indexOf(stepPrompt) + 1;

            return {
              screenshotUrl:
                promptGenerationScreenshots[parsedInteractionId]?.[
                  invertedIndex
                ],
              gptCommand: enrichedInteraction?.rawLog,
              appetizeCommand: appetizeCommand,
              prompt: `${stepNumber}. ${stepPrompt}`,
              stepNumber: stepNumber,
              reasoning: `Reasoning: performed action according to this step description: ${stepPrompt}`,
            };
          }
        );
      })
      .flat()
      .reverse();
  }, [promptGenerationScreenshots, enrichedFromApiInteractions]);

  const executeMutation = useMutation({
    mutationKey: ["executeMutation"],
    mutationFn: async (partialPromptSteps: PromptStep[] | void) => {
      const isPartialExecution = !!partialPromptSteps;
      resetEditor();
      setIsExecutingFullTest(!isPartialExecution);
      setActiveTab("logs");
      setAuxLocalLastSuccessfulRun(null);
      setTestBuilderCustomAlertState({ open: false });

      const parentBaseline = localBaseline.filter(
        (item) => item.title === "parent"
      );
      let baselineToUse =
        parentBaseline.length > 0 ? parentBaseline : localBaseline;

      let promptStepsToUse =
        partialPromptSteps && partialPromptSteps?.length > 0
          ? [...partialPromptSteps, succeedingStep]
          : promptSteps;

      const { allowCachedRun, cachedData } = await prepareCachedFlow(
        promptStepsToUse,
        baselineToUse,
        companyData.settings.cachingEnabled,
        buildData.platform,
        buildData.organisationId,
        isPartialExecution
      );

      const disablePreRequests =
        isPartialExecution &&
        partialPromptSteps?.at(0)?.id !== promptSteps.at(0)?.id;

      const platform = buildData.platform!.toLocaleLowerCase();

      startExecution({
        runs: [
          {
            cache: {
              enabled: allowCachedRun,
              data: cachedData,
            },
            promptSteps: promptStepsToUse,
            settings: test?.settings ?? {
              launchParams: [],
              preRequests: [],
              inputKeys: [],
            },
            order: 1,
            testId: test?.id,
            title: test?.title,
            isPartialExecution,
            ...sessionConfig,
            locale: sessionConfig.language,
          },
        ],
      });
    },
  });

  const executeWithDependenciesMutation = useMutation({
    mutationKey: ["executeWithDependenciesMutation"],
    mutationFn: async (upperPromptStep: PromptStep | void) => {
      const executingCroppedTest = upperPromptStep != null;
      resetEditor();
      setAuxLocalLastSuccessfulRun(null);
      setTestBuilderCustomAlertState({ open: false });
      setActiveTab("logs");
      setIsExecutingFullTest(!executingCroppedTest);

      const nestedDependencies = await getNestedDependencies({
        cachingEnabled: companyData.settings.cachingEnabled ?? false,
        testId: dependencyFormRef.current!.values.dependencyId!,
        tempDependencies: [],
        platform: sessionConfig.platform,
        device: sessionConfig.device,
      });

      const parsedNestedDependencies = nestedDependencies
        .map<RunExecutionData>((dependency, index) => ({
          ...dependency,
          order: nestedDependencies.length - index,
          locale: sessionConfig.language,
          orientation: sessionConfig.orientation,
          os: sessionConfig.os,
        }))
        .sort((a, b) => a.order - b.order);

      let filteredPromptSteps = promptSteps;

      if (executingCroppedTest) {
        filteredPromptSteps = [];
        for (let promptStep of promptSteps) {
          if (promptStep.id === upperPromptStep.id) {
            break;
          }
          filteredPromptSteps.push(promptStep);
        }
        filteredPromptSteps.push(succeedingStep);
      }

      const { allowCachedRun, cachedData } = await prepareCachedFlow(
        filteredPromptSteps,
        localBaseline,
        companyData.settings.cachingEnabled,
        sessionConfig.platform,
        buildData!.organisationId,
        executingCroppedTest
      );

      startExecution({
        enableRetry: executingCroppedTest,
        runs: [
          ...parsedNestedDependencies,
          {
            cache: {
              enabled: allowCachedRun,
              data: cachedData,
            },
            promptSteps: filteredPromptSteps,
            order: (parsedNestedDependencies?.length ?? 0) + 1,
            settings: test?.settings ?? {
              launchParams: [],
              preRequests: [],
              inputKeys: [],
            },
            title: test?.title,
            testId: test?.id,
            isPartialExecution: executingCroppedTest,
            isRunUntilExecution: executingCroppedTest,
            ...sessionConfig,
            locale: sessionConfig.language,
            dependencyInputValues:
              parsedNestedDependencies.length === 0
                ? []
                : test?.dependencyInputValues?.[
                    parsedNestedDependencies[
                      parsedNestedDependencies.length - 1
                    ].testId!
                  ] ?? [],
          },
        ],
      });
      return parsedNestedDependencies;
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const localBaseline: CachedScreenData[] = useMemo(() => {
    if (
      test &&
      test.cachedSteps &&
      test.cachedSteps?.[sessionConfig.platform]?.[sessionConfig.device]
        ?.length > 0
    ) {
      console.log(
        `>> Using deviceSpecificCachedSteps (${sessionConfig.platform} -> ${sessionConfig.device}) as local baseline`
      );

      let dependenciesCacheScreenData: CachedScreenData[] = [];
      if (test.localDependenciesCacheSteps != null) {
        dependenciesCacheScreenData = test.localDependenciesCacheSteps;
      } else if (executeWithDependenciesMutation.data != null) {
        dependenciesCacheScreenData =
          (
            executeWithDependenciesMutation.data as
              | RunExecutionData[]
              | undefined
          )
            ?.map<CachedScreenData[]>(
              (dependency: RunExecutionData) =>
                (dependency.baseline ?? ([] as CachedScreenData[]))?.map(
                  (data: CachedScreenData) => ({
                    ...data,
                    title: dependency.title,
                  })
                ) ?? []
            )
            .flat() ?? [];
      }

      return [
        ...dependenciesCacheScreenData,
        ...test.cachedSteps?.[sessionConfig.platform]?.[
          sessionConfig.device
        ].map((cachedScreen) => ({
          ...cachedScreen,
          title: "parent",
        })),
      ];
    }

    if (localLastSuccessfulRun.length > 0) {
      console.log(">> Using localLastSuccessfulRun as local baseline");
      return localLastSuccessfulRun;
    }

    if (promptGenerationRecordedActions.length > 0) {
      console.log(">> Using promptGenerationRecordedActions as local baseline");
      return promptGenerationRecordedActions;
    }

    console.log(">> Local baseline changed to []");
    return [];
  }, [
    test?.localDependenciesCacheSteps,
    promptGenerationRecordedActions,
    test?.cachedSteps,
    executeWithDependenciesMutation.data,
    localLastSuccessfulRun,
  ]);

  useEffect(() => {
    if (
      isExecutingFullTest &&
      executionState.result === "Success" &&
      auxLocalLastSuccessfulRun == null
    ) {
      setAuxLocalLastSuccessfulRun(localBaseline);

      if (test?.id != null && test?.localDependenciesCacheSteps != null) {
        queryClient.setQueryData(
          ["getTestForTestEditor", test.id!],
          (prev) => ({
            ...prev!,
            localDependenciesCacheSteps: null,
          })
        );
      }

      executeWithDependenciesMutation.reset();

      if (parsedExecutionLogs.length > 0) {
        setLocalLastSuccessfulRun(parsedExecutionLogs);
      }
    }
  }, [executionState.result, localBaseline, auxLocalLastSuccessfulRun]);

  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  useEffect(() => {
    if (isEditing && inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleRecordActionsButton = useCallback(() => {
    if (promptGeneratorEnabled) {
      setPromptGeneratorEnabled(false);
      if (enrichedPrompts.length > 0) {
        setOpenSuccessPromptDialog(true);
      }
    } else {
      setPromptGeneratorEnabled(true);
      trackAmplitudeEvent({
        eventType: "Assistant recording started",
        eventProperties: {
          testId: test?.id,
        },
        groups: { organisation: companyData.name },
      });
    }
  }, [promptGeneratorEnabled, test, enrichedPrompts, companyData]);

  const handleUpdateTitleOnKeyBlur = (
    e: React.FocusEvent<HTMLInputElement>
  ) => {
    setIsEditing(false);
    setTestEditorProperties((prev) => ({
      ...prev,
      recordingTitle: e.target.value,
    }));
  };

  const handleUpdateTitleOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior of submitting the form
      setIsEditing(false);
      setTestEditorProperties((prev) => ({
        ...prev,
        recordingTitle: (e.target as HTMLInputElement).value,
      }));
    }
  };

  const [shouldForceRedirect, setShouldForceRedirect] = useState(false);

  let redirectBlocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !testEditorNotDirty &&
      !shouldForceRedirect &&
      currentLocation.pathname !== nextLocation.pathname
  );

  const saveTestMutation = useMutation({
    mutationKey: ["saveTestMutation"],
    mutationFn: async () => {
      const uploadEnrichedLogs = async (
        testId: string,
        hadEnrichedInteractionsLogsPath: boolean
      ) => {
        let newEnrichedInteractions = {};

        const enrichedInteractionLogsRef = ref(
          customStorage,
          `enrichedInteractionLogs/${testId}/logs.json`
        );

        let oldScreenshotsRefs: string[] = [];
        if (test?.promptGenerationScreenshots != null) {
          oldScreenshotsRefs = Object.keys(
            test!.promptGenerationScreenshots!
          ).reduce((prev, current) => {
            const newScreenshots =
              promptGenerationScreenshots[parseInt(current)];
            const oldScreenshots =
              test!.promptGenerationScreenshots![parseInt(current)];

            return [
              ...prev,
              ...(newScreenshots == null
                ? oldScreenshots.map(
                    (screenshotUrl) =>
                      ref(customStorage, screenshotUrl).fullPath
                  )
                : []),
            ];
          }, [] as string[]);
        }

        const docRef = doc(customFirestore, "custom-tests", testId);
        if (enrichedPrompts.length > 0) {
          newEnrichedInteractions = await Object.keys(
            promptGenerationScreenshots
          ).reduce<
            Promise<{ [id: number]: { rawLog: string; enrichedLog: string }[] }>
          >(async (prev, current) => {
            const currentIndex = parseInt(current);
            const screenshotUrls = await Promise.all(
              promptGenerationScreenshots[currentIndex].map(
                async (screenshot, index) => {
                  const screenshotRef = ref(
                    customStorage,
                    `enrichedInteractionLogs/${testId}/screenshot-${currentIndex}-${
                      index + 1
                    }.png`
                  );
                  const screenshotBlob = await b64toBlob(screenshot);
                  await uploadBytes(screenshotRef, screenshotBlob);
                  return await getDownloadURL(ref(screenshotRef));
                }
              )
            );

            const awaitedPrev = await prev;
            return {
              ...awaitedPrev,
              [currentIndex]: promptGenerationScreenshots[currentIndex].map(
                (_, index) => ({
                  ...(enrichedFromApiInteractions[currentIndex]?.[index] ??
                    enrichedInteractions[currentIndex][index]),
                  screenshotUrl: screenshotUrls[index],
                })
              ),
            };
          }, Promise.resolve({}));
          await uploadString(
            enrichedInteractionLogsRef,
            JSON.stringify(newEnrichedInteractions)
          );
          await updateDoc(docRef, {
            enrichedInteractionsLogsPath: enrichedInteractionLogsRef.fullPath,
          });
        } else if (hadEnrichedInteractionsLogsPath) {
          try {
            await deleteObject(
              ref(customStorage, enrichedInteractionLogsRef.fullPath)
            );
            await updateDoc(docRef, {
              enrichedInteractionsLogsPath: deleteField(),
            });
          } catch (e) {
            trackSentryException(e);
            throw e;
          }
        }
        if (oldScreenshotsRefs.length > 0) {
          await Promise.all(
            oldScreenshotsRefs.map((oldScreenshotRef) =>
              deleteObject(ref(customStorage, oldScreenshotRef))
            )
          );
        }
      };

      try {
        if (Object.keys(settingsFormRef.current?.errors ?? {}).length > 0) {
          settingsFormRef.current?.submitForm();
          toast.error(`Please fix the settings errors before saving the test`);
          return;
        }

        provider?.createVersion(user.email);

        const testSettings = settingsFormRef.current?.values ?? {
          testInputs: [],
          launchParams: [],
          preRequests: [],
        };

        let newTestId: string;
        if (test == null) {
          // Create a new document if testId is not set
          await setDoc(newTestDocRef!, {
            title: testEditorProperties.recordingTitle,
            commands: JSON.stringify(testEditorProperties.testCommands as Node),
            userId: user.uid,
            organisationId: buildData!.organisationId,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            tag: "",
            type: "gpt",
            uploadId: buildId,
            settings: testSettings,
            dependencyInputValues: {
              ...(selectedDependencyId != null && {
                [selectedDependencyId]:
                  dependencyFormRef.current?.values?.dependencyInputValues ??
                  [],
              }),
            },
          });

          newTestId = newTestDocRef!.id;

          // cache last successful run when saving a new test
          if (localLastSuccessfulRun.length > 0) {
            const localLastSuccessfulRunToBeCached: CachedScreenData[] =
              await Promise.all(
                localLastSuccessfulRun.map(async (value, index) => {
                  const screenshotLink = await uploadScreenshotToStorage(
                    value.screenshotUrl,
                    `cachedScreenshots/${newTestId}/${sessionConfig.platform}-${
                      sessionConfig.device
                    }-cachedScreenshot-${index + 1}.jpg`
                  );

                  const {
                    annotatedScreenshotUrl,
                    serverExecutionParams,
                    ...filteredCacheStep
                  } = value;
                  console.log("filteredCacheStep step to be saved", {
                    ...filteredCacheStep,
                    screenshotUrl: screenshotLink,
                  });
                  return {
                    ...filteredCacheStep,
                    screenshotUrl: screenshotLink,
                  };
                })
              );

            await updateDoc(newTestDocRef!, {
              cachedSteps: {
                [sessionConfig.platform]: {
                  [sessionConfig.device]:
                    localLastSuccessfulRunToBeCached ?? [],
                },
              },
            });
          }

          await uploadEnrichedLogs(newTestId, false);
          if (selectedDependencyId != null) {
            await addDoc(
              collection(
                customFirestore,
                `custom-tests/${newTestDocRef!.id}/dependencies`
              ),
              {
                order: 1,
                testId: selectedDependencyId,
              }
            );
          }
          trackAmplitudeEvent({
            eventType: "Save Test",
            eventProperties: { testId: newTestId, type: "create" },
            groups: { organisation: companyData.name },
          });
        } else {
          // Update the existing document with testId as ID
          const docRef = doc(customFirestore, "custom-tests", test.id);

          const dependencyInputValuesToUpdate = `dependencyInputValues.${selectedDependencyId}`;

          const updatedTest = {
            title: testEditorProperties.recordingTitle,
            commands: JSON.stringify(testEditorProperties.testCommands as Node),
            settings: testSettings,
            ...(selectedDependencyId != null &&
              dependencyFormRef.current?.values?.dependencyInputValues !=
                null && {
                [dependencyInputValuesToUpdate]:
                  dependencyFormRef.current?.values?.dependencyInputValues,
              }),
            updatedAt: Timestamp.now(),
          };

          if (test.folderId) {
            const shouldReloadTestList = await runTransaction(
              customFirestore,
              async (transaction) => {
                let folderActuallyExists = false;

                const lastTestValue = (
                  await transaction.get(docRef)
                ).data() as Test;

                if (lastTestValue.folderId != null) {
                  folderActuallyExists = true;

                  const folderDocRef = doc(
                    customFirestore,
                    "folders",
                    lastTestValue!.folderId!
                  );
                  const folderSnapshot = await transaction.get(folderDocRef);
                  const folderData = folderSnapshot.data() as TestFolder;
                  const testPreviewIndex =
                    folderData.tests?.findIndex(
                      (testPreview) => testPreview.id === test.id
                    ) ?? -1;
                  if (testPreviewIndex != -1) {
                    const newTests = replaceItemFomArray(
                      folderData.tests!,
                      testPreviewIndex,
                      {
                        id: test.id,
                        title: updatedTest.title,
                        updatedAt: updatedTest.updatedAt,
                      }
                    );

                    transaction.update(folderDocRef, { tests: newTests });
                  }
                }
                transaction.update(docRef, updatedTest);
                return folderActuallyExists;
              }
            );

            if (shouldReloadTestList) {
              await queryClient.invalidateQueries({
                queryKey: ["getFolders"],
              });
              getTestsAtom.remove({
                organizationId: buildData!.organisationId,
                folderId: test!.folderId,
              });
            }
          } else {
            await updateDoc(docRef, updatedTest);
          }

          await uploadEnrichedLogs(
            test.id,
            test.enrichedInteractionsLogsPath != null
          );

          if (selectedDependencyId != test?.dependencies?.[0]?.testId) {
            if (test?.dependencies?.[0]?.testId != null) {
              const dependencyRef = doc(
                customFirestore,
                "custom-tests",
                test!.id,
                "dependencies",
                test!.dependencies![0].id
              );
              if (selectedDependencyId == null) {
                await deleteDoc(dependencyRef);
              } else {
                await updateDoc(dependencyRef, {
                  order: 1,
                  testId: selectedDependencyId,
                });
              }
            } else {
              if (selectedDependencyId != null) {
                await addDoc(
                  collection(
                    customFirestore,
                    `custom-tests/${test!.id}/dependencies`
                  ),
                  {
                    order: 1,
                    testId: selectedDependencyId,
                  }
                );
              }
            }
          }
          trackAmplitudeEvent({
            eventType: "Save Test",
            eventProperties: { testId: test.id, type: "update" },
            groups: { organisation: companyData.name },
          });
        }
        setTestCommandsUpdated(false);
        setIsDirty(false);

        getTestsAtom.remove({
          organizationId: buildData!.organisationId,
          folderId: kAllTestsId,
        });
        queryClient.removeQueries({ queryKey: ["getTests"] });
        await queryClient.invalidateQueries({
          queryKey: ["getTestForTestEditor"],
        });

        if (redirectBlocker.state == "blocked") {
          redirectBlocker.proceed?.();
        } else if (test == null) {
          setShouldForceRedirect(true);
          setTimeout(() => {
            setSession(null);
            buildLogsAtomWithId.remove(buildId!);
            navigateTo(`/gpt-driver/build/${buildId}/test/${newTestId!}`);
          }, 500);
        }
      } catch (error) {
        if (redirectBlocker.state == "blocked") {
          redirectBlocker.reset();
        }
        trackSentryException(error);
        console.error("Error saving recording: ", error);
        setCustomAlertState({
          open: true,
          type: "error",
          title: "Something went wrong",
          description: getErrorMessage(error),
        });
      }
    },
  });

  const flattenSteps: ExecutionStepState[] = useMemo(() => {
    return executionState.runs.map((run) => run.executionSteps).flat();
  }, [executionState.runs]);

  const prepareCSVData = useCallback(() => {
    const extractTargetValue = (command: string) => {
      if (command.includes("tabOn")) {
        const coordinates = getDigitsAfterFirstSemicolon(command);
        if (coordinates !== null) {
          let [xPos, yPos] = coordinates;
          [xPos, yPos] = [Number(xPos), Number(yPos)];
          return `.*tabOn:.*(${xPos - 1}|${xPos}|${xPos + 1}).*(${
            yPos - 1
          }|${yPos}|${yPos + 1}).*`;
        }
      } else if (command.includes("task complete")) {
        return ".*task complete:.*";
      }
      return `.*${command}.*`;
    };

    const promptSteps = getPromptStepsFromTipTapNode({
      node: testEditorProperties.testCommands,
    });
    const parsedCommands = getPromptStepsText(promptSteps);

    const testTask = parsedCommands.replace(
      /{{(.*?)}}/g,
      (match, key) => replaceTemplateItem(key.trim()) || ""
    );
    // prev appetizeSteps = [["example1", "example2"], ["example3", "example4"]]

    const targetValuesArray = flattenSteps
      .map((step) => [extractTargetValue(step.appetizeCommands[0])])
      .map((value) => value.join("\n"));
    const screenContentArray = flattenSteps.map((step) =>
      step.screenContent.join("\n")
    );
    const actionHistoryArray = flattenSteps.map((step) =>
      step.actionHistory.join("\n")
    );
    const labels = targetValuesArray.map(
      (_, index) => `${testEditorProperties.recordingTitle} Step ${index + 1}`
    );

    const rows = [
      ["label", "test_task", "action_history", "screen_content", "target"],
    ];
    for (let i = 0; i < targetValuesArray.length; i++) {
      rows.push([
        labels[i],
        testTask,
        actionHistoryArray[i],
        screenContentArray[i],
        targetValuesArray[i],
      ]);
    }

    return rows;
  }, [flattenSteps, testEditorProperties.testCommands]);

  const exportToCSV = (e) => {
    try {
      const csvData = prepareCSVData();
      const filename = `TestCase_${testEditorProperties.recordingTitle.replace(
        " ",
        "_"
      )}.csv`;
      downloadBlob(arrayToCsv(csvData), filename, "text/csv;charset=utf-8;");
    } catch (e) {
      trackSentryException(e);
      console.error("Error occurred while exporting the test steps: ", e);
      alert(`Error occurred while exporting the test steps: ${e}`);
    }
  };
  const removeCachedSteps = async (
    deleteAll: boolean,
    stepNumber: number = 0
  ) => {
    if (
      test &&
      test.id &&
      test.cachedSteps &&
      test.cachedSteps?.[sessionConfig.platform]?.[sessionConfig.device]
        ?.length > 0
    ) {
      const updatedCachedSteps = await deleteCachedSteps(
        test.id,
        deleteAll,
        sessionConfig.platform,
        sessionConfig.device,
        stepNumber
      );
      console.log("Updated data", updatedCachedSteps);

      const updatedTest = {
        ...test,
        cachedSteps: updatedCachedSteps,
      };

      queryClient.setQueryData(["getTestForTestEditor", test.id!], updatedTest);
    } else {
      if (deleteAll) {
        setPromptGenerationScreenshots({});
        setLocalLastSuccessfulRun([]);
        setAuxLocalLastSuccessfulRun(null);
      } else {
        if (localLastSuccessfulRun.length > 0) {
          const updatedLocalLasSuccessfulRun = localLastSuccessfulRun.filter(
            (value, index) => index !== stepNumber
          );
          setLocalLastSuccessfulRun(updatedLocalLasSuccessfulRun);
        } else if (Object.keys(promptGenerationRecordedActions).length > 0) {
          console.log("Removing assitant step", stepNumber);
          const stepToBeDeleted = promptGenerationRecordedActions[stepNumber];
          const updatedGenerationScreenshots: typeof promptGenerationScreenshots =
            {};
          console.log({ promptGenerationScreenshots });
          console.log({ updatedGenerationScreenshots });
          for (const [index, screenshotsList] of Object.entries(
            promptGenerationScreenshots
          )) {
            updatedGenerationScreenshots[parseInt(index)] =
              screenshotsList.filter(
                (value) => value !== stepToBeDeleted.screenshotUrl
              );
          }
          setPromptGenerationScreenshots(updatedGenerationScreenshots);
        }
      }
    }
  };

  const resetPromptGenerator = () => {
    setEnrichedInteractions({});
    setPromptGenerationScreenshots({});
    setEnrichedFromApiInteractions({});
    firstPressedKeyTimestamp.current = 0;
  };

  const parsedEnvVars = useMemo(
    () =>
      companyData.settings.envVars?.map((envVar) => `env.${envVar.key}`) ?? [],
    [companyData.settings]
  );

  const annotatedScreenshotsQuery = useQuery({
    queryKey: ["annotateAssistantScreenshots", promptGenerationScreenshots],
    queryFn: async () => {
      const annotatedScreenshots: { [p: number]: string[] } = {};

      try {
        for (const [sortedInteractionId, screenshotsList] of Object.entries(
          promptGenerationScreenshots
        )) {
          const parsedInteractionId = parseInt(sortedInteractionId);
          annotatedScreenshots[parsedInteractionId] = [];
          for (const screenshotUrl of screenshotsList) {
            const index = screenshotsList.indexOf(screenshotUrl);
            const appetizeCommand =
              enrichedInteractions[parsedInteractionId][index].rawLog;
            const annotatedScreen = await annotateScreenshot(screenshotUrl, [
              appetizeCommand,
            ]);
            annotatedScreenshots[parsedInteractionId].push(annotatedScreen);
          }
        }

        return annotatedScreenshots;
      } catch (e) {
        console.error(e);
        trackSentryException(e);
        return promptGenerationScreenshots; //not annotated screenshots
      }
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const testEditorNotDirty = useMemo(
    () =>
      testEditorProperties.originalRecordingTitle ===
        testEditorProperties.recordingTitle &&
      !testCommandsUpdated &&
      test?.dependencies?.[0]?.testId == selectedDependencyId &&
      !testEditorProperties.hasGeneratedEnrichedLogs &&
      !isDirty,
    [
      testEditorProperties.originalRecordingTitle,
      testEditorProperties.recordingTitle,
      testCommandsUpdated,
      testEditorProperties.hasGeneratedEnrichedLogs,
      isDirty,
      test?.dependencies,
      selectedDependencyId,
    ]
  );

  const isSaveButtonDisabled = useMemo(
    () =>
      saveTestMutation.isPending || testEditorNotDirty || customTests.isError,
    [saveTestMutation.isPending, customTests.isError, testEditorNotDirty]
  );

  useEffect(() => {
    setForceUpdateManualConditionsState({
      unsavedTest: saveTestMutation.isPending || !testEditorNotDirty,
    });

    return () => {
      setForceUpdateManualConditionsState({
        unsavedTest: false,
      });
    };
  }, [saveTestMutation.isPending, testEditorNotDirty]);

  const acceptedDynamicValues = useMemo(
    () => [
      "timestamp",
      "currentDate",
      "currentDateFormatted",
      ...parsedEnvVars,
      ...(settingsFormRef.current?.values?.inputKeys?.map(
        (input) => input.key
      ) ?? []),
    ],
    [parsedEnvVars, settingsFormRef.current]
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <CreateSuccessPromptDialog
        open={openSuccessPromptDialog}
        setOpen={setOpenSuccessPromptDialog}
        test={test}
        confirmButtonHandler={createSuccessPrompt}
        enrichedPrompts={enrichedPrompts}
        companyData={companyData}
      />
      <ThemeProvider theme={lightTheme}>
        <AsyncConfirmationModal
          open={redirectBlocker.state == "blocked"}
          secondaryActionHandler={redirectBlocker.proceed}
          closeHandler={redirectBlocker.reset}
          actionHandler={() => saveTestMutation.mutateAsync()}
          submitting={saveTestMutation.isPending}
          title={"Hold on, you have unsaved changes!"}
          description={
            "If you leave now, you'll lose all unsaved changes.\n" +
            "Would you like to save before exiting?"
          }
          actionTitle={"Save & Exit"}
          actionIcon={<Save />}
          backTitle={"Exit Without Saving"}
        />
      </ThemeProvider>
      <div className="flex mb-1 gap-4">
        <div className={"flex gap-2 items-start basis-3/4"}>
          {isEditing ? (
            <input
              ref={inputRef}
              className="border-b-2 border-white text-white bg-transparent focus:outline-none w-full"
              defaultValue={testEditorProperties.recordingTitle}
              onBlur={handleUpdateTitleOnKeyBlur}
              onKeyDown={handleUpdateTitleOnKeyDown}
            />
          ) : (
            <span className="text-white text-lg">
              {testEditorProperties.recordingTitle}
            </span>
          )}
          <BorderColorIcon
            fontSize={"small"}
            className={"text-[#7C7C7C] mt-1"}
            onClick={handleEditClick}
          />
        </div>
        <div className={"flex basis-1/3 items-start gap-2 justify-end"}>
          {saveTestMutation.isPending && <RenderSpinner />}
          {(user.companyName === "kitchenful" || user.role === "superUser") && (
            <div className="flex basis-1/3 items-start gap-2 justify-end">
              <Tooltip
                title={"Export execution log as CSV file"}
                placement="top"
              >
                <span>
                  <Button
                    onClick={exportToCSV}
                    variant={"outlined"}
                    size={"large"}
                    disabled={
                      session === null ||
                      saveTestMutation.isPending ||
                      flattenSteps.length === 0
                    }
                  >
                    CSV
                  </Button>
                </span>
              </Tooltip>
            </div>
          )}
          <Button
            onClick={() => saveTestMutation.mutate()}
            variant={"outlined"}
            size={"large"}
            disabled={isSaveButtonDisabled}
          >
            {saveTestMutation.isPending ? "Saving..." : "Save"}
          </Button>
        </div>
      </div>
      <Box sx={{ width: "100%", overflow: "auto" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "8px" }}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
            scrollButtons={true}
            sx={{
              "& .MuiButtonBase-root": {
                minHeight: "0px",
              },
            }}
          >
            <Tab
              label="Prompt"
              icon={<Notes />}
              iconPosition={"start"}
              {...a11yProps("prompt")}
            />
            {
              <Tab
                label="Execution log"
                icon={<Compare />}
                iconPosition={"start"}
                {...a11yProps("logs")}
              />
            }
            <Tab
              icon={<AutoAwesome />}
              label="Assistant"
              iconPosition={"start"}
              {...a11yProps("generator")}
            />
            {companyData.settings.testSettingsEnabled && (
              <Tab
                label="Settings"
                icon={<Settings />}
                iconPosition={"start"}
                {...a11yProps("settings")}
              />
            )}
          </Tabs>
        </Box>
        <CustomTabPanel value={"prompt"} hidden={activeTab !== "prompt"}>
          <Paper sx={{ paddingTop: "8px" }}>
            {!!provider && !!document && connected ? (
              <TipTapEditor
                testEditorProperties={testEditorProperties}
                setTestCommandsUpdated={setTestCommandsUpdated}
                setTestEditorProperties={setTestEditorProperties}
                running={executionState.isRunning}
                stopExecution={stopExecutionHandler}
                session={session}
                isNewTest={test == null}
                testCommandsUpdated={testCommandsUpdated}
                provider={provider}
                document={document}
                selectedTestDependencyId={selectedDependencyId ?? undefined}
                auxDisplayPasteStep={auxDisplayPasteStep}
                setAuxDisplayPasteStep={setAuxDisplayPasteStep}
                abstractPromptGeneratorEnabled={
                  companyData.settings.abstractPromptGeneratorEnabled!
                }
                executeMutation={executeMutation}
                executeWithDependenciesMutation={
                  executeWithDependenciesMutation
                }
                userEmail={user.email}
                templates={companyData.templates}
                suggestionsEnabled={
                  companyData.settings.enableCommandSuggestions!
                }
                acceptedDynamicValues={acceptedDynamicValues}
              />
            ) : (
              <div className={"flex justify-center pb-3.5"}>
                <CircularProgress />
              </div>
            )}
          </Paper>

          <div className={"my-4"}>
            {/* TODO make the Formik handle saving and sending values to firestore and displaying it from test document if the testInputsValues already exist */}
            {customTests.isSuccess && (
              <Formik<DependencyFormState>
                innerRef={dependencyFormRef}
                initialValues={{
                  dependencyId: dependencyFormInitialState.selectedDependencyId,
                  dependencyInputValues:
                    dependencyFormInitialState.dependencyInputs,
                }}
                validationSchema={object({
                  dependencyId: string().nullable(),
                  dependencyInputValues: array(
                    object({
                      key: string().required("Required field"),
                      value: string().required("Required field"),
                    })
                  ).default([]),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  resetForm();
                  setSubmitting(false);
                }}
                validateOnBlur={true}
                enableReinitialize={true}
              >
                {({ values, setValues }) => (
                  <Form>
                    <div className={"pl-4  flex flex-col gap-4"}>
                      <div className={"flex flex-col gap-2"}>
                        <h3 className="text-[#7C7C7C] text-[16px] mb-2">
                          Test Dependency
                        </h3>
                        <FormikSelect
                          sx={{ maxWidth: "384px" }}
                          name={"dependencyId"}
                          key={"dependencyId"}
                          label={"Dependency"}
                          optionTitleKey={"title"}
                          valueKey={"id"}
                          options={customTests.data}
                          onChange={async (value) => {
                            const newValue = value === "0" ? null : value;
                            await setValues((prev) => ({
                              ...prev,
                              dependencyId: newValue,
                            }));
                            setSelectedDependencyId(newValue);
                          }}
                        />
                      </div>
                      {values.dependencyInputValues.length > 0 && (
                        <div className={"flex flex-col gap-2"}>
                          <h3 className="text-[#7C7C7C] text-[16px] mb-2 ">
                            Required test inputs for this dependency
                          </h3>
                          <div className={"flex flex-col gap-4 items-start"}>
                            <div className={"pt-4 flex flex-col gap-4"}>
                              {values.dependencyInputValues.map(
                                (item, index) => (
                                  <div
                                    key={index}
                                    className={"flex gap-3 items-start"}
                                  >
                                    <FormikTextInput
                                      name={`dependencyInputValues.${index}.key`}
                                      label={"Key"}
                                      placeholder={item.key}
                                      type={"text"}
                                      value={item.key}
                                      disabled
                                    />
                                    <FormikTextInput
                                      name={`dependencyInputValues.${index}.value`}
                                      label={"Value"}
                                      placeholder={"Value"}
                                      type={"text"}
                                      required
                                      value={item.value}
                                      onChange={() => {
                                        setIsDirty(true);
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={"logs"} hidden={"logs" !== activeTab}>
          <TestEditorExecutionLogs
            setActiveTab={setActiveTab}
            setAuxDisplayPasteStep={setAuxDisplayPasteStep}
            baseline={auxLocalLastSuccessfulRun ?? localBaseline}
            currentLogs={parsedExecutionLogs ?? []}
            removeCachedSteps={removeCachedSteps}
            running={executionState.isRunning}
            stopExecution={stopExecutionHandler}
            acceptedDynamicValues={acceptedDynamicValues}
            isPreparingData={
              executeMutation.isPending ||
              executeWithDependenciesMutation.isPending
            }
            companyData={companyData}
            sessionConfig={sessionConfig}
            test={test}
            buildId={buildId!}
          />
        </CustomTabPanel>
        <CustomTabPanel value={"generator"} hidden={"generator" !== activeTab}>
          <TestEditorAssistant
            promptGeneratorEnabled={promptGeneratorEnabled}
            handleRecordActionsButton={handleRecordActionsButton}
            session={session}
            enrichedPrompts={enrichedPrompts}
            resetPromptGenerator={resetPromptGenerator}
            sortedInteractionIds={sortedInteractionIds}
            promptGenerationScreenshots={promptGenerationScreenshots}
            enrichedInteractions={enrichedInteractions}
            scrollableRef={scrollableRef}
            enrichedFromApiInteractions={enrichedFromApiInteractions}
            annotatedScreenshots={annotatedScreenshotsQuery.data}
          />
        </CustomTabPanel>
        {companyData.settings.testSettingsEnabled === true && (
          <CustomTabPanel value={"settings"} hidden={activeTab !== "settings"}>
            <TestEditorSettings
              formRef={settingsFormRef}
              test={test}
              setIsDirty={setIsDirty}
              envVars={companyData.settings.envVars}
            />
          </CustomTabPanel>
        )}
      </Box>
    </ThemeProvider>
  );
}

function a11yProps(value: string) {
  return {
    id: `simple-tab-${value}`,
    "aria-controls": `simple-tabpanel-${value}`,
    value,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  hidden: boolean;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, hidden, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id={`simple-tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      {...other}
    >
      <Box sx={{ p: 1.5 }}>{children}</Box>
    </div>
  );
}

const CreateSuccessPromptDialog = ({
  open,
  setOpen,
  confirmButtonHandler,
  test,
  enrichedPrompts,
  companyData,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  confirmButtonHandler: any;
  test: Test | null | undefined;
  enrichedPrompts: string[];
  companyData: CompanyData;
}) => {
  const handleClose = useCallback(() => {
    setOpen(false);
    trackAmplitudeEvent({
      eventType: "Assistant recording stopped",
      eventProperties: {
        testId: test?.id,
        successCriteria: false,
        interactions: enrichedPrompts.length,
      },
      groups: { organisation: companyData.name },
    });
  }, [companyData, test, enrichedPrompts]);

  return (
    <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Add a success assertion?</Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        Ensure robust test execution by adding a success assertion to the end of
        your generated prompt.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant={"text"}>
          <Typography>No, thanks</Typography>
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
            confirmButtonHandler();
          }}
        >
          <CheckIcon strokeWidth={2} className="h-5 w-5" />
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
