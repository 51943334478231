import { Checkbox, FormControlLabel } from "@mui/material";
import { useField } from "formik";

const FormikCheckbox = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormControlLabel
      {...field}
      {...props}
      onChange={(_, checked) => {
        if (props.onChange != null) {
          props.onChange(checked);
        }
        helpers.setValue(checked);
      }}
      control={<Checkbox defaultChecked />}
      label={label}
    />
  );
};

export default FormikCheckbox;
