import { atomFamily } from "jotai/utils";
import { atomWithQuery } from "jotai-tanstack-query";
import {
  collection,
  getDocs,
  limit as limitFirebase,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { customFirestore } from "../firebase";
import { BuildData } from "../utils/types";

const getBuildsAtom = atomFamily(
  ({ organizationId, limit }: { organizationId: string; limit?: number }) => {
    return atomWithQuery((get) => ({
      queryKey: ["getBuilds", organizationId, limit],
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      queryFn: async () => {
        const testsRef = collection(customFirestore, "uploads");

        const q = query(
          testsRef,
          where("organisationId", "==", organizationId),
          ...(organizationId === "6693701a43c9d0f2c2900d2827b9ed76cb18205e"
            ? [where("app_name", "==", "Pazza Pasta Staging")]
            : []), // Circus hacky solution
          orderBy("epoch", "desc"),
          ...(limit != null ? [limitFirebase(limit)] : [])
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          return [];
        }
        return querySnapshot.docs.map((build) => {
          const buildData = build.data() as BuildData;
          buildData.id = build.id;
          return buildData;
        });
      },
    }));
  },
  (a, b) => a.organizationId === b.organizationId && a.limit === b.limit
);

export default getBuildsAtom;
