import { atomFamily } from "jotai/utils";
import { atomWithMutation } from "jotai-tanstack-query";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { customFirestore } from "../firebase";
import {
  CachedScreenData,
  PromptStep,
  Test,
  TestSettings,
} from "../utils/types";
import { getGptDriverInput, prepareCachedFlow } from "../utils/helpers";

const getNestedDependencies = async ({
  testId,
  tempDependencies,
  cachingEnabled,
  platform,
  device,
}: {
  cachingEnabled: boolean;
  platform: string;
  device: string;
  testId: string;
  tempDependencies: {
    promptSteps: PromptStep[];
    testId: string;
    title: string;
    cache: {
      enabled: boolean;
      data: CachedScreenData[];
    };
    settings: TestSettings;
    dependencyInputValues: { key: string; value: string }[];
    platform: string;
    device: string;
  }[];
}): Promise<
  {
    promptSteps: PromptStep[];
    testId: string;
    title: string;
    cache: {
      enabled: boolean;
      data: CachedScreenData[];
    };
    settings: TestSettings;
    dependencyInputValues: { key: string; value: string }[];
    platform: string;
    device: string;
  }[]
> => {
  const testRef = doc(customFirestore, `custom-tests/${testId}`);
  const testSnap = await getDoc(testRef);
  const testData = testSnap.data() as Test;
  const subCollectionRef = collection(
    customFirestore,
    `custom-tests/${testId}/dependencies`
  );
  const subCollectionSnapshot = await getDocs(subCollectionRef);

  const promptSteps = getGptDriverInput(testData.commands);
  const baseline = testData.cachedSteps?.[platform]?.[device] ?? [];

  const { allowCachedRun, cachedData } = await prepareCachedFlow(
    promptSteps,
    baseline,
    cachingEnabled,
    platform,
    testData.organisationId
  );

  const settings = {
    launchParams: testData.settings?.launchParams ?? [],
    preRequests:
      testData.settings?.preRequests?.map((preRequest) => ({
        ...preRequest,
        delayBeforeRequest: preRequest.delayBeforeRequest ?? 0,
      })) ?? [],
    inputKeys: testData.settings?.inputKeys ?? [],
  };

  const newTempDependencies = [
    ...tempDependencies,
    {
      testId: testSnap.id,
      promptSteps,
      title: testData.title,
      baseline,
      cache: {
        enabled: allowCachedRun,
        data: cachedData,
      },
      settings,
      platform,
      device,
      dependencyInputValues: subCollectionSnapshot.empty
        ? []
        : testData.dependencyInputValues?.[
            subCollectionSnapshot.docs[0].data().testId
          ] ?? [],
    },
  ];
  if (subCollectionSnapshot.empty) {
    return newTempDependencies;
  }

  const dependencyDoc = subCollectionSnapshot.docs[0];
  const currentTestDependencyIds = tempDependencies.map(
    (testDependency) => testDependency.testId
  );
  if (currentTestDependencyIds.includes(dependencyDoc.id)) {
    return tempDependencies;
  }
  return await getNestedDependencies({
    cachingEnabled: cachingEnabled,
    platform: platform,
    device: device,
    testId: dependencyDoc.data().testId,
    tempDependencies: newTempDependencies,
  });
};

export { getNestedDependencies };
