import { type CompanySettings } from "../utils/types";

export const DEFAULT_SETTINGS: CompanySettings = {
  displayNetworkLogs: {
    type: "boolean",
    value: true,
    label: "Display network logs",
  },
  // location: {
  //   type: "string",
  //   value: "40.75684279398277, -73.98450146642257",
  //   label: "Location",
  // },
  parallelRuns: {
    type: "number",
    value: 1,
    label: "Number of parallel runs",
    disabled: true,
  },
  numberOfRetriesOnFailure: {
    type: "dropdown",
    value: 0,
    label: "Number of retries after failed test",
    options: [0, 1, 2, 3],
  },
  waitForStableScreenTime: {
    type: "number",
    value: 3000,
    label: "Wait for stable screen time (ms)",
    disabled: false,
  },
  webhookUrl: {
    type: "string",
    value: "",
    label: "Webhook URL to be called after test runs",
  },
  webhookTrigger: {
    type: "dropdown",
    value: "whole suite completion",
    label: "Call the webhook after",
    options: [
      "whole suite completion",
      "single test run",
      "both: whole suite completion and single test run",
    ],
  },
  webhookAuthHeader: {
    type: "string",
    value: "",
    label: "(Optional) Webhook authorization header",
    tooltip:
      "This value will be used directly in the POST request header: { Authorization: <value> }",
  },
  customHeaders: {
    type: "map",
    value: [],
    label: "(Optional) Custom headers",
  },
  region: {
    type: "dropdown",
    value: "automatic",
    label: "Region",
    options: ["automatic", "us", "eu"],
  },
  autoGrantPermissions: {
    type: "boolean",
    value: false,
    label: "Automatically grant permissions",
  },
};
