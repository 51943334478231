import { atomFamily } from "jotai/utils";
import { atomWithQuery } from "jotai-tanstack-query";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { getDownloadURL, ref } from "firebase/storage";
import axios from "axios";
import { customFirestore, customStorage } from "../../firebase";
import {
  CompanyData,
  RunDependency,
  RunData,
  RunExecutionData,
  TestSuiteData,
  TestSuiteRunExecutionData,
  TestSuiteRunRecordingData,
  InputKey,
  InteractionLog,
} from "../../utils/types";
import { FirebaseError } from "firebase/app";
import {
  getDevice,
  getGptDriverInput,
  getOs,
  prepareCachedFlow,
  trackSentryException,
} from "../../utils/helpers";
import { kLanguages } from "../../constants/appetize-constants";

const testSuiteDataAtom = atomFamily((suiteId: string) => {
  return atomWithQuery((get) => ({
    queryKey: ["getTestSuiteData", suiteId],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
    queryFn: async ({ queryKey: [, suiteId] }) => {
      const testSuiteRef = await getDoc(
        doc(customFirestore, "custom-test-suite-runs", suiteId as string)
      );
      if (testSuiteRef.exists()) {
        const data = testSuiteRef.data() as TestSuiteData;
        return { ...data, metaData: data.metaData ?? {} } as TestSuiteData;
      } else {
        throw new Error("Test suite doesn't exist");
      }
    },
  }));
});

const getRuns = async ({
  suiteId,
  runId,
  parentRunData,
  organisationId,
  forRecording,
}: {
  suiteId: string;
  runId: string;
  parentRunData: RunData;
  organisationId: string;
  forRecording: boolean;
}) => {
  const dependenciesQuery = query(
    collection(
      customFirestore,
      "custom-test-suite-runs",
      suiteId as string,
      "runs",
      runId as string,
      "dependencies"
    )
  );

  const device = getDevice(
    parentRunData.platform.toLowerCase() as "android" | "ios",
    parentRunData.device
  );
  const os = getOs(
    parentRunData.platform.toLowerCase() as "android" | "ios",
    device,
    parentRunData.os
  );

  const dependenciesSnap = await getDocs(dependenciesQuery);

  const dependencies: RunExecutionData[] = dependenciesSnap.empty
    ? []
    : (
        await Promise.all(
          dependenciesSnap.docs.map(async (dependency, index) => {
            const dependencyData = dependency.data() as RunDependency;

            const dependencyPromptSteps = getGptDriverInput(
              dependencyData.commands
            );
            const { allowCachedRun, cachedData } = await prepareCachedFlow(
              dependencyPromptSteps,
              dependencyData.cache?.data ?? [],
              !forRecording && dependencyData.cache?.enabled,
              parentRunData.platform.toLowerCase(),
              organisationId
            );

            const settings = {
              launchParams: dependencyData.settings?.launchParams ?? [],
              preRequests:
                dependencyData.settings?.preRequests?.map((preRequest) => ({
                  ...preRequest,
                  delayBeforeRequest: preRequest.delayBeforeRequest ?? 0,
                })) ?? [],
              inputKeys: dependencyData.settings?.inputKeys ?? [],
            };

            const prevDependency = dependenciesSnap.docs.find(
              (dep) =>
                (dep.data() as RunDependency).order === dependencyData.order - 1
            );

            return {
              id: dependency.id,
              device,
              os,
              testId: dependencyData.custom_test_id,
              promptSteps: dependencyPromptSteps,
              cache: {
                enabled: allowCachedRun,
                data: cachedData,
              },
              settings,
              platform: dependencyData.platform,
              orientation: dependencyData.orientation,
              locale: dependencyData.locale,
              order: dependencyData.order,
              startedTimeInEpoch: dependencyData.startedTimeInEpoch,
              runData: dependencyData,
              dependencyInputValues:
                prevDependency == null
                  ? []
                  : parentRunData?.dependencyInputValues?.[
                      (prevDependency.data() as RunDependency).custom_test_id!
                    ] ?? [],
            } as RunExecutionData;
          })
        )
      ).sort((a, b) => a.order - b.order);

  const parentRunPromptSteps = getGptDriverInput(parentRunData.commands);
  const { allowCachedRun, cachedData } = await prepareCachedFlow(
    parentRunPromptSteps,
    parentRunData.cache?.data ?? [],
    !forRecording && parentRunData.cache?.enabled,
    parentRunData.platform.toLowerCase(),
    organisationId
  );

  const parentInputValues =
    parentRunData?.dependencyInputValues?.[parentRunData!.custom_test_id!];

  const parsedInputKeys: InputKey[] =
    parentRunData.settings?.inputKeys?.map((inputKey) => {
      const parentInputValue = parentInputValues?.find(
        (inputValue) => inputValue.key === inputKey.key
      );

      return {
        ...inputKey,
        ...(parentInputValue != null && {
          value: parentInputValue!.value,
        }),
      };
    }) ?? [];

  const settings = {
    launchParams: parentRunData.settings?.launchParams ?? [],
    preRequests:
      parentRunData.settings?.preRequests?.map((preRequest) => ({
        ...preRequest,
        delayBeforeRequest: preRequest.delayBeforeRequest ?? 0,
      })) ?? [],
    inputKeys: parsedInputKeys,
  };

  const parentRun: RunExecutionData = {
    id: parentRunData.id,
    device,
    os,
    promptSteps: parentRunPromptSteps,
    testId: parentRunData.custom_test_id,
    cache: {
      enabled: allowCachedRun,
      data: cachedData,
    },
    settings,
    order: dependencies.length + 1,
    platform: parentRunData.platform,
    orientation: parentRunData.orientation,
    locale: parentRunData.locale,
    startedTimeInEpoch: parentRunData.startedTimeInEpoch,
    runData: parentRunData,
    dependencyInputValues:
      dependencies.length === 0
        ? []
        : parentRunData?.dependencyInputValues?.[
            dependencies[dependencies.length - 1].testId!
          ] ?? [],
  };

  return {
    runs: [...dependencies, parentRun],
  } as TestSuiteRunExecutionData;
};

const testRunRecordingDataAtom = atomFamily(
  ({ suiteId, runId }: { suiteId: string; runId: string }) => {
    return atomWithQuery((get) => {
      const testSuiteDataQuery = get(testSuiteDataAtom(suiteId));

      return {
        queryKey: ["getTestRunExecutionDataAtom", runId, suiteId],
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: testSuiteDataQuery.data != null,
        queryFn: async ({ queryKey: [, runId, suiteId] }) => {
          try {
            const runSnap = await getDoc(
              doc(
                customFirestore,
                "custom-test-suite-runs",
                suiteId as string,
                "runs",
                runId as string
              )
            );

            if (runSnap.exists()) {
              const parentRunData = {
                ...(runSnap.data() as RunData),
                id: runSnap.id,
              };
              const runExecutionData = await getRuns({
                parentRunData,
                suiteId: suiteId as string,
                runId: runId as string,
                organisationId: (testSuiteDataQuery.data as TestSuiteData)
                  .organisationId,
                forRecording: true,
              });

              const parentRun = runExecutionData.runs?.at(-1)!;

              let logs = [];
              if (parentRun.runData!.logsPath != null) {
                const downloadUrl = await getDownloadURL(
                  ref(customStorage, parentRun.runData!.logsPath)
                );

                const response = await axios.get(downloadUrl);
                logs = response.data;
              }

              let networkLogs = [];
              if (parentRun.runData!.networkLogsPath != null) {
                const downloadUrl = await getDownloadURL(
                  ref(customStorage, parentRun.runData!.networkLogsPath)
                );

                const response = await axios.get(downloadUrl);
                if (typeof response.data === "string") {
                  // Attempt to parse as JSON if data is a string
                  try {
                    networkLogs = JSON.parse(response.data);
                  } catch (error) {
                    console.error("Error parsing JSON from string:", error);
                    trackSentryException(error);
                  }
                } else {
                  // If axios already parsed it as JSON, use it directly
                  networkLogs = response.data;
                }
              }

              console.log("networkLogs", networkLogs);

              let interactionLogs: InteractionLog[] = [];
              if (parentRun.runData!.interactionLogsPath != null) {
                const downloadUrl = await getDownloadURL(
                  ref(customStorage, parentRun.runData!.interactionLogsPath)
                );

                const response = await axios.get(downloadUrl);
                interactionLogs = response.data as InteractionLog[];

                const imageUrls = await Promise.all(
                  interactionLogs.map(async (interactionLog) => {
                    if (interactionLog.screenshotUrl != null) {
                      return interactionLog.screenshotUrl;
                    }
                    return await getDownloadURL(
                      ref(customStorage, interactionLog.screenshot)
                    );
                  })
                );
                interactionLogs = interactionLogs.map(
                  (interactionLog, index) => ({
                    ...interactionLog,
                    screenshot: imageUrls[index],
                  })
                );
              }

              return {
                runs: runExecutionData.runs,
                recording: {
                  logs,
                  networkLogs,
                  interactionLogs,
                },
              } as TestSuiteRunRecordingData;
            } else {
              throw new Error("Test run doesn't exist");
            }
          } catch (e) {
            trackSentryException(e);
            const firebaseError = e as FirebaseError;
            if (firebaseError.code === "permission-denied") {
              location.reload();
            } else {
              throw e;
            }
          }
        },
      };
    });
  },
  (a, b) => a.suiteId === b.suiteId && a.runId === b.runId
);

const testRunExecutionDataAtom = atomFamily(
  ({ suiteId, runId }: { suiteId: string; runId: string }) => {
    return atomWithQuery((get) => {
      const testSuiteDataQuery = get(testSuiteDataAtom(suiteId));

      return {
        queryKey: ["getTestRunExecutionDataAtom", runId, suiteId],
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: testSuiteDataQuery.data != null,
        queryFn: async ({ queryKey: [, runId, suiteId] }) => {
          try {
            const runSnap = await getDoc(
              doc(
                customFirestore,
                "custom-test-suite-runs",
                suiteId as string,
                "runs",
                runId as string
              )
            );

            if (runSnap.exists()) {
              const parentRunData = {
                ...(runSnap.data() as RunData),
                id: runSnap.id,
              };
              return await getRuns({
                suiteId: suiteId as string,
                runId: runId as string,
                parentRunData,
                organisationId: (testSuiteDataQuery.data as TestSuiteData)
                  .organisationId,
                forRecording: false,
              });
            } else {
              throw new Error("Test run doesn't exist");
            }
          } catch (e) {
            trackSentryException(e);
            const firebaseError = e as FirebaseError;
            if (firebaseError.code === "permission-denied") {
              location.reload();
            } else {
              throw e;
            }
          }
        },
      };
    });
  },
  (a, b) => a.suiteId === b.suiteId && a.runId === b.runId
);

const companyDataAtom = atomFamily((organisationId?: string) => {
  return atomWithQuery((get) => {
    return {
      queryKey: ["getCompanyData", organisationId],
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      queryFn: async ({ queryKey: [, organisationId] }) => {
        const companyQuery = query(
          collection(customFirestore, "companies"),
          where("id", "==", organisationId!)
        );
        const companyDoc = (await getDocs(companyQuery)).docs.at(0)!;
        const companyData = companyDoc.data();

        const settingsRef = doc(
          customFirestore,
          "settings",
          organisationId as string
        );
        const settingsSnap = await getDoc(settingsRef);
        if (settingsSnap.exists()) {
          const settingsData = settingsSnap.data();

          const parsedCompanyData: CompanyData = {
            name: companyDoc.id,
            templates: companyData.templates ?? [],
            editorExecutionModel:
              companyData.model_version?.prompting ?? "v2-main",
            serverExecutionModel:
              companyData.model_version.execution ?? "v2-main",
            fallbackModel: companyData.model_version?.fallback ?? "v2-main",
            organisationId: organisationId as string,
            settings: {
              authRequired: settingsData.authRequired === "true",
              envVars: settingsData.envVars ?? [],
              tags: settingsData.tags ?? [],
              displayLogs: settingsData.displayLogs ?? true,
              displayNetworkLogs: !!settingsData.displayNetworkLogs,
              ...(settingsData.proxy?.length > 0 && {
                proxy: settingsData.proxy,
              }),
              displayNetworkLogsOnEditor:
                settingsData.proxy?.length > 0
                  ? false
                  : settingsData.displayNetworkLogsOnEditor ?? false,
              shouldResetActionHistory:
                settingsData.shouldResetActionHistory ?? true,
              disableVirtualKeyboard:
                settingsData.disableVirtualKeyboard ?? false,
              splitTextWhenTyping: settingsData.splitTextWhenTyping ?? true,
              numberOfRetriesOnFailure:
                settingsData.numberOfRetriesOnFailure ?? 0,
              utilizeFullTextAnnotation:
                settingsData.utilizeFullTextAnnotation ?? false,
              dynamicActionHistoryEnabled:
                settingsData.dynamicActionHistoryEnabled ?? false,
              enableSortingOCR: settingsData.enableSortingOCR ?? false,
              enableActionHistoryCut:
                settingsData.enableActionHistoryCut ?? false,
              enableCommandSuggestions:
                settingsData.enableCommandSuggestions ?? false,
              removeOverlappingText:
                settingsData.removeOverlappingText ?? false,
              abstractPromptGeneratorEnabled:
                settingsData.abstractPromptGeneratorEnabled ?? false,
              simplePromptAssistantEnabled:
                settingsData.simplePromptAssistantEnabled ?? false,
              popupDetectionEnabled:
                settingsData.popupDetectionEnabled ?? false,
              runHistoryEnabled: settingsData.runHistoryEnabled ?? true,
              sameStepPerformedLimit: settingsData.sameStepPerformedLimit ?? 0,
              sameScreenOccurredLimit:
                settingsData.sameScreenOccurredLimit ?? 0,
              shortenCommandStringEnabled:
                settingsData.shortenCommandStringEnabled ?? false,
              shortenCommandStringFromTopEnabled:
                settingsData.shortenCommandStringFromTopEnabled ?? false,
              numberOfCachedScreensCompared:
                settingsData.numberOfCachedScreensCompared ?? 0,
              waitForStableScreenTime:
                settingsData.waitForStableScreenTime ?? 3000,
              ocrProvider: settingsData.ocrProvider ?? "gcp",
              modelResponseDoubleCheckEnabled:
                settingsData.modelResponseDoubleCheckEnabled ?? false,
              testSettingsEnabled: settingsData.testSettingsEnabled ?? false,
              cachingEnabled: settingsData.cachingEnabled ?? false,
              enableFoldersFeature: settingsData.enableFoldersFeature ?? false,
              enableSessionsFeature:
                settingsData.enableSessionsFeature ?? false,
              defaultSessionConfig: {
                deviceAndroid: settingsData.deviceAndroid,
                osAndroid: settingsData.androidVersion,
                osIos: settingsData.iOSVersion,
                deviceIos: settingsData.deviceIOS,
                language: settingsData.language ?? "en_US",
                orientation: "portrait" as "portrait" | "landscape",
                region: settingsData.region ?? "automatic",
              },
              aggressiveCachingEnabled:
                settingsData.aggressiveCachingEnabled ?? false,
              enableAppetizeClone: settingsData.enableAppetizeClone ?? false,
              popupDetectionParameters:
                settingsData.popupDetectionParameters ?? {},
              templatesDetectionParameters:
                settingsData.templatesDetectionParameters ?? {},
              disabledButtonsDetectionEnabled:
                settingsData.disabledButtonsDetectionEnabled ?? false,
              disabledButtonsDetectionParameters:
                settingsData.disabledButtonsDetectionParameters ?? {},
              useAppetizeCommands: settingsData.useAppetizeCommands ?? true,
              autoGrantPermissions: settingsData.autoGrantPermissions ?? false,
            },
          };

          return parsedCompanyData;
        }
      },
      enabled: organisationId != null,
    };
  });
});

export {
  testRunExecutionDataAtom,
  testSuiteDataAtom,
  testRunRecordingDataAtom,
  companyDataAtom,
};
