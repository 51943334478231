import { type ToastOptions } from "react-toastify";
import {
  type AudioFrame,
  type CachedScreenData,
  type CompanyData,
  type EmailPayload,
  type EnvVar,
  type GetCachedScreenAPIResults,
  type ImageDimensions,
  type InputValue,
  NetworkLog,
  type PixelsToCrop,
  type PromptStep,
  type RunExecutionData,
  type TemplateWithLabel,
} from "./types";
import { getCompanyIdFromUpload } from "./jiraHelpers";
import { addDoc, collection, getDocs, query, where } from "@firebase/firestore";
import { customFirestore, customStorage } from "../firebase";
import * as Sentry from "@sentry/react";
import { DateTime } from "luxon";
import {
  BACKEND_SERVER_URL,
  FASTAPI_SERVER_URL,
  LAMBDA_AWS_TIMEOUT,
  LAMBDA_AWS_URL,
} from "../constants/aws-constants";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import axios, {
  AxiosInstance,
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";
import { type Node } from "prosemirror-model";
import { type Selection } from "prosemirror-state";
import * as amplitude from "@amplitude/analytics-browser";

import { getSchema } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  kAndroidOsName,
  kDefaultAppetizeOSVersions,
  kIosOsName,
} from "../constants/appetize-constants";
import UniqueID from "@tiptap-pro/extension-unique-id";
import { Timestamp } from "firebase/firestore";
import { ReactElement } from "react";

const generateFeedbackLink = (feedbackId: string): string =>
  `${window.location.protocol}//${window.location.host}/feedback-new/${feedbackId}`;

const b64toBlob = async (
  base64: string,
  type = "application/octet-stream"
): Promise<Blob> => await fetch(base64).then(async (res) => await res.blob());

const removeItemFromArray = (array: any[], index: number): any[] => {
  // If the item is not found, return the original array
  if (index === -1) {
    return array;
  }

  // Create a new array with the item removed
  const newArray = [...array.slice(0, index), ...array.slice(index + 1)];

  // Return the new array
  return newArray;
};

const replaceItemFomArray = (
  array: any[],
  index: number,
  value: any
): any[] => {
  return [...array.slice(0, index), value, ...array.slice(index + 1)];
};

const capitalizeFirstLetter = (word: string): string => {
  if (word.length === 0) {
    return word;
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const TOAST_OPTIONS: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

const delay = async (milliseconds: number): Promise<void> => {
  await new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const getImageDimensions = async (
  base64Image: string
): Promise<{ width: number; height: number }> => {
  return await new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const dimensions = { width: image.width, height: image.height };
      resolve(dimensions);
    };
    image.onerror = reject;
    image.src = base64Image; // Replace "jpeg" with the correct image format if necessary
  });
};

const flattenArray = (arr: string[][]): string[] => {
  return arr.reduce((acc, val) => acc.concat(val), []);
};

const getTemplatesForUploadId = async (uploadId: string): Promise<any> => {
  const companyId = await getCompanyIdFromUpload(uploadId);
  const companyQuery = query(
    collection(customFirestore, "companies"),
    where("id", "==", companyId)
  );
  const result = await getDocs(companyQuery);

  if (!result.empty) {
    const templates: TemplateWithLabel[] =
      result.docs[0].data()?.templates ?? [];
    return transformTemplates(templates);
  }

  return {};
};

const transformTemplates = (templates: TemplateWithLabel[]) => {
  return templates.reduce(
    (prev, template) => ({
      ...prev,
      [template.imageUrl]: template.label,
    }),
    {}
  );
};

async function handleEmailSending(email: string) {
  const payload: EmailPayload = {
    to: "md@kitchenful.com",
    text: `test message for mail ${email}`,
    subject: "testing",
    html: "none",
  };

  const response = await fetch("https://admin.flutterboost.com/api/send-mail", {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const responseJson = response.json();
  console.log("Handling email sending POST Response: ", responseJson);

  return await responseJson;
}

const trackAmplitudeEvent = ({
  eventType,
  eventProperties,
  groups,
  groupProperties,
}: {
  eventType: string;
  eventProperties?: Record<string, any> | undefined;
  groups?: Record<string, any> | undefined;
  groupProperties?: Record<string, any> | undefined;
}) => {
  if (import.meta.env.MODE === "production") {
    amplitude.track({
      event_type: eventType,
      event_properties: eventProperties,
      group_properties: groupProperties,
      groups,
    });
  }
};

const trackSentryException = (e: any) => {
  if (import.meta.env.MODE === "production") {
    Sentry.captureException(e);
  }
};

const trackExceptionOnFirestore = async ({
  error,
  source,
}: {
  error: any;
  source: string;
}) => {
  if (import.meta.env.MODE === "production") {
    try {
      await addDoc(collection(customFirestore, "errors"), {
        error: JSON.stringify(error),
        source,
        createdAt: Timestamp.now(),
      });
    } catch (e) {
      console.error(e);
      trackSentryException(e);
    }
  }
};

const trackSentryMessage = (message: any) => {
  if (import.meta.env.MODE === "production") {
    Sentry.captureMessage(message, "debug");
  }
};

function getDigitsAfterFirstSemicolon(input: string): string[] | null {
  const startIndex = input.indexOf(";") + 1; // find the first semicolon
  const substring = input.slice(startIndex); // get the substring starting after the first semicolon
  const matches = substring.match(/\d+/g); // return the array of matches
  return matches?.length === 2 ? matches : null;
}

const getDefaultSlideStartingCoordinates = (
  direction: string,
  img_width: number,
  img_height: number
) => {
  switch (direction) {
    case "left":
      return { startX: img_width * 1, startY: img_height / 2 };
    case "right":
      return { startX: img_width * 0, startY: img_height / 2 };
    case "up":
      return { startX: img_width / 2, startY: img_height * 0.6 };
    case "down":
      return { startX: img_width / 2, startY: img_height * 0.4 };
    default:
      return { startX: img_width / 2, startY: img_height / 2 };
  }
};

const getSlideAnnotationDetails = (command: string) => {
  let action, direction, percentage, startX, startY;
  const actionMatch = command.match(/(slide|swipe|scroll)/);
  if (actionMatch != null && actionMatch.length === 2) {
    action = actionMatch[1];
  }
  const directionMatch = command.match(/(left|right|up|down)/);
  if (directionMatch != null && directionMatch.length === 2) {
    direction = directionMatch[1];
    if (action == "scroll") {
      direction = invertDirection(direction);
    }
  } else {
    console.log("No direction found for command", command);
    return null;
  }

  const coordinatesAndPercentage = command.match(/(\d+)%.*;(\d+);(\d+);/);

  console.log("Slide annotation details", {
    direction,
    coordinatesAndPercentage,
  });

  if (
    coordinatesAndPercentage != null &&
    coordinatesAndPercentage.length === 4
  ) {
    percentage = Number(coordinatesAndPercentage[1]);
    startX = Number(coordinatesAndPercentage[2]);
    startY = Number(coordinatesAndPercentage[3]);
  } else {
    percentage = direction === "left" || direction === "right" ? 90 : 45;
  }

  return { direction, percentage, startX, startY };
};

const decodeScheduledTestTime = (
  day: number | string,
  time: number | string
) => {
  const dayIndex = Number(day);
  const timeIndex = Number(time);
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const hours = [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
  ];

  return `${days[dayIndex]}, ${hours[timeIndex]}`;
};

const invertDirection = (initialDirection: string) => {
  switch (initialDirection) {
    case "up":
      return "down";
    case "down":
      return "up";
    case "left":
      return "right";
    case "right":
      return "left";
    default:
      return "up";
  }
};

function arrayToCsv(data: any[]) {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}

function downloadBlob(content: any, filename: string, content_type: string) {
  // Create a blob
  const blob = new Blob([content], { type: content_type });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}

const hideNetworkLogsEnvVars = (logs: any[], envVars: EnvVar[]): any[] => {
  return logs.map((log: any) => {
    if (log.type === "request") {
      return {
        ...log,
        ...(log.request?.postData?.text != null && {
          request: {
            ...log.request,
            postData: {
              ...log.request.postData,
              text: hideEnvVarSecrets(log.request.postData.text, envVars),
            },
          },
        }),
      };
    } else if (log.type === "response") {
      return {
        ...log,
        ...(log.request?.postData?.text != null && {
          request: {
            ...log.request,
            postData: {
              ...log.request.postData,
              text: hideEnvVarSecrets(log.request.postData.text, envVars),
            },
          },
        }),
        ...(log.response?.content?.text != null && {
          response: {
            ...log.response,
            content: {
              ...log.response.content,
              text: hideEnvVarSecrets(log.response.content.text, envVars),
            },
          },
        }),
      };
    }

    return log;
  });
};

const hideEnvVarSecrets = (item: string, envVars: EnvVar[]) => {
  let changedString = item;

  if (envVars.length > 0) {
    for (const envVar of envVars) {
      if (envVar.isSecret === true) {
        changedString = changedString.replaceAll(
          `${envVar.value}`,
          `{{env.${envVar.key}}}`
        );
        if (changedString !== item) {
          console.log(" > Hiding env var", envVar);
          console.log(" > inside text: ", item);
        }
      }
    }
  }

  return changedString;
};

const replaceEnvVars = (
  item: string,
  envVars: EnvVar[],
  revealSecrets: boolean = false
) => {
  console.log(`Replacing ${item} with envVars`, envVars, revealSecrets);

  if (envVars.length > 0) {
    const passedKey = item.trim().split("env.")[1] ?? item.trim();
    if (passedKey) {
      const foundEnvVar = envVars.find((value) => value.key === passedKey);
      if (foundEnvVar === undefined) {
        throw new Error(`Env variable with key ${item} not found`);
      } else {
        if (revealSecrets || foundEnvVar.isSecret !== true) {
          return foundEnvVar.value;
        }
      }
    }
  }
};

const parseCommands = ({
  shortenCommandStringEnabled,
  shortenCommandStringFromTopEnabled,
  actionHistory,
  commands,
  promptSteps,
  envVars,
  runInputValues,
  currentTemplateTimestamp,
}: {
  shortenCommandStringEnabled: boolean;
  shortenCommandStringFromTopEnabled: boolean;
  actionHistory: string[];
  commands: string;
  promptSteps: PromptStep[];
  envVars: EnvVar[];
  runInputValues: InputValue[];
  currentTemplateTimestamp: any;
}) => {
  let parsedCommands = commands;
  let parsedPromptSteps = promptSteps;

  if (shortenCommandStringEnabled) {
    const lastStepNumber = actionHistory.reduce((maxStep, step) => {
      const stepMatch = step.match(/\[Step (\d+)\]/);
      if (stepMatch != null) {
        const stepNumber = parseInt(stepMatch[1], 10);
        return Math.max(maxStep, stepNumber);
      }
      return maxStep;
    }, 0);

    // optimize below lines
    const { shortenedCommands, shortenedPromptSteps } = shortenCommands(
      parsedCommands,
      parsedPromptSteps,
      lastStepNumber,
      false
    );
    [parsedCommands, parsedPromptSteps] = [
      shortenedCommands,
      shortenedPromptSteps,
    ];
  }

  if (shortenCommandStringFromTopEnabled) {
    const { shortenedCommands, shortenedPromptSteps } = shortenCommands(
      parsedCommands,
      parsedPromptSteps,
      0,
      true
    );
    [parsedCommands, parsedPromptSteps] = [
      shortenedCommands,
      shortenedPromptSteps,
    ];
  }

  parsedCommands = parsePrompt({
    prompt: parsedCommands,
    envVars,
    runInputValues,
    currentTemplateTimestamp,
  });

  parsedPromptSteps = parsedPromptSteps.map((step) => {
    return {
      ...step,
      text: parsePrompt({
        prompt: step.text,
        envVars,
        runInputValues,
        currentTemplateTimestamp,
      }),
      plainText: parsePrompt({
        prompt: step.plainText,
        envVars,
        runInputValues,
        currentTemplateTimestamp,
      }),
    };
  });

  console.log("Parsed prompts", { parsedCommands, parsedPromptSteps });

  return { parsedCommands, parsedPromptSteps };
};

const parsePrompt = ({
  prompt,
  envVars,
  runInputValues,
  currentTemplateTimestamp,
}: {
  prompt: string;
  envVars: EnvVar[];
  runInputValues: Array<{ key: string; value: string }>;
  currentTemplateTimestamp: any;
}) => {
  return prompt.replace(/{{(.*?)}}/g, (match, key) => {
    const parsedKey = key.trim();
    const replacedTemplateItem =
      replaceTemplateItem(parsedKey, envVars, runInputValues) || match;
    if (parsedKey === "timestamp") {
      if (currentTemplateTimestamp.current == null) {
        currentTemplateTimestamp.current = replacedTemplateItem;
      }
      return currentTemplateTimestamp.current;
    }
    return replacedTemplateItem;
  });
};

const replaceTemplateItem = (
  item: string,
  envVars: EnvVar[] = [],
  runInputValues: Array<{ key: string; value: string }> = []
) => {
  if (item === "currentDate") {
    return DateTime.now().toUTC().toFormat("y-MM-dd");
  }
  if (item === "currentDateFormatted") {
    return getFormattedDate(new Date());
  }
  if (item === "timestamp") {
    return `${Math.floor(Date.now() / 1000)}`;
  }
  const randomNumberMatches = item.match(/randomNumber\((-?\d+)\)/);
  if (randomNumberMatches != null) {
    const digits = parseInt(randomNumberMatches[1]);
    const randomNumber = generateRandomNumber(digits);
    return `${randomNumber}`;
  }
  if (item.startsWith("env.")) {
    return replaceEnvVars(item, envVars, false);
  }
  const runInputKeys = runInputValues.map((input) => input.key);
  if (runInputKeys.includes(item)) {
    return runInputValues.find((input) => input.key === item)?.value;
  }
};

const generateRandomNumber = (digits: number): number => {
  if (digits <= 0) {
    throw new Error("Number of digits must be greater than 0");
  }

  const min = Math.pow(10, digits - 1); // Smallest number with the specified digits
  const max = Math.pow(10, digits) - 1; // Largest number with the specified digits

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getStringSimilarity = (s1: string, s2: string) => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength == 0) {
    return 1.0;
  }
  const result =
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  return result;
};

function editDistance(s1, s2) {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i == 0) costs[j] = j;
      else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

const allStringsEqual = (array: string[]) => {
  const firstElement = array[0];
  return array
    .slice(1)
    .every(
      (elem) =>
        getStringSimilarity(elem.toLowerCase(), firstElement.toLowerCase()) >=
        0.95
    );
};

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

function getPropByString(obj: any, propString: string) {
  if (!propString) return obj;

  let prop;
  const props = propString.split(".");

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    const candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
}

const drawCircleOnCanvas = (
  ctx: CanvasRenderingContext2D,
  circleCenterX: number,
  circleCenterY: number,
  circleRadius: number
) => {
  // Create radial gradient
  const grd = ctx.createRadialGradient(
    circleCenterX,
    circleCenterY,
    0,
    circleCenterX,
    circleCenterY,
    circleRadius
  );
  grd.addColorStop(0, "rgba(255, 255, 0, 1)"); // Yellow color at the center
  grd.addColorStop(1, "rgba(255, 255, 0, 0)"); // Transparent at the edge

  // Set the fill style to the gradient
  ctx.fillStyle = grd;

  // Draw the circle at the specified location
  ctx.beginPath();
  ctx.arc(circleCenterX, circleCenterY, circleRadius, 0, 2 * Math.PI);
  ctx.fill();

  // Set the fill style to solid red
  ctx.fillStyle = "rgba(255, 0, 0, 1)"; // Red

  // Draw a small red circle in the middle of the yellow circle
  ctx.beginPath();
  ctx.arc(circleCenterX, circleCenterY, 6, 0, 2 * Math.PI);
  ctx.fill();
};

const drawLineOnCanvas = (
  ctx: CanvasRenderingContext2D,
  startX: number,
  startY: number,
  direction: "left" | "right" | "up" | "down",
  percentage: number
) => {
  const canvasWidth = ctx.canvas.width;
  const canvasHeight = ctx.canvas.height;

  let endX = startX;
  let endY = startY;

  switch (direction) {
    case "left":
      endX = startX - canvasWidth * percentage;
      break;
    case "right":
      endX = startX + canvasWidth * percentage;
      break;
    case "up":
      endY = startY - canvasHeight * percentage;
      break;
    case "down":
      endY = startY + canvasHeight * percentage;
      break;
  }

  // Create a linear gradient for the line
  const lineGradient = ctx.createLinearGradient(startX, startY, endX, endY);
  lineGradient.addColorStop(0, "rgba(255, 255, 0, 0)"); // Transparent color at the start
  lineGradient.addColorStop(1, "rgba(255, 255, 0, 1)"); // Yellow at the end

  // Set the line style
  ctx.strokeStyle = lineGradient;
  ctx.lineWidth = 8; // Line width of 4 pixels

  // Draw the line
  ctx.beginPath();
  ctx.moveTo(startX, startY);
  ctx.lineTo(endX, endY);
  ctx.stroke();

  return { endX, endY };
};

// Helper function to draw a circle
async function drawShapeAndReturnImage(
  base64Image: string,
  circle: { centerX: number; centerY: number; radius: number } | null = null,
  line: {
    startX: number;
    startY: number;
    direction: "left" | "right" | "up" | "down";
    percentage: number;
  } | null = null
): Promise<string> {
  // Create new canvas and context
  const canvas = document.createElement("canvas");
  const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

  // Create new image
  const img = new Image();
  img.crossOrigin = "*";
  // Convert base64 to Image
  img.src = base64Image;

  return await new Promise((resolve, reject) => {
    img.onload = function () {
      // Set the canvas to the same dimensions as the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      if (line != null) {
        if (line.startX === undefined || line.startY === undefined) {
          const { startX, startY } = getDefaultSlideStartingCoordinates(
            line.direction,
            canvas.width,
            canvas.height
          );
          line.startX = startX;
          line.startY = startY;
        }
        const { endX, endY } = drawLineOnCanvas(
          ctx,
          line.startX,
          line.startY,
          line.direction,
          line.percentage
        );
        drawCircleOnCanvas(ctx, endX, endY, 35);
      }
      if (circle != null) {
        drawCircleOnCanvas(ctx, circle.centerX, circle.centerY, circle.radius);
      }

      // Convert the canvas image to Base64
      const modifiedBase64Image =
        "data:image/png;base64," + canvas.toDataURL("image/png").split(",")[1];

      resolve(modifiedBase64Image);
    };

    img.onerror = reject;
  });
}

const annotateScreenshot = async (
  base64Input: string,
  commands: string[]
): Promise<string> => {
  // Annotate screenshot with tap / swipe indicator

  let image = base64Input;
  for (const command of commands.filter(
    (value) =>
      !value.includes("reasoning") &&
      !value.includes("task complete") &&
      !value.includes("error detected")
  )) {
    const isTappingCommand =
      command.includes("tabOn") ||
      command.includes("double tap") ||
      command.includes("long press") ||
      command.includes("tap at");
    const isSwipingCommand =
      command.includes("slide") ||
      command.includes("swipe") ||
      command.includes("scroll");

    try {
      if (isTappingCommand) {
        const coordsMatch = getDigitsAfterFirstSemicolon(command);
        if (coordsMatch != null) {
          const [centerX, centerY] = coordsMatch;
          const circle = {
            centerX: Number(centerX),
            centerY: Number(centerY),
            radius: 50,
          };
          image = await drawShapeAndReturnImage(image, circle, null);
        } else {
          console.log("No coordinates found for command", command);
        }
      } else if (isSwipingCommand) {
        const slideDetails = getSlideAnnotationDetails(command);
        if (slideDetails != null) {
          const { direction, percentage, startX, startY } = slideDetails;
          const line = {
            startX,
            startY,
            direction,
            percentage: percentage / 100,
          };
          image = await drawShapeAndReturnImage(image, null, line);
        } else {
          console.log("No coordinates found for command", command);
        }
      }
    } catch (error) {
      trackSentryException(error);
      console.log("Error while processing command", command);
      console.error(error);
    }
  }

  return image;
};

async function base64ToImageData(base64: string): Promise<ImageData> {
  return await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      resolve(ctx.getImageData(0, 0, img.width, img.height));
    };
    img.onerror = reject;
    img.src = base64;
    img.crossOrigin = "*";
  });
}

function groupArrayElements(arr, size) {
  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
}

const resizeImage = async (
  base64image: string,
  new_width: number,
  new_height: number
): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = new_width;
      canvas.height = new_height;

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(image, 0, 0, new_width, new_height);

      // convert the canvas back to a base64 string
      const croppedBase64Image =
        "data:image/jpg;base64," + canvas.toDataURL("image/jpg").split(",")[1];

      resolve(croppedBase64Image);
    };
    image.onerror = reject;
    image.src = base64image;
    image.crossOrigin = "*";
  });
};

async function compareImages(
  base64Image1: string,
  base64Image2: string,
  diffThreshold: number = 0
) {
  const { imageData1, imageData2, wereDifferent } = await loadAndEqualizeImages(
    base64Image1,
    base64Image2
  );
  const pixelsDiffThreshold = wereDifferent ? 128 : diffThreshold;

  const pixelValuesGroupedImage1 = groupArrayElements(imageData1.data, 4);
  const pixelValuesGroupedImage2 = groupArrayElements(imageData2.data, 4);

  let numberOfDifferingPixels = 0;
  for (let i = 0; i < pixelValuesGroupedImage1.length; i++) {
    const summedIn1 = pixelValuesGroupedImage1[i].reduce(
      (partialSum: number, a: number) => partialSum + a,
      0
    );
    const summedIn2 = pixelValuesGroupedImage2[i].reduce(
      (partialSum: number, a: number) => partialSum + a,
      0
    );
    const difference = Math.abs(summedIn1 - summedIn2);

    if (difference > pixelsDiffThreshold) {
      numberOfDifferingPixels++;
    }

    if (numberOfDifferingPixels > 200) {
      console.log("Breaking early due to too many differing pixels");
      break;
    }
  }
  return numberOfDifferingPixels;
}

const loadAndEqualizeImages = async (
  base64Image1: string,
  base64Image2: string
) => {
  let imageData1 = await base64ToImageData(base64Image1);
  let imageData2 = await base64ToImageData(base64Image2);
  let wereDifferent = false;

  if (
    imageData1.width !== imageData2.width ||
    imageData1.height !== imageData2.height
  ) {
    const message = `Images are not the same size! ${imageData1.width}x${imageData1.height} vs ${imageData2.width}x${imageData2.height}`;
    console.log(message);
    const newWidth = Math.max(imageData1.width, imageData2.width);
    const newHeight = Math.max(imageData1.height, imageData2.height);
    console.log("Resizing to dimensions", { newWidth, newHeight });
    const resizedImage1 = await resizeImage(base64Image1, newWidth, newHeight);
    const resizedImage2 = await resizeImage(base64Image2, newWidth, newHeight);
    imageData1 = await base64ToImageData(resizedImage1);
    imageData2 = await base64ToImageData(resizedImage2);
    wereDifferent = true;
  }

  return { imageData1, imageData2, wereDifferent };
};

async function loadAndCropImage(
  base64Image: string,
  topPixelsToCrop: number = 0,
  rightPixelsToCrop: number = 0,
  bottomPixelsToCrop: number = 0,
  leftPixelsToCrop: number = 0,
  contrast: number = 1
): Promise<string> {
  return await new Promise((resolve, reject) => {
    // create an image
    const img = new Image();
    img.onload = () => {
      // create a canvas and get the context
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // adjust the canvas size considering the cropping
      canvas.width = img.width - rightPixelsToCrop - leftPixelsToCrop;
      canvas.height = img.height - topPixelsToCrop - bottomPixelsToCrop;

      // draw the image to the canvas, cropping the top, right, bottom and left pixels
      ctx.drawImage(
        img,
        leftPixelsToCrop,
        topPixelsToCrop,
        img.width - rightPixelsToCrop - leftPixelsToCrop,
        img.height - topPixelsToCrop - bottomPixelsToCrop,
        0,
        0,
        img.width - rightPixelsToCrop - leftPixelsToCrop,
        img.height - topPixelsToCrop - bottomPixelsToCrop
      );

      // Get the image data from the canvas
      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imgData.data;

      // Loop through the image pixels and adjust the contrast
      for (let i = 0; i < data.length; i += 4) {
        for (let j = 0; j < 3; j++) {
          // Loop over R, G, B (and not A)
          // Adjust contrast and clamp the value between 0 and 255
          data[i + j] = Math.max(
            0,
            Math.min(255, (data[i + j] - 128) * contrast + 128)
          );
        }
      }

      // Put the adjusted image data back onto the canvas
      ctx.putImageData(imgData, 0, 0);

      // convert the canvas back to a base64 string
      const croppedBase64Image =
        "data:image/jpg;base64," + canvas.toDataURL("image/jpg").split(",")[1];

      resolve(croppedBase64Image);
    };
    img.onerror = reject;
    img.src = base64Image;
    img.crossOrigin = "*";
  });
}

const callEmailReaderAPI = async (
  email: string,
  linkPattern: string
): Promise<string> => {
  const requestObject = {
    recipient_mail: email,
    link_pattern: linkPattern,
  };
  console.log("Email reader request object: ", requestObject);

  const url =
    "https://l2et4uyql5bzn5gg65mextsdeq0kqqym.lambda-url.us-east-1.on.aws/";

  const response_start_time = new Date().getTime();
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObject),
  });

  if (!response.ok) {
    console.log("Incorrect response: ", response);
    // console.log("response json: ", await response.json());
    console.log("response text: ", await response.text());
    throw new Error(`HTTP error ${response.status}`);
  }

  const jsonResponse = await response.json();
  console.log("Email reader json response", jsonResponse);

  return jsonResponse.link;
};

const fetchPlus = (
  url: string,
  options: AxiosRequestConfig = {},
  retries: number,
  serverErrorMessages?: Record<number, string>
): any => {
  const axiosOptions: AxiosRequestConfig = {
    timeout: 30000,
    ...options,
    url,
  };

  return axios
    .request(axiosOptions)
    .then((response) => {
      // Check if the response status is OK (200 range)
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    })
    .catch((error) => {
      trackSentryException(error);
      if (retries > 0) {
        // Retry logic for network errors or timeouts
        return fetchPlus(url, options, retries - 1);
      } else if (error.response) {
        // If the error has a response (like a 404, 500, etc.), throw an error with that status
        throw new Error(
          serverErrorMessages?.[error.response.status] != null
            ? serverErrorMessages[error.response.status]
            : `HTTP error ${error.response.status}`
        );
      } else {
        // For any other errors (like network errors), throw a generic error
        throw new Error(`Network error ${error.code}`);
      }
    });
};

function wasAudioPlayingInTheLastThreeSeconds(audioEvents: AudioFrame[]) {
  if (audioEvents == null || audioEvents.length === 0) {
    return false;
  }

  const now = Date.now();
  const lastThreeSecondsAudioEvents = audioEvents.filter((audioEvent) => {
    const diff = now - audioEvent.absoluteTimestamp;
    return diff <= 3000;
  });

  return lastThreeSecondsAudioEvents.length > 0;
}

const enrichLogTextAPIWrapper = async (
  buildId: any,
  base64screenshot: string,
  log_text: string,
  lastUiElements: [],
  utilizeFullTextAnnotation: boolean,
  firstInteraction: boolean,
  simplePromptAssistantEnabled: boolean
): Promise<string> => {
  if (log_text.startsWith("type")) {
    return log_text;
  }
  let model_version = "enrich-logs";
  if (simplePromptAssistantEnabled && !firstInteraction) {
    model_version = "enrich-logs-checkless";
  }
  const screenshotDimensions = await getImageDimensions(base64screenshot);
  const requestObject = {
    base64_screenshot: base64screenshot.split(",")[1],
    getUI_elements: lastUiElements,
    image_width: screenshotDimensions.width,
    image_height: screenshotDimensions.height,
    log_text,
    template_images:
      buildId != null && (await getTemplatesForUploadId(buildId)),
    lambda_flow: "enrich_logs",
    enrich_instruction_start: firstInteraction ? "Wait until" : "Check that",
    utilize_fullTextAnnotation: utilizeFullTextAnnotation,
    model_version,
  };

  let jsonResponse;

  try {
    jsonResponse = await fetchPlus(
      LAMBDA_AWS_URL,
      {
        method: "POST",
        data: requestObject,
        timeout: 15000,
      },
      0
    );
  } catch (_) {
    jsonResponse = await fetchPlus(
      LAMBDA_AWS_URL,
      {
        method: "POST",
        data: { ...requestObject, model_version: `azure-${model_version}` },
        timeout: 15000,
      },
      0
    );
  }

  const matches = jsonResponse.enriched_text.match(
    /<prompt>([\s\S]*?)<\/prompt>/g
  );
  return matches[matches.length - 1]
    .replace("<prompt>", "")
    .replace("</prompt>", "")
    .trim();
};

const createSuccessPromptAPIWrapper = async (
  buildId: any,
  base64screenshot: string,
  lastUiElements: [],
  utilizeFullTextAnnotation: boolean
): Promise<string> => {
  const screenshotDimensions = await getImageDimensions(base64screenshot);
  const requestObject = {
    base64_screenshot: base64screenshot.split(",")[1],
    getUI_elements: lastUiElements,
    image_width: screenshotDimensions.width,
    image_height: screenshotDimensions.height,
    template_images:
      buildId != null && (await getTemplatesForUploadId(buildId)),
    lambda_flow: "create_success_prompt",
    utilize_fullTextAnnotation: utilizeFullTextAnnotation,
    model_version: "create-success-prompt",
  };

  // console.log("Request", requestObject)
  let jsonResponse;

  try {
    jsonResponse = await fetchPlus(
      LAMBDA_AWS_URL,
      {
        method: "POST",
        data: requestObject,
        timeout: 15000,
      },
      0
    );
  } catch (_) {
    jsonResponse = await fetchPlus(
      LAMBDA_AWS_URL,
      {
        method: "POST",
        data: {
          ...requestObject,
          model_version: "azure-create-success-prompt",
        },
        timeout: 15000,
      },
      0
    );
  }

  // console.log("jsonResponse", jsonResponse)

  const matches = jsonResponse.success_prompt.match(
    /<prompt>([\s\S]*?)<\/prompt>/g
  );
  return matches[matches.length - 1]
    .replace("<prompt>", "")
    .replace("</prompt>", "")
    .trim();
};

const parsePressedKeys = (pressedKeys: string[]) => {
  // TODO handle Backspaces
  const lastItem = pressedKeys.at(-1);
  let lastKeyHit = "";
  if (lastItem && ["Enter", "Tab"].includes(lastItem)) {
    lastKeyHit = ` and hit ${lastItem}`;
    pressedKeys.pop();
  }
  return pressedKeys.join("") + lastKeyHit;
};

function bufferToBase64(buffer) {
  let binary = "";

  if (buffer instanceof Uint8Array) {
    for (const byte of buffer) {
      binary += String.fromCharCode(byte);
    }
  } else {
    // Assuming object format like {0: 255, 1: 216, ...}
    for (const key in buffer) {
      if (buffer.hasOwnProperty(key)) {
        binary += String.fromCharCode(buffer[key]);
      }
    }
  }

  return btoa(binary);
}

function uint8ArrayToBase64(bytes: Uint8Array) {
  let binary = "";
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return `data:image/jpg;base64, ${window.btoa(binary)}`;
}

const generateEnrichedPrompts = (
  rawInteractions: string[],
  enrichedInteractions: string[]
): string[] => {
  const logContent =
    "Combined generated prompt:\n" +
    "---\n" +
    enrichedInteractions
      .map((value, index) => `${index + 1}. ${value}`)
      .join("\n") +
    "\n---\n\n" +
    "Individual action log\n" +
    "---\n" +
    rawInteractions
      .map(
        (value, index) =>
          `raw: ${value}\n` +
          `enriched: ${enrichedInteractions[index]}` +
          "\n---"
      )
      .reverse()
      .join("\n");

  // console.log(logContent)

  return enrichedInteractions.map((value, index) => value);
};

const getSlideDirectionAndPercentage = (xMove: number, yMove: number) => {
  let direction = "";
  if (Math.abs(xMove) > Math.abs(yMove)) {
    direction = xMove > 0 ? "right" : "left";
  } else {
    direction = yMove > 0 ? "down" : "up";
  }
  const percentage = Math.round(
    100 * Math.max(Math.abs(xMove), Math.abs(yMove))
  );
  return { direction, percentage };
};

const getSlideDirectionAndPercentageFromAssistant = ({
  startX,
  startY,
  endX,
  endY,
  rectWidth,
  rectHeight,
}: {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  rectWidth: number;
  rectHeight: number;
}): { direction: string; percentage: number } => {
  const deltaX = endX - startX;
  const deltaY = endY - startY;

  // Determine primary axis and calculate percentage
  let direction = "";
  let percentage = 0;

  if (Math.abs(deltaX) > Math.abs(deltaY)) {
    // Horizontal swipe
    direction = deltaX > 0 ? "right" : "left";
    percentage = Math.trunc(Math.abs(deltaX / rectWidth) * 100);
  } else {
    // Vertical swipe
    direction = deltaY > 0 ? "down" : "up";
    percentage = Math.trunc(Math.abs(deltaY / rectHeight) * 100);
  }

  return { direction, percentage };
};
const getPromptAssistantDeviceCoordinates = (
  coordX: number,
  coordY: number,
  device: any,
  imageDimensions: ImageDimensions
) => {
  const divider = device.screen.width / imageDimensions.width;

  const [tapX, tapY] = [Math.round(coordX), Math.round(coordY)];
  const [deviceX, deviceY] = [
    Math.round(tapX / divider),
    Math.round(tapY / divider),
  ];

  return { deviceX, deviceY };
};

const getCoordinatesForDevice = (
  device: any,
  imageDimensions: ImageDimensions,
  cmd: string
) => {
  const coordinates = getDigitsAfterFirstSemicolon(cmd);

  if (coordinates !== null) {
    // if specific divider is specified we take it. Otherwise, we look for a fallback-platform divider.
    const divider = imageDimensions.width / device.screen.width;

    const [xPos, yPos] = coordinates;
    let deviceX = Math.ceil(Number(xPos) / divider);
    let deviceY = Math.ceil(Number(yPos) / divider);

    deviceX = Math.min(device.screen.width - 1, Math.max(0, deviceX));
    deviceY = Math.min(device.screen.height - 1, Math.max(0, deviceY));

    console.log(coordinates, divider, [xPos, yPos], deviceX, deviceY);
    return { deviceX, deviceY };
  }

  return null;
};

const prepareGetUIElements = async (
  ui_elements: any,
  videoFrameBuffer: any
) => {
  const EditText_elements: any[] = [];
  let application_element: any = null;
  let homeCalloutContainerPresent = false;
  // console.log("ui elements", ui_elements)

  const traverse = (jsonObj: object | string | number) => {
    if (jsonObj !== null && typeof jsonObj === "object") {
      Object.entries(jsonObj).forEach(([key, value]) => {
        if (Boolean(value) && typeof value === "object") {
          if (
            Object.hasOwn(value, "class") &&
            value.class.includes("EditText")
          ) {
            EditText_elements.push(JSON.parse(JSON.stringify(jsonObj)));
          }
          if (
            application_element === null &&
            Object.hasOwn(value, "type") &&
            value.type === "application"
          ) {
            application_element = JSON.parse(JSON.stringify(jsonObj));
          }
          if (
            Object.hasOwn(value, "resource-id") &&
            Boolean(value["resource-id"].includes("homeCalloutContainer"))
          ) {
            homeCalloutContainerPresent = true;
          }

          // key is either an array index or object key
          traverse(value);
        }
      });
    }
  };

  traverse(ui_elements);

  // EditText_elements.length > 0 && console.log("EditText_elements", EditText_elements)
  // application_element && console.log("application_element", application_element)

  if (EditText_elements.length > 0 && application_element) {
    const getUI_width =
      application_element.bounds.x + application_element.bounds.width;
    const getUI_height =
      application_element.bounds.y + application_element.bounds.height;
    const screenshot = uint8ArrayToBase64(videoFrameBuffer);
    const { width, height } = await getImageDimensions(screenshot);

    if (getUI_width !== width || getUI_height !== height) {
      const multiplier = width / getUI_width;

      for (const editTextElement of EditText_elements) {
        editTextElement.bounds.x = Math.round(
          editTextElement.bounds.x * multiplier
        );
        editTextElement.bounds.y = Math.round(
          editTextElement.bounds.y * multiplier
        );
        editTextElement.bounds.width = Math.round(
          editTextElement.bounds.width * multiplier
        );
        editTextElement.bounds.height = Math.round(
          editTextElement.bounds.height * multiplier
        );
      }
    }
  }

  return { uiElements: EditText_elements, homeCalloutContainerPresent };
};

const uploadScreenshotToStorage = async (screenshot: string, url: string) => {
  console.log(`Uploading screenshot to path ${url}`);
  const screenshotRef = ref(customStorage, url);
  // `enrichedInteractionLogs/${testId}/screenshot-${currentIndex}-${index + 1}.jpg`
  const screenshotBlob = await b64toBlob(screenshot);
  await uploadBytes(screenshotRef, screenshotBlob);
  const downloadUrl = await getDownloadURL(
    ref(customStorage, screenshotRef.fullPath)
  );

  console.log(`Finished: ${downloadUrl}`);

  return downloadUrl;
};

const getBase64ImageResolution = async (
  base64: string
): Promise<{
  width: number;
  height: number;
}> => {
  return await new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
    image.onerror = (err) => {
      reject(`Failed to load the image: ${err}`);
    };
    image.src = base64;
  });
};

const executeCurlCommand = async (
  curlCommand: string,
  organisationId: string
): Promise<AxiosResponse<any> | AxiosError> => {
  try {
    console.log("> Executing cURL command: ", curlCommand);

    // Construct the full URL for the FastAPI server endpoint
    // Assuming the endpoint accepts POST requests with a JSON body containing the cURL command
    const fullUrl = `https://tester-eu.mobileboost.io/curl`;

    const response = await axios.post(
      fullUrl,
      { command: curlCommand, organisationId },
      { timeout: 60000 }
    );

    console.log("Response from FastAPI server:", response.data);
    return response;
  } catch (error) {
    trackSentryException(error);
    console.error("Error calling FastAPI server:", error);
    return error as AxiosError;
  }
};

const curlToAxios = async (
  curlCommand: string,
  withProxy: boolean = false
): Promise<AxiosResponse | AxiosError> => {
  // Extract the URL
  const urlMatches = curlCommand.match(/'?(http[s]?:\/\/[^'\s]+)'?/);
  console.log("urlMatches: ", urlMatches);
  if (urlMatches == null || urlMatches.length < 1) {
    throw new Error("Invalid curl command: Unable to extract URL");
  }
  let url = urlMatches[1]; // Note the change here
  url = url.replace('"', "").replace("'", ""); // Remove any quotes from the url
  // Extract headers
  const headers = {};
  const headerMatches = curlCommand.match(/-H '([^']+)'/g);
  if (headerMatches != null) {
    headerMatches.forEach((match) => {
      const headerContent = match.replace("-H '", "").replace("'", "");
      const headerParts = headerContent.split(":");
      if (headerParts.length === 2) {
        headers[headerParts[0].trim()] = headerParts[1].trim();
      }
    });
  }

  // Extract HTTP method
  let method = "GET"; // default to GET
  if (curlCommand.includes("-X POST")) {
    method = "POST";
  } else if (curlCommand.includes("-X DELETE")) {
    method = "DELETE";
  } // ... add other methods as needed

  // Extract data payload for POST requests
  const dataRegex = /(?:--data|-d)\s*(['"])(.*?)\1/;
  let data = null;
  if (method === "POST") {
    const dataMatch = curlCommand.match(dataRegex);
    if (dataMatch != null && dataMatch[2]) {
      data = dataMatch[2];
    }
  }

  if (withProxy) {
    url = `${FASTAPI_SERVER_URL}/cors-proxy?url=${encodeURIComponent(url)}`;
  }

  console.log("Pre request", method, url, headers, data);

  try {
    const response = await axios({
      method,
      url,
      headers,
      data,
    });
    console.log({ response });
    return response;
  } catch (error) {
    trackSentryException(error);
    console.error(error);
    return error as AxiosError;
  }
};

const getValueByPath = (
  obj: Record<string, any> | any[],
  path: string
): any => {
  const pathParts = path.split(".");
  let current: any = obj;
  for (const part of pathParts) {
    if (Array.isArray(current)) {
      const index = parseInt(part, 10);
      if (!isNaN(index)) {
        current = current[index];
      } else {
        return undefined;
      }
    } else if (current[part] === undefined) {
      return undefined;
    } else {
      current = current[part];
    }
  }
  return current;
};

interface NestedInfo {
  level: number;
  path?: string;
}

const getPromptStepsFromTipTapNode = ({
  node,
  selection,
}: {
  node: Node;
  selection?: Selection;
}) => {
  const plainText: PromptStep[] = [];

  const getTextParser = (
    node: any
  ): ((node: Node, nestedInfo: NestedInfo) => PromptStep[]) => {
    if (node.type?.name === "orderedList" || node.type === "orderedList") {
      return orderedListParser;
    } else if (node.type?.name === "bulletList" || node.type === "bulletList") {
      return bulletListParser;
    }
    return paragraphParser;
  };

  const paragraphParser = (node: any): PromptStep[] => {
    let text = "";
    node.content?.forEach((childNode: any) => {
      if (childNode.type?.name === "text" || childNode.type === "text") {
        text += childNode.text;
      } else if (
        childNode.type?.name === "hardBreak" ||
        childNode.type === "hardBreak"
      ) {
        text += "\n";
      }
    });

    return [
      {
        id: node.attrs?.id as string,
        text: text.length > 0 ? text : "",
        plainText: text.length > 0 ? text : "",
      },
    ];
  };

  const bulletListParser = (
    node: any,
    nestedInfo: NestedInfo
  ): PromptStep[] => {
    const parsedSteps: PromptStep[] = [];
    node.content.forEach((childNode: any) => {
      childNode.content.forEach((listItemChild: any) => {
        if (
          listItemChild.type.name === "paragraph" ||
          listItemChild.type === "paragraph"
        ) {
          const content =
            listItemChild.content?.content != null
              ? listItemChild.content.content
              : listItemChild.content;
          if (content == null) {
            return;
          }

          const parsedContent = content.reduce(
            (prev, current) => prev + current.text,
            ""
          );
          parsedSteps.push({
            id: listItemChild.attrs?.id,
            text: `    - ${parsedContent}`,
            plainText: parsedContent,
          });
        } else {
          const parser = getTextParser(listItemChild);
          parsedSteps.push(
            ...parser(listItemChild, { level: nestedInfo.level + 1 })
          );
        }
      });
    });
    return parsedSteps;
  };

  const getListItemNumber = (
    nestedInfo: NestedInfo,
    selectionLevel: number,
    node: any
  ) => {
    if (nestedInfo.level > 0) {
      return 1;
    }
    if (selectionLevel > 0) {
      return selectionLevel + 1;
    }
    return node.attrs?.start ?? 1;
  };

  const orderedListParser = (
    node: any,
    nestedInfo: NestedInfo
  ): PromptStep[] => {
    const parsedSteps: PromptStep[] = [];
    const selectionLevel: number = selection?.$from?.path[4] ?? 0;
    let listItemNumber = getListItemNumber(nestedInfo, selectionLevel, node);
    // console.log("node", node);

    node.content.forEach((listItemNode: any) => {
      listItemNode.content.forEach((listItemChild: any) => {
        if (
          listItemChild.type.name === "paragraph" ||
          listItemChild.type === "paragraph"
        ) {
          const content =
            listItemChild.content?.content != null
              ? listItemChild.content.content
              : listItemChild.content;
          if (content == null) {
            return;
          }
          const parsedContent = content.reduce(
            (prev, current) => prev + current.text,
            ""
          );

          parsedSteps.push({
            id: listItemChild.attrs?.id,
            text: `${
              nestedInfo.path != null ? `${nestedInfo.path}.` : ""
            }${listItemNumber++}. ${parsedContent}`,
            plainText: parsedContent,
          });
        } else {
          const parser = getTextParser(listItemChild);
          parsedSteps.push(
            ...parser(listItemChild, {
              level: nestedInfo.level + 1,
              path: `${nestedInfo.path != null ? `${nestedInfo.path}.` : ""}${
                listItemNumber - 1
              }`,
            })
          );
        }
      });
    });
    return parsedSteps;
  };

  const parseParentContent = (childNode: Node) => {
    const parser = getTextParser(childNode);
    plainText.push(...parser(childNode, { level: 0 }));
  };

  if (selection != null) {
    const cutNode = node.cut(selection.from, selection.to);
    cutNode.content.forEach(parseParentContent);
  } else {
    node.content.forEach(parseParentContent);
  }

  return plainText.length === 0 ? [] : plainText;
};

const getGptDriverInput = (json: string): PromptStep[] => {
  try {
    return getPromptStepsFromTipTapNode({
      node: JSON.parse(json),
    });
  } catch (e) {
    console.error("error parsing stored prosemirror data", e);
    trackSentryException(e);
    return json;
  }
};

const getPromptStepsText = (steps: PromptStep[]) =>
  steps.reduce((prev, value, index) => {
    if (value.text !== "" && value.text !== "\n") {
      return `${prev}${index != 0 ? "\n" : ""}${value.text}`;
    }
    return prev;
  }, "");

const createSimpleHtmlList = (items: string[]) => {
  // Start with the opening tag for the ordered list
  let htmlContent = `<meta charset="utf-8"><ol>`;

  // Loop through each item in the list
  for (const item of items) {
    // For each item, add a list item tag
    htmlContent += `<li>${item}</li>`;
  }

  // Close the ordered list tag
  htmlContent += `</ol>`;

  return htmlContent;
};

const timestampToDate = (timestamp: number) => {
  // TODO use me in run History component
  return DateTime.fromJSDate(new Date(timestamp * 1000)).toFormat(
    "dd-MM-yyyy hh:mm a"
  );
};

const getTipTapExtensions = () => [
  StarterKit,
  UniqueID.configure({
    types: ["paragraph"],
  }),
];

const getTipTapSchema = () => getSchema(getTipTapExtensions());

const parseGptCommandsResponse = (
  gptCommands: string[]
): {
  reasoning: string;
  commands: string;
  step_number: string;
  fromCache: boolean;
  step_description: string | undefined;
  actions_description: string | undefined;
  step_id: string | undefined;
  step_completed: boolean | undefined;
} => {
  let reasoning = "";
  let commands = "";
  let step_number: string = "";
  let fromCache = false;
  let step_description: string | undefined;
  let actions_description: string | undefined;
  let step_id: string | undefined;
  let step_completed: boolean | undefined;

  for (const gptCommand of gptCommands) {
    const gptCommandLower = gptCommand.toLowerCase();

    if (gptCommandLower.includes("reasoning")) {
      reasoning = gptCommand;
    } else if (gptCommandLower.includes("step_number")) {
      step_number = gptCommand.split(":")[1].trim();
    } else if (gptCommandLower.includes("step_description")) {
      step_description = gptCommand.split(":")[1].trim();
    } else if (gptCommandLower.includes("actions_description")) {
      actions_description = gptCommand.split(":")[1].trim();
    } else if (gptCommandLower.includes("step_id")) {
      step_id = gptCommand.split(":")[1].trim();
    } else if (gptCommandLower.includes("step_completed")) {
      step_completed = gptCommandLower.split(":")[1].trim() === "true";
    } else if (gptCommand === "Action taken from cache") {
      fromCache = true;
    } else {
      commands += "\n" + gptCommand;
    }
  }

  return {
    reasoning,
    commands: commands.trim(),
    step_number,
    step_description,
    actions_description,
    fromCache,
    step_id,
    step_completed,
  };
};

const getPromptText = (
  stepNumber: number,
  commandsText: string,
  step_description: string | undefined,
  step_id: string | undefined,
  promptSteps: PromptStep[]
) => {
  console.log("> Getting prompt text with parameters", {
    stepNumber,
    commandsText,
    step_description,
    step_id,
    promptSteps,
  });

  if (step_id && promptSteps.length > 0) {
    const foundStep = promptSteps.find((step) => step.id === step_id);
    if (foundStep != null) {
      return foundStep.text;
    }
  }

  if (step_description) {
    return step_description;
  }

  if (stepNumber > 0) {
    return commandsText.split("\n")[stepNumber - 1];
  }

  return undefined;
};

const imageUrlToBase64 = async (url: string) => {
  try {
    const response = await axios.get(url, {
      responseType: "blob",
    });

    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(response.data); // Convert blob to base64
      reader.onloadend = () => {
        resolve(reader.result); // Resolve the promise with the Base64 string
      };
      reader.onerror = reject; // Reject the promise on error
    });
  } catch (error) {
    trackSentryException(error);
    console.error("Error fetching and converting image: ", error);
    throw error; // Rethrow or handle error as needed
  }
};

function calculateTotalWeight(alternatives: string[], query: string) {
  let weight = 0;
  let matchFound = false;

  for (let i = 0; i < alternatives.length; i++) {
    if (alternatives[i].startsWith(query)) {
      matchFound = true;
    }

    // Higher weights for earlier alternatives
    weight = 100 / (i + 1);
    if (matchFound) {
      break;
    }
  }

  // If the query matches, add a large bonus weight
  if (matchFound) {
    weight += 1000;
  }

  return weight;
}

const isUnusableCachedScreen = (cachedData: CachedScreenData) => {
  return (
    cachedData.gptCommand.includes("test+") ||
    (cachedData.gptCommand.includes("type") &&
      cachedData.gptCommand.includes("@example.com")) ||
    cachedData.prompt?.includes("{{") ||
    cachedData.reasoning?.includes("remember")
  );
};

const prepareCachedFlow = async (
  promptSteps: PromptStep[],
  cachedSteps: CachedScreenData[],
  cachingEnabled: boolean | undefined,
  platform: string = "android",
  organizationId: string,
  isPartialExecution: boolean = false
) => {
  if (cachingEnabled === undefined || !cachingEnabled) {
    console.log("> Caching disabled for this run");
    return {
      allowCachedRun: false,
      cachedData: [],
    };
  }

  const filteredPromptSteps = promptSteps
    .map((promptStep) => promptStep.text.replaceAll("\n", ""))
    .filter((text) => text !== "");

  let filteredCachedSteps: CachedScreenData[] = cachedSteps.filter(
    (value) => !value.appetizeCommand.includes("task complete:")
  );
  const wrongIndices = [];

  for (let i = 0; i < filteredCachedSteps.length; i++) {
    const cachedStep = filteredCachedSteps[i];
    cachedStep.baseIndex = i;
    let found = false;

    if (isUnusableCachedScreen(cachedStep) !== true) {
      // Try to find matching step only for usable cached screens
      for (const promptText of filteredPromptSteps) {
        if (cachedStep.prompt?.trim() === promptText.trim()) {
          found = true;
          break;
        }
      }
    }

    if (!found) {
      wrongIndices.push(i);
    }
  }

  if (wrongIndices.length > 0) {
    const startTime = new Date().getTime();
    filteredCachedSteps = await callFilteredCachedScreensAPI(
      wrongIndices,
      filteredCachedSteps,
      platform,
      organizationId,
      isPartialExecution
    );
    console.log("Filtering took: ", (new Date().getTime() - startTime) / 1000);
  }

  console.log({ promptSteps, cachedSteps, filteredCachedSteps });
  return {
    allowCachedRun: cachingEnabled && filteredCachedSteps.length > 0,
    cachedData: filteredCachedSteps,
  };
};

const callFilteredCachedScreensAPI = async (
  wrongIndices: number[],
  cachedSteps: CachedScreenData[],
  platform: string,
  organizationId: string,
  isPartialExecution: boolean
) => {
  console.log("Calling filteredCachedScreens API with data: ", {
    wrongIndices,
    cachedSteps,
    platform,
    isPartialExecution,
    organizationId,
  });

  const FILTER_CACHED_SCREENS_URL = `${FASTAPI_SERVER_URL}/filterCachedScreens`;

  const requestObject = {
    wrongIndices,
    cachedSteps,
    isPartialExecution,
    organizationId,
    pixelsToCrop: {
      top: platform === "android" ? 60 : 80,
      bottom: 30,
      left: 5,
      right: 5,
    },
  };

  try {
    const jsonResponse = await fetchPlus(
      FILTER_CACHED_SCREENS_URL,
      {
        method: "POST",
        data: requestObject,
        timeout: 60000,
      },
      1
    );

    return jsonResponse as CachedScreenData[];
  } catch (error) {
    trackSentryException(error);
    console.error("Error calling filteredCachedScreens API:", error);
    return cachedSteps.filter((_, index) => !wrongIndices.includes(index));
  }
};

const callDeleteUploadAPI = async (uploadId: string) => {
  console.log("Calling deleteUpload API with uploadId: ", uploadId);

  const DELETE_UPLOAD_URL = `${FASTAPI_SERVER_URL}/${uploadId}`;

  return await fetchPlus(
    DELETE_UPLOAD_URL,
    {
      method: "DELETE",
      timeout: 15000,
    },
    1
  );
};

const callGetCachedScreenAPI = async (
  currentScreenshot: string,
  currentlyUsedCachedScreens: CachedScreenData[],
  pixelsToCrop: PixelsToCrop,
  organizationId: string,
  aggressiveCachingEnabled: boolean
) => {
  console.log("Calling getCachedScreen API with data: ", {
    currentScreenshot,
    currentlyUsedCachedScreens,
    pixelsToCrop,
    organizationId,
    aggressiveCachingEnabled,
  });

  const GET_CACHED_SCREENSHOT_URL = `${FASTAPI_SERVER_URL}/getCachedScreenshot`;

  const requestObject = {
    currentScreenshot,
    currentlyUsedCachedScreens: currentlyUsedCachedScreens.map(
      (value) => value.screenshotUrl
    ),
    currentAppetizeCommands: currentlyUsedCachedScreens.map(
      (value) => value.appetizeCommand
    ),
    pixelsToCrop,
    organizationId,
    aggressiveCachingEnabled,
  };

  try {
    const jsonResponse = await fetchPlus(
      GET_CACHED_SCREENSHOT_URL,
      {
        method: "POST",
        data: requestObject,
        timeout: 15000,
      },
      1
    );

    return jsonResponse as GetCachedScreenAPIResults[];
  } catch (error) {
    trackSentryException(error);
    console.error("Error calling getCachedScreen API:", error);
    return [];
  }
};

const callCancelSuiteAPI = async (
  cancelSingleRun: boolean,
  testSuiteId: string | undefined,
  runId: string | undefined = undefined
) => {
  console.log("Calling cancelSuiteAPI with data: ", {
    testSuiteId,
    runId,
    cancelSingleRun,
  });

  const endpoint = cancelSingleRun ? "cancelSingleRun" : "cancelSuite";
  const CANCEL_ENDPOINT_URL = `${BACKEND_SERVER_URL}/${endpoint}`;

  const requestObject = {
    testSuiteId,
    runId,
  };

  try {
    await fetchPlus(
      CANCEL_ENDPOINT_URL,
      {
        method: "POST",
        data: requestObject,
        timeout: 30000,
      },
      1
    );
  } catch (error) {
    trackSentryException(error);
    console.error("Error calling callCancelSuiteAPI API:", error);
    throw error;
  }
};

async function deleteFirestoreFolder(
  path: string,
  fileNameStartsWith?: string
) {
  const folderRef = ref(customStorage, path);
  const fileList = await listAll(folderRef);
  const promises = fileList.items
    .filter((item) => {
      if (fileNameStartsWith == null) {
        return true;
      }
      return item.name.startsWith(fileNameStartsWith);
    })
    .map(async (item) => {
      await deleteObject(item);
    });

  return await Promise.all(promises);
}

const getFallbackModel = (current_model_version: string) => {
  if (current_model_version === "azure-alias-testing") {
    return "reasoning-structured-testing";
  } else if (current_model_version === "reasoning-structured-testing") {
    return "azure-alias-testing";
  } else if (current_model_version === "claude3-opus-test") {
    return "gemini-1.5-pro-test";
  }

  let fallbackModel: string;

  if (current_model_version.includes("azure")) {
    if (current_model_version.includes("vision")) {
      fallbackModel = "gpt_vision";
    } else {
      fallbackModel = "gpt-tags";
    }
  } else {
    if (current_model_version.includes("vision")) {
      fallbackModel = "azure_gpt_vision";
    } else {
      fallbackModel = "azure-gpt-tags";
    }
  }

  return fallbackModel;
};

const callGetAbstractPromptAPI = async (text: string) => {
  console.log("Calling getAbstractPrompt API with text: ", text);

  const requestObject = {
    model_version: "azure-generate-abstract-prompt",
    lambda_flow: "generate_abstract_prompt",
    selectedText: text,
  };

  const jsonResponse = await fetchPlus(
    LAMBDA_AWS_URL,
    {
      method: "POST",
      data: requestObject,
      timeout: 15000,
    },
    1
  );

  return jsonResponse.abstractPrompt;
};

const retryOperation = async <T>(
  operation: (executedTimes: number) => Promise<T> | T,
  delay: number,
  executeTimes: number,
  executedTimes?: number
): Promise<T> => {
  try {
    return await operation(executedTimes ?? 0);
  } catch (ex) {
    trackSentryException(ex);
    if (ex?.cancelRetry) {
      throw ex;
    } else {
      if (executeTimes > 0) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        return await retryOperation(
          operation,
          delay,
          executeTimes - 1,
          (executedTimes ?? 0) + 1
        );
      } else {
        throw ex;
      }
    }
  }
};

const getInputValues = ({
  currentRun,
  nextRunToExecute,
}: {
  currentRun: RunExecutionData;
  nextRunToExecute?: RunExecutionData;
}): InputValue[] =>
  currentRun.settings.inputKeys?.map<InputValue>((inputKey) => {
    const inputValue = nextRunToExecute?.dependencyInputValues?.find(
      (dependencyInputValue) => dependencyInputValue.key === inputKey.key
    );

    return {
      key: inputKey.key,
      value:
        inputValue?.value != null && inputValue?.value?.trim() != ""
          ? inputValue?.value
          : inputKey.value ?? inputKey.defaultValue ?? "",
    };
  }) ?? [];

const shortenCommands = (
  command: string,
  promptSteps: PromptStep[],
  lastStepNumber: number,
  fromTop: boolean = false
) => {
  const lines = command.split("\n");
  const startIndex = lines.findIndex((line) =>
    line.startsWith(`${lastStepNumber}.`)
  );

  const CUTTING_FROM_TOP_LIMIT = 6;

  if (fromTop) {
    return {
      shortenedCommands: lines.slice(0, CUTTING_FROM_TOP_LIMIT).join("\n"),
      shortenedPromptSteps: promptSteps.slice(0, CUTTING_FROM_TOP_LIMIT),
    };
  }

  if (startIndex !== -1) {
    return {
      shortenedCommands: lines.slice(startIndex).join("\n"),
      shortenedPromptSteps: promptSteps.slice(startIndex),
    };
  }

  return { shortenedCommands: command, shortenedPromptSteps: promptSteps };
};

const getDevice = (uploadType: "android" | "ios", device?: string): string => {
  if (uploadType === kIosOsName.toLowerCase()) {
    if (
      device != null &&
      Object.keys(kDefaultAppetizeOSVersions.ios).includes(device)
    ) {
      return device;
    }
    return "iphone15pro";
  }

  if (uploadType === kAndroidOsName.toLowerCase()) {
    if (
      device != null &&
      Object.keys(kDefaultAppetizeOSVersions.android).includes(device)
    ) {
      return device;
    }
    return "pixel7";
  }

  return getDevice("ios");
};

const getOs = (
  uploadType: "android" | "ios",
  device: string,
  os?: string
): string => {
  if (uploadType === kIosOsName.toLowerCase()) {
    if (os != null && kDefaultAppetizeOSVersions.ios[device].includes(os)) {
      return os;
    }
    return kDefaultAppetizeOSVersions.ios[device].at(-1) ?? "17.2";
  }

  if (uploadType === kAndroidOsName.toLowerCase()) {
    if (os != null && kDefaultAppetizeOSVersions.android[device].includes(os)) {
      return os;
    }
    return kDefaultAppetizeOSVersions.android[device].at(-1) ?? "13.0";
  }
  return getOs("ios", device);
};

const getExecutionData = async (requestObject: any) => {
  let response;

  try {
    response = await fetchPlus(
      LAMBDA_AWS_URL,
      {
        method: "POST",
        data: requestObject,
        timeout: LAMBDA_AWS_TIMEOUT,
      },
      0
    );
  } catch (e) {
    trackSentryException(e);
    const current_model_version = requestObject.model_version;
    // If initial model was OpenAI, fallback to Azure. If initial one was Azure, fallback to OpenAI model
    const fallbackModel: string =
      requestObject?.fallbackModel ?? getFallbackModel(current_model_version);

    console.log(
      `Falling back from model ${requestObject.model_version} to ${fallbackModel}`
    );
    requestObject.model_version = fallbackModel;

    response = await fetchPlus(
      LAMBDA_AWS_URL,
      {
        method: "POST",
        data: requestObject,
        timeout: LAMBDA_AWS_TIMEOUT,
      },
      1,
      {
        500: "Sorry, something went wrong during execution. Please try again.",
      }
    );
  }
  return response;
};

const calculateMinutes = (x: number): number => {
  const secondsPerX = 20; // Define how many seconds one x represents
  const totalSeconds = x * secondsPerX; // Calculate total seconds for given x
  const minutes = Math.round(totalSeconds / 60); // Convert total seconds to minutes

  return minutes > 0 ? minutes : 1; // Round the result to the nearest whole number
};

const capitalize = (s: string) => {
  return (s !== "" && s[0].toUpperCase() + s.slice(1)) || "";
};

const findDuolingoRequest = (logs: NetworkLog[]) => {
  const responsesReversed = logs
    .reverse()
    .filter((log: any) => log.type === "response");

  const foundStory = responsesReversed.find((response) =>
    response.request.url.includes("https://stories.duolingo.com/api2/stories/")
  );

  const foundTest = responsesReversed.find((response) =>
    response.request.url.includes(
      "https://android-api-cf.duolingo.com/2017-06-30/sessions"
    )
  );

  if (foundStory != null) {
    const content = JSON.parse(foundStory.response.content.text);
    const challenges = content?.elements ?? [];

    return { challenges, type: "story" };
  } else if (foundTest != null) {
    const content = JSON.parse(foundTest.response.content.text);
    const challenges = content?.challenges ?? [];

    return { challenges, type: "test" };
  }
  return undefined;
};

const extractDuolingoAnswerForTest = (challenge: any) => {
  const type = challenge.type as string;

  switch (type) {
    case "select":
      return {
        prompt: challenge.metadata.phrase,
        correct_answer: [challenge.choices[challenge.correctIndex]["hint"]],
        type,
        title: "Select the correct image",
      };
    case "translate":
      return {
        prompt: challenge.prompt,
        correct_answer: challenge.correctTokens,
        type,
        title: "Translate this sentence",
      };
    case "listenTap":
      return {
        prompt: challenge.prompt,
        correct_answer: (challenge.solutionTranslation as string)
          .replace(".", "")
          .split(" "),
        type,
        title: "Translate this sentence",
      };
    case "tapComplete":
      return {
        prompt: (
          challenge.displayTokens.filter(
            (token: any) => !Boolean(token.isBlank)
          ) as string[]
        )
          .map((token: any) => token.text)
          .join(""),
        correct_answer: (challenge.correctIndices as number[]).map(
          (index) => challenge.choices[index].text
        ),
        type,
        title: "Complete the sentence",
      };
    case "assist":
      return {
        prompt: challenge.prompt,
        correct_answer: challenge.choices[challenge.correctIndex],
        type,
        title: "Select the correct translation",
      };
    case "match":
      return {
        prompt: "Tap the matching pairs",
        correct_answer: Object.entries(
          challenge.metadata.pairs.map((pair: any) => [
            pair.learning_word,
            pair.translation,
          ])
        ).flat(),
        type,
        title: "Tap the matching pairs",
      };
    default:
      console.log(`Unknown Duolingo challenge type: ${type}`);
      return undefined;
  }
};

const extractDuolingoAnswerForStory = (challenge: any) => {
  const type = challenge.type as string;

  switch (type) {
    case "SELECT_PHRASE":
      return {
        prompt: "Select the missing phrase",
        correct_answer: challenge.answers[challenge.correctAnswerIndex],
        type,
      };
    case "POINT_TO_PHRASE":
      // eslint-disable-next-line no-case-declarations
      const selectableAnswers = challenge.transcriptParts
        .filter((answer: any) => answer.selectable === true)
        .map((answer: any) => answer.text);

      return {
        prompt: challenge.question.text,
        correct_answer: selectableAnswers[challenge.correctAnswerIndex],
        type,
      };
    case "MULTIPLE_CHOICE":
      return {
        prompt: challenge?.question?.text ?? "What comes next?",
        correct_answer: challenge.answers[challenge.correctAnswerIndex].text,
        type,
      };
    case "ARRANGE":
      // eslint-disable-next-line no-case-declarations
      const correctIndices: number[] = challenge.phraseOrder;
      return {
        prompt: "Tap what you hear",
        correct_answer: correctIndices.map(
          (index: number) => challenge.selectablePhrases[index]
        ),
        type,
      };
    case "MATCH":
      return {
        prompt: "Tap the pairs",
        correct_answer: challenge.matches.map((pair: any) => [
          pair.translation,
          pair.phrase,
        ]),
        type,
      };
    default:
      console.log(`Unknown Duolingo challenge type: ${type}`);
      return undefined;
  }
};

const prepareDuolingoAnswerSheet = (
  challengesList: any[],
  quizType: string
) => {
  console.log("Preparing answer sheet for challenges list", { challengesList });
  const extractAnswer = (challenge: any) => {
    try {
      if (quizType === "test") {
        return extractDuolingoAnswerForTest(challenge);
      }
      if (quizType === "story") {
        return extractDuolingoAnswerForStory(challenge);
      }
    } catch (e) {
      console.error("Error extracting answer from challenge", e);
      return undefined;
    }
  };

  const challenges = challengesList.map((challenge: any) =>
    extractAnswer(challenge)
  );

  return challenges.filter((challenge: any) => challenge !== undefined);
};

const getOrdinalSuffix = (day: number) => {
  if (day > 3 && day < 21) return "th"; // Covers 11th to 19th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const getFormattedDate = (date: Date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  const ordinalSuffix = getOrdinalSuffix(day);

  return `${day}${ordinalSuffix} ${month} ${year}`;
};

function findElementAtCoordinatesIOS(xmlDoc, x, y): Element {
  const stack = Array.from(xmlDoc.documentElement.children); // Start with all children of the root element
  let bestMatch = null;

  while (stack.length > 0) {
    const node = stack.pop();

    // Check visibility; assume true if not explicitly false
    const isVisible = node.getAttribute("visible") !== "false"; // Treat null or missing as visible

    if (!isVisible) continue; // Skip elements that are explicitly marked as not visible

    const xAttr = parseFloat(node.getAttribute("x"));
    const yAttr = parseFloat(node.getAttribute("y"));
    const widthAttr = parseFloat(node.getAttribute("width"));
    const heightAttr = parseFloat(node.getAttribute("height"));

    if (
      !isNaN(xAttr) &&
      !isNaN(yAttr) &&
      !isNaN(widthAttr) &&
      !isNaN(heightAttr)
    ) {
      if (
        x >= xAttr &&
        x <= xAttr + widthAttr &&
        y >= yAttr &&
        y <= yAttr + heightAttr
      ) {
        // Update the bestMatch to this node if it's more specific (smaller bounding box)
        if (
          bestMatch === null ||
          widthAttr * heightAttr <
            parseFloat(bestMatch.getAttribute("width")) *
              parseFloat(bestMatch.getAttribute("height"))
        ) {
          bestMatch = node;
        }
      }
    }

    // Push children onto the stack to check if any are a better match (deeper in hierarchy)
    const children = Array.from(node.children);
    for (let child of children) {
      stack.push(child);
    }
  }

  return bestMatch; // Return the most specific (smallest) element that matches the coordinates
}

function parseBounds(boundsStr) {
  const match = boundsStr.match(/\[(\d+),(\d+)\]\[(\d+),(\d+)\]/);
  if (match) {
    return {
      left: parseInt(match[1], 10),
      top: parseInt(match[2], 10),
      right: parseInt(match[3], 10),
      bottom: parseInt(match[4], 10),
    };
  }
  return null;
}

function findElementAtCoordinatesAndroid(xmlDoc, x, y): Element {
  const stack = Array.from(xmlDoc.documentElement.children); // Start with all children of the root element
  let bestMatch = null;

  while (stack.length > 0) {
    const node = stack.pop();

    const boundsStr = node.getAttribute("bounds");
    const bounds = parseBounds(boundsStr);

    if (bounds) {
      if (
        x >= bounds.left &&
        x <= bounds.right &&
        y >= bounds.top &&
        y <= bounds.bottom
      ) {
        // Update the bestMatch to this node if it's more specific (smaller bounding box)
        const area =
          (bounds.right - bounds.left) * (bounds.bottom - bounds.top);
        if (
          bestMatch === null ||
          area <
            (bestMatch.right - bestMatch.left) *
              (bestMatch.bottom - bestMatch.top)
        ) {
          bestMatch = {
            node: node,
            left: bounds.left,
            top: bounds.top,
            right: bounds.right,
            bottom: bounds.bottom,
          };
        }
      }
    }

    // Push children onto the stack to check if any are a better match (deeper in hierarchy)
    const children = Array.from(node.children);
    for (let child of children) {
      stack.push(child);
    }
  }

  return bestMatch ? bestMatch.node : null; // Return the most specific (smallest) element that matches the coordinates
}

const generateSwipeOrScrollAppiumCode = ({
  startX,
  startY,
  endX,
  endY,
  duration,
  deviceHeight,
  deviceWidth,
}: {
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  duration: number;
  deviceHeight: number;
  deviceWidth: number;
}): string => {
  let naturalLanguagePrompt;

  const { direction, percentage } = getSlideDirectionAndPercentageFromAssistant(
    {
      startX,
      startY,
      endX,
      endY,
      rectHeight: deviceHeight,
      rectWidth: deviceWidth,
    }
  );
  const directionInverted = invertDirection(direction);

  if (direction == "up" || direction == "down") {
    naturalLanguagePrompt = `scroll ${directionInverted}`;
  } else {
    if (
      startY < 0.2 * deviceHeight ||
      startY > 0.8 * deviceHeight ||
      percentage < 20
    ) {
      naturalLanguagePrompt = `slide ${direction} ${percentage}% ; at x=${startX} y=${startY}`;
    } else {
      naturalLanguagePrompt = `swipe ${direction}`;
    }
  }

  const generatedCode = `const actions = [
       {
         type: 'pointer',
         id: 'finger1',
         parameters: { pointerType: 'touch' },
         actions: [
          { type: 'pointerMove', duration: 0, x: ${startX}, y: ${startY} },
          { type: 'pointerDown', button: 0 },
          { type: 'pointerMove', duration: ${duration}, x: ${endX}, y: ${endY} },
          { type: 'pointerUp', button: 0 },
         ],
       },
      ];

      await driver.performActions(actions);
      console.log('Swipe ${direction} by ${percentage.toFixed(2)}%');`;

  return `await gptDriver.execute("${naturalLanguagePrompt}",
   async (driver: WebdriverIO.Browser) => {
      ${generatedCode}
   });
`;
};

function generateTapAppiumCodeForAndroid(clickedElement): string {
  let naturalLanguagePrompt;
  let generatedCode;

  // Check for accessibility id first
  const accessibilityId = clickedElement.getAttribute("content-desc");
  if (accessibilityId) {
    generatedCode = `const element = await driver.$('~${accessibilityId}');
      await element.click();`;
    naturalLanguagePrompt = `tap on element with accessibility id ${accessibilityId}`;
  } else {
    // Generate XPath using essential attributes
    const resourceId = clickedElement.getAttribute("resource-id");
    const text = clickedElement.getAttribute("text");
    const className = clickedElement.getAttribute("class");

    let conditions = [];

    if (resourceId) conditions.push(`@resource-id='${resourceId}'`);
    if (text) conditions.push(`@text='${text}'`);
    if (className) conditions.push(`@class='${className}'`);

    // Ensure there are conditions before using xpath
    if (conditions.length > 0) {
      const xpath = `//*[${conditions.join(" and ")}]`;
      generatedCode = `const element = await driver.$("${xpath}");
      await element.click();`;
      naturalLanguagePrompt =
        text != null ? `tap on ${text}` : `tap on element with XPath ${xpath}`;
    } else {
      console.error("No valid attributes found to generate XPath.");
      return; // Exit if no valid attributes are available
    }
  }

  return `await gptDriver.execute("${naturalLanguagePrompt}",
   async (driver: WebdriverIO.Browser) => {
      ${generatedCode}
   });
`;
}

function parseXml(xmlStr) {
  return new window.DOMParser().parseFromString(xmlStr, "text/xml");
}

function generateTapAppiumCodeForiOS(clickedElement): string {
  let naturalLanguagePrompt;
  let generatedCode;

  // Generate a more specific XPath using the correct attributes
  const type = clickedElement.getAttribute("type");
  const label = clickedElement.getAttribute("label");
  const name = clickedElement.getAttribute("name");

  let xpath;

  // Check for parent uniqueness to narrow down the element selection
  if (type && label && name) {
    xpath = `//${type}[@name='${name}' and @label='${label}']`;
  } else {
    console.error("Not enough attributes to generate a specific XPath.");
    return; // Exit if not enough attributes are available
  }

  generatedCode = `const element = await driver.$("${xpath}");
      await element.click();`;
  naturalLanguagePrompt =
    name != null ? `tap on ${name}` : `tap on element with XPath ${xpath}`;

  return `await gptDriver.execute("${naturalLanguagePrompt}",
   async (driver: WebdriverIO.Browser) => {
      ${generatedCode}
   });
`;
}

/**
 * Determines if the polling should retry on the encountered error.
 *
 * @param error - The error encountered during the polling request.
 * @returns `true` if the error should trigger a retry, `false` otherwise.
 */
function shouldRetry(error: any): boolean {
  // Retry on 5xx server errors. Adjust the condition based on your needs.
  return error.response && error.response.status >= 500;
}

/**
 * Polls a given URL until a specified condition is met.
 *
 * @param {string} url The URL to poll.
 * @param {function} condition A function that takes the response and decides whether to stop polling.
 * @param {number} interval The interval in seconds between poll attempts.
 * @param {number} maxAttempts The maximum number of attempts to make.
 */
async function poll<T>(
  url: string,
  condition: (response: AxiosResponse<T>) => boolean,
  interval: number,
  maxAttempts: number,
  axiosInstance: AxiosInstance | undefined
): Promise<AxiosResponse<T>> {
  let attempts = 0;

  const executePoll = async (
    resolve: (value: AxiosResponse<T>) => void,
    reject: (reason?: any) => void
  ) => {
    try {
      const response = await (axiosInstance ?? axios).get<T>(url);
      attempts++;

      if (condition(response)) {
        resolve(response);
      } else if (attempts >= maxAttempts) {
        reject(
          new Error(
            "Maximum attempts reached without fulfilling the condition."
          )
        );
      } else {
        setTimeout(() => executePoll(resolve, reject), interval * 1000);
      }
    } catch (error) {
      console.log("error", error);
      if (shouldRetry(error)) {
        if (attempts >= maxAttempts) {
          reject(new Error("Maximum attempts reached with server errors."));
        } else {
          console.log("Retrying due to server error...");
          setTimeout(() => executePoll(resolve, reject), interval * 1000);
        }
      } else {
        console.log("Error encountered during polling:", error);
        reject(error);
      }
    }
  };

  return new Promise(executePoll);
}

const determineModelVersion = (
  repeatedStepOccurred: boolean,
  useVisionModel: boolean,
  fromEditor: boolean,
  companyData: CompanyData
) => {
  if (repeatedStepOccurred) {
    return "explain-repeated-actions";
  } else if (useVisionModel) {
    return "vision-gpt4-o";
  }

  return fromEditor
    ? companyData.editorExecutionModel
    : companyData.serverExecutionModel;
};

const isAppetizeCommand = (command: string): boolean => {
  const appetizeCommands = [
    "tapOn:",
    "tapOn.id:",
    "type:",
    "launchApp:",
    "openLink:",
    "restartApp",
    "scrollUpUntilVisible.text:",
    "scrollUpUntilVisible.id:",
    "scrollDownUntilVisible.text:",
    "scrollDownUntilVisible.id:",
    "waitUntilVisible.text:",
    "waitUntilVisible.id:",
    "swipe:",
    "scroll:",
    "slide:",
    "assertVisible.text:",
    "assertVisible.id:",
    "pressBackButton",
    "wait:",
    "assertVisible.id:",
    "removeText:",
  ];

  return appetizeCommands.some((appetizeCommand) =>
    command.startsWith(appetizeCommand)
  );
};

type JsonNode = {
  attributes?: { "resource-id"?: string; text?: string };
  children?: JsonNode[];
  bounds: { x: number; y: number; width: number; height: number };
  [key: string]: any;
};

type SearchConfig = {
  targetId?: string;
  text?: string;
};

function findNodeByConfig(
  data: JsonNode | JsonNode[] | undefined,
  config: SearchConfig
): JsonNode | undefined {
  // Return undefined if data is invalid or no search criteria is provided
  if (!data || (!config.targetId && !config.text)) {
    return undefined;
  }

  // If data is an array, recursively check each item in the array
  if (Array.isArray(data)) {
    for (const item of data) {
      const result = findNodeByConfig(item, config);
      if (result) {
        return result;
      }
    }
    return undefined;
  }

  // If data has an "attributes" field, check for a match based on the config
  if (data.attributes) {
    const matchesTargetId =
      config.targetId && data.attributes["resource-id"] === config.targetId;
    const matchesText = config.text && data.attributes.text === config.text;

    if (matchesTargetId || matchesText) {
      return data;
    }
  }

  // Recursively check each property in the object
  for (const key in data) {
    if (key === "children" && Array.isArray(data.children)) {
      // If the key is "children", recursively check each child node
      const result = findNodeByConfig(data.children, config);
      if (result) {
        return result;
      }
    } else if (typeof data[key] === "object") {
      // Recursively check any other nested object
      const result = findNodeByConfig(data[key], config);
      if (result) {
        return result;
      }
    }
  }

  return undefined;
}

function resourceIdExists(
  data: JsonNode | JsonNode[],
  targetId: string
): boolean {
  // Return false if data or targetId is undefined
  if (!data || !targetId) {
    return false;
  }

  // If data is an array, recursively check each item in the array
  if (Array.isArray(data)) {
    return data.some((item) => resourceIdExists(item, targetId));
  }

  // If data has an "attributes" field with "resource-id", check if it matches targetId
  if (data.attributes && data.attributes["resource-id"] === targetId) {
    return true;
  }

  // Recursively check each property in the object
  for (const key in data) {
    if (key === "children" && Array.isArray(data.children)) {
      // If the key is "children", recursively check each child node
      if (resourceIdExists(data.children, targetId)) {
        return true;
      }
    } else if (typeof data[key] === "object") {
      // Recursively check any other nested object
      if (resourceIdExists(data[key], targetId)) {
        return true;
      }
    }
  }

  return false;
}

const parseLocalAppetizeCommand = (promptStep: PromptStep | undefined) => {
  let parsedCommand = promptStep?.plainText?.replace("tapOn", "tabOn");

  if (
    parsedCommand?.startsWith("assertVisible.id:") ||
    parsedCommand?.startsWith("scrollUpUntilVisible.id:") ||
    parsedCommand?.startsWith("scrollDownUntilVisible.id:") ||
    parsedCommand?.startsWith("scrollUpUntilVisible.text:") ||
    parsedCommand?.startsWith("scrollDownUntilVisible.text:")
  ) {
    parsedCommand = parsedCommand + " Result=true";
  }

  return parsedCommand;
};

export {
  parseLocalAppetizeCommand,
  generateFeedbackLink,
  b64toBlob,
  removeItemFromArray,
  replaceItemFomArray,
  capitalizeFirstLetter,
  TOAST_OPTIONS,
  delay,
  flattenArray,
  getImageDimensions,
  getTemplatesForUploadId,
  handleEmailSending,
  trackAmplitudeEvent,
  trackSentryException,
  trackSentryMessage,
  decodeScheduledTestTime,
  arrayToCsv,
  downloadBlob,
  invertDirection,
  replaceTemplateItem,
  allStringsEqual,
  getErrorMessage,
  getPropByString,
  annotateScreenshot,
  callEmailReaderAPI,
  getDigitsAfterFirstSemicolon,
  fetchPlus,
  loadAndCropImage,
  compareImages,
  wasAudioPlayingInTheLastThreeSeconds,
  enrichLogTextAPIWrapper,
  parsePressedKeys,
  bufferToBase64,
  uint8ArrayToBase64,
  getCoordinatesForDevice,
  generateEnrichedPrompts,
  getSlideDirectionAndPercentage,
  getPromptAssistantDeviceCoordinates,
  createSuccessPromptAPIWrapper,
  prepareGetUIElements,
  uploadScreenshotToStorage,
  getBase64ImageResolution,
  curlToAxios,
  executeCurlCommand,
  getValueByPath,
  getPromptStepsFromTipTapNode,
  createSimpleHtmlList,
  getGptDriverInput,
  timestampToDate,
  replaceEnvVars,
  hideEnvVarSecrets,
  parseGptCommandsResponse,
  imageUrlToBase64,
  getTipTapSchema,
  getTipTapExtensions,
  calculateTotalWeight,
  prepareCachedFlow,
  deleteFirestoreFolder,
  getFallbackModel,
  callGetAbstractPromptAPI,
  transformTemplates,
  retryOperation,
  getInputValues,
  shortenCommands,
  getDevice,
  getOs,
  getExecutionData,
  calculateMinutes,
  getPromptStepsText,
  parseCommands,
  parsePrompt,
  trackExceptionOnFirestore,
  callGetCachedScreenAPI,
  capitalize,
  getPromptText,
  callDeleteUploadAPI,
  findDuolingoRequest,
  prepareDuolingoAnswerSheet,
  isUnusableCachedScreen,
  callCancelSuiteAPI,
  getFormattedDate,
  findElementAtCoordinatesAndroid,
  findElementAtCoordinatesIOS,
  parseXml,
  generateTapAppiumCodeForiOS,
  generateTapAppiumCodeForAndroid,
  getSlideDirectionAndPercentageFromAssistant,
  generateSwipeOrScrollAppiumCode,
  determineModelVersion,
  poll,
  isAppetizeCommand,
  hideNetworkLogsEnvVars,
  resourceIdExists,
  findNodeByConfig,
};
