import axios, { AxiosResponse } from "axios";
import {
  AppetizeCloneSessionInfo,
  AppetizeCloneTestDeviceConfiguration,
  ParsedAppetizeCloneTestDeviceConfiguration,
} from "./types";
import { atomWithMutation } from "jotai-tanstack-query";
import { delay, poll, retryOperation } from "./helpers";
import Fuse from "fuse.js";

const kServerUrl = "https://device-hub.mobileboost.io";

const axiosAuth = axios.create({
  auth: {
    username: "mobileboost",
    password: "mobileboost-pass",
  },
});

interface Device {
  screen: { width: number; height: number };
  platform: string;
}

interface AppetizeCloneSession {
  stop: () => Promise<void>;
  commands: {
    tap: ({
      coordinates,
      duration,
    }: {
      coordinates: { x: number; y: number };
      duration: number;
    }) => Promise<void>;
    tapOnElement: ({
      text,
      id,
      duration,
    }: {
      text?: string;
      id?: string;
      duration: number;
    }) => Promise<void>;
    type: ({ text }: { text: string }) => Promise<void>;
    keyStroke: ({ keycode }: { keycode: number }) => Promise<void>;
    openDeepLinkUrl: (url: string) => Promise<void>;
    swipe: ({
      startCoordinates,
      endCoordinates,
      duration,
    }: {
      startCoordinates: { x: number; y: number };
      endCoordinates: { x: number; y: number };
      duration?: number;
    }) => Promise<void>;
    tapHomeScreen: () => Promise<void>;
  };
  getScreenshot: () => Promise<string>;
  device: Device;
  startedAt: number;
  info: AppetizeCloneSessionInfo;
  serverUrl: string;
}

interface CreateSessionMutationAtomParams {
  platform: string;
  device: string;
  os: string;
  buildId: string;
  bundleId: string;
  storagePath: string;
  language?: string;
  countryCode?: string;
  proxy?: string;
}

const sessionMutationAtom = atomWithMutation((get) => ({
  mutationKey: ["createSessionMutation"],
  mutationFn: createAppetizeCloneSession,
}));

const createAppetizeCloneSession = async ({
  platform,
  device,
  os,
  buildId,
  bundleId,
  storagePath,
  language,
  countryCode,
  proxy,
}: CreateSessionMutationAtomParams) => {
  let pc = new RTCPeerConnection({
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
    ],
  });
  pc.ontrack = function (event) {
    console.log("Track event: ", event);

    event.streams.forEach((stream) => {
      stream.getTracks().forEach((track) => {
        console.log("Track kind: ", track.kind);

        if (track.kind === "video") {
          var videoElement = document.createElement("video");
          videoElement.srcObject = stream;
          videoElement.autoplay = true;
          videoElement.controls = false;
          videoElement.playsInline = true;
          videoElement.preload = "none";
          videoElement.playbackRate = 1.1;

          document.getElementById("deviceWrapper")?.appendChild(videoElement);

          videoElement.addEventListener("loadedmetadata", () => {
            console.log("Video metadata loaded");
            console.log(
              "Video dimensions: ",
              videoElement.videoWidth,
              "x",
              videoElement.videoHeight
            );
            videoElement.style.width = videoElement.videoWidth + "px";
            videoElement.style.height = videoElement.videoHeight + "px";
          });

          videoElement.addEventListener("error", (e) => {
            console.error("Video element error: ", e);
          });
        }
      });
    });
  };

  pc.addTransceiver("video", { direction: "recvonly" });

  const localDescription = await pc.createOffer();
  // console.log("localDescription", localDescription);
  await pc.setLocalDescription(localDescription);
  const base64LocalDescription = btoa(JSON.stringify(localDescription));
  // console.log("base64LocalDescription", base64LocalDescription);

  let sessionResponse;

  await retryOperation(
    async (_) => {
      try {
        const deviceConfigResponse = await axiosAuth.get(
          `${kServerUrl}/devices`
        );

        const unparsedDeviceConfigs: AppetizeCloneTestDeviceConfiguration[] =
          deviceConfigResponse.data;

        const deviceConfig = getDeviceConfig({
          platform,
          os,
          device,
          availableDeviceConfigs: unparsedDeviceConfigs,
        });

        sessionResponse = await axiosAuth.post(`${kServerUrl}/session`, {
          device_name: deviceConfig.device,
          osVersion: deviceConfig.os,
          build_id: buildId,
          bundle_id: bundleId,
          language,
          locale: countryCode,
          save_recording: true,
          proxy,
        });
      } catch (e: any) {
        if (e?.response?.data?.detail === "NON_EXISTENT_BUILD") {
          await axiosAuth.post(`${kServerUrl}/uploadAppBuild`, {
            download_url:
              `https://storage.googleapis.com/flutter-feedback.appspot.com/` +
              storagePath,
            build_id: buildId,
          });
          //TODO confirm delay value with Alan
          await delay(20000);
        } else if (
          e?.response?.data?.detail === "INVALID_DEVICE_CONFIGURATION"
        ) {
          throw { ...e, cancelRetry: true };
        }
        throw e;
      }
    },
    10000,
    3
  );

  const session = sessionResponse!.data;
  const sessionId = session.session_id;

  try {
    await delay(3000);

    const sessionInfo = await poll<AppetizeCloneSessionInfo>(
      `${kServerUrl}/session/${sessionId}`,
      (response) => {
        return response.data.state === "assigned";
      },
      15,
      10,
      axiosAuth
    );

    const serverDescriptionResponse = await axiosAuth.post(
      `${kServerUrl}/sdp/`,
      {
        session_id: sessionId,
        sdp: base64LocalDescription,
      }
    );
    const serverDescription = serverDescriptionResponse.data.sdp;
    console.log("serverDescription", serverDescription);
    await pc.setRemoteDescription(
      new RTCSessionDescription(JSON.parse(atob(serverDescription)))
    );

    const deviceDimensionsResponse: AxiosResponse = await retryOperation(
      (_) =>
        axiosAuth.get(`${kServerUrl}/interaction/get_dimensions/${sessionId}`),
      5000,
      10
    );

    let deviceDimensions: { width: number; height: number } =
      deviceDimensionsResponse.data;

    return {
      serverUrl: kServerUrl,
      info: sessionInfo.data,
      startedAt: new Date().getTime(),
      device: {
        screen: deviceDimensions,
        platform,
      },
      stop: async () => {
        await axiosAuth.post(`${kServerUrl}/stopSession`, {
          session_id: sessionId,
        });
      },
      getScreenshot: async () => {
        const screenshotResponse = await axiosAuth.get(
          `${kServerUrl}/interaction/screen_shot/${sessionId}`
        );
        return screenshotResponse.data;
      },
      commands: {
        openDeepLinkUrl: async (url: string) => {
          await axiosAuth.post(
            `${kServerUrl}/interaction/open_link/${sessionId}`,
            {
              url,
            }
          );
        },
        tap: async ({
          coordinates,
          duration,
        }: {
          coordinates: { x: number; y: number };
          duration: number;
        }) => {
          await axiosAuth.post(`${kServerUrl}/interaction/tap/${sessionId}`, {
            position: [coordinates.x, coordinates.y],
            duration,
          });
        },
        tapOnElement: async ({
          text,
          id,
          duration,
        }: {
          text?: string;
          id?: string;
          duration: number;
        }) => {
          const response = await axiosAuth.post(
            `${kServerUrl}/interaction/interaction/tap_element/${sessionId}`,
            {
              text,
              id,
              duration,
            }
          );
          if (
            response?.data != null &&
            response.data.message === "ELEMENT_NOT_FOUND"
          ) {
            const identifier = text ?? id ?? "unknown";
            throw new Error(`Element [${identifier}] not found!`);
          }
        },
        doubleTap: async ({
          coordinates,
          duration,
          count = 1,
        }: {
          coordinates: { x: number; y: number };
          duration: number;
          count: number;
        }) => {
          await axiosAuth.post(
            `${kServerUrl}/interaction/double_tap/${sessionId}`,
            {
              position: [coordinates.x, coordinates.y],
              duration,
              count,
            }
          );
        },
        longPress: async ({
          coordinates,
          duration,
          count = 1,
        }: {
          coordinates: { x: number; y: number };
          duration: number;
          count: number;
        }) => {
          await axiosAuth.post(
            `${kServerUrl}/interaction/long_press/${sessionId}`,
            {
              position: [coordinates.x, coordinates.y],
              duration,
              count,
            }
          );
        },
        swipe: async ({
          startCoordinates,
          endCoordinates,
          duration,
        }: {
          startCoordinates: { x: number; y: number };
          endCoordinates: { x: number; y: number };
          duration?: number;
        }) => {
          await axiosAuth.post(`${kServerUrl}/interaction/swipe/${sessionId}`, {
            start: [
              Math.floor(startCoordinates.x),
              Math.floor(startCoordinates.y),
            ],
            end: [Math.floor(endCoordinates.x), Math.floor(endCoordinates.y)],
            ...(duration != null && { duration }),
          });
        },
        type: async ({ text }: { text: string }) => {
          await axiosAuth.post(`${kServerUrl}/interaction/text/${sessionId}`, {
            text,
          });
        },
        keyStroke: async ({ keycode }: { keycode: number }) => {
          await axiosAuth.post(
            `${kServerUrl}/interaction/keystroke/${sessionId}`,
            {
              keycode,
            }
          );
        },
        tapHomeScreen: async () => {
          await axiosAuth.get(
            `${kServerUrl}/interaction/home_button/${sessionId}`
          );
        },
      },
    } as AppetizeCloneSession;
  } catch (e) {
    console.log("e", e);
    await axiosAuth.post(`${kServerUrl}/stopSession`, {
      session_id: sessionId,
    });
    throw e;
  }
};

const getDeviceConfig = ({
  platform,
  os,
  device,
  availableDeviceConfigs,
}: {
  platform: string;
  os: string;
  device: string;
  availableDeviceConfigs: AppetizeCloneTestDeviceConfiguration[];
}): ParsedAppetizeCloneTestDeviceConfiguration => {
  const parsedData = availableDeviceConfigs
    .map((item) => {
      return item.osVersions.map((os) => ({
        id: item.id,
        platform: item.platform,
        os,
        device: item.name,
      }));
    })
    .flat()
    .sort((a, b) => a.id.localeCompare(b.id));
  console.log("parsedData", parsedData);

  const fuseOptions = {
    // isCaseSensitive: false,
    includeScore: true,
    // shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.9,
    // distance: 100,
    // useExtendedSearch: false,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: ["os", "id"],
  };

  const fuse = new Fuse(parsedData, fuseOptions);
  const searchPattern = `${os} ${device}`;
  console.log("searchPattern", searchPattern);
  const result = fuse.search(searchPattern);
  console.log("result", result);
  return result[0].item;
};

// const eventToScaledCoordinates = (event) => {
//   // console.log(event)
//   const imageScale = 1; // we downsized the original by factor of two (1 = 100%, 2 = 50%, 4 = 25%)
//   // const [ originalWidth, originalHeight ] = [ event.target.naturalWidth || event.target.videoWidth, event.target.naturalHeight || event.target.videoHeight ];
//   const originalHeight = event.target.height;
//   const originalWidth = event.target.width;
//   const rect = event.target.getBoundingClientRect();
//   const scaleX = originalWidth / rect.width; // relationship bitmap vs. element for X
//   const scaleY = originalHeight / rect.height; // relationship bitmap vs. element for Y
//
//   const x = Math.round((event.clientX - rect.left) * scaleX); // scale mouse coordinates after they have
//   const y = Math.round((event.clientY - rect.top) * scaleY); // been adjusted to be relative to element
//
//   return [x, y, originalWidth, originalHeight].map((n) => n * imageScale);
// };

// function addEventListeners(element) {
//   element.addEventListener("mousedown", function (event) {
//     const [clickX, clickY, originalWidth, originalHeight] =
//       eventToScaledCoordinates(event);
//
//     console.log("mousedown!", {
//       clickX,
//       clickY,
//       originalWidth,
//       originalHeight,
//     });
//
//     // sendChannel.send(
//     //   JSON.stringify({
//     //     command: "MOUSEDOWN",
//     //     data: {
//     //       x: clickX,
//     //       y: clickY,
//     //       w: originalWidth,
//     //       h: originalHeight,
//     //     },
//     //   })
//     // );
//
//     // isDuringTap = true;
//
//     event.preventDefault();
//   });
//
//   element.addEventListener("mouseup", function (event) {
//     const [clickX, clickY, originalWidth, originalHeight] =
//       eventToScaledCoordinates(event);
//
//     console.log("mouseup!", {
//       clickX,
//       clickY,
//       originalWidth,
//       originalHeight,
//     });
//
//     // sendChannel.send(
//     //   JSON.stringify({
//     //     command: "MOUSEUP",
//     //     data: {
//     //       x: clickX,
//     //       y: clickY,
//     //       w: originalWidth,
//     //       h: originalHeight,
//     //     },
//     //   })
//     // );
//
//     // isDuringTap = false;
//
//     event.preventDefault();
//   });
//
//   element.addEventListener("mousemove", function (event) {
//     const [clickX, clickY, originalWidth, originalHeight] =
//       eventToScaledCoordinates(event);
//
//     // if (new Date() - lastMoveTime < 200) {
//     //   return; // do not spam mouse position more than 15 fps
//     // }
//     //
//     // if (!isDuringTap) {
//     //   return; // we do not care about events unless we have the finger down
//     // }
//
//     console.log("mousemove!", {
//       // isDuringTap,
//       clickX,
//       clickY,
//       originalWidth,
//       originalHeight,
//       event,
//     });
//
//     // sendChannel.send(
//     //   JSON.stringify({
//     //     command: "MOUSEMOVE",
//     //     data: {
//     //       x: clickX,
//     //       y: clickY,
//     //       w: originalWidth,
//     //       h: originalHeight,
//     //     },
//     //   })
//     // );
//
//     // lastMoveTime = +new Date();
//
//     event.preventDefault();
//   });
// }

export { sessionMutationAtom, type AppetizeCloneSession, type Device };
