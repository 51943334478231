import { atomFamily } from "jotai/utils";
import { atomWithQuery } from "jotai-tanstack-query";
import { type TestTemplate } from "../utils/types";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { customFirestore } from "../firebase";

const dependenciesForTestAtom = atomFamily(
  ({ organizationId, testId }: { organizationId: string; testId?: string }) => {
    return atomWithQuery((get) => ({
      queryKey: ["getDependenciesForTest", organizationId, testId],
      refetchOnWindowFocus: false,
      queryFn: async ({ queryKey: [, id] }) => {
        const testsRef = collection(customFirestore, "custom-tests");

        const q = query(
          testsRef,
          where("organisationId", "==", organizationId),
          orderBy("title", "asc")
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          return [];
        }
        const testWithDependencies = await Promise.all(
          querySnapshot.docs
            .sort((a, b) =>
              a
                .data()
                .title?.toLowerCase()
                .localeCompare(b.data().title?.toLowerCase())
            )
            .map(async (test) => {
              const testData = test.data() as TestTemplate;
              testData.id = test.id;

              // Access sub-collection and add to test data
              const subCollectionRef = collection(test.ref, "dependencies");
              const subCollectionSnapshot = await getDocs(subCollectionRef);

              testData.dependencies = subCollectionSnapshot.empty
                ? []
                : subCollectionSnapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                  }));

              return testData;
            })
        );
        if (testId == null) {
          return testWithDependencies;
        }
        return filteredTests(testWithDependencies, testId);
      },
    }));
  },
  (a, b) => a.organizationId === b.organizationId && a.testId === b.testId
);

const filteredTests = (
  testTemplates: TestTemplate[],
  comparedTestId: string
): TestTemplate[] => {
  return testTemplates.reduce<TestTemplate[]>((prev, currentTestTemplate) => {
    if (
      !hasCyclicDependency(
        testTemplates,
        currentTestTemplate,
        comparedTestId
      ) &&
      currentTestTemplate.id !== comparedTestId
    ) {
      return [...prev, currentTestTemplate];
    }
    return prev;
  }, []);
};

const hasCyclicDependency = (
  testTemplates: TestTemplate[],
  currentTestTemplate: TestTemplate,
  comparedTestId: string
): boolean => {
  if ((currentTestTemplate.dependencies?.length ?? 0) === 0) {
    return false;
  }
  const indirectDependencies: { testId: string; order: number }[] =
    currentTestTemplate.dependencies!.filter(
      (dependency) => dependency.testId !== comparedTestId
    );

  if (
    indirectDependencies.length !== currentTestTemplate.dependencies!.length
  ) {
    return true;
  }

  for (const indirectDependency of indirectDependencies) {
    const newCurrentTestTemplate = testTemplates.find(
      (testTemplate) => testTemplate.id === indirectDependency.testId
    );
    if (
      newCurrentTestTemplate !== undefined &&
      hasCyclicDependency(
        testTemplates,
        newCurrentTestTemplate!,
        comparedTestId
      )
    ) {
      return true;
    }
  }

  return false;
};

export default dependenciesForTestAtom;
