import { MutableRefObject, useCallback } from "react";
import {
  callEmailReaderAPI,
  delay,
  executeCurlCommand,
  findNodeByConfig,
  getCoordinatesForDevice,
  getPropByString,
  invertDirection,
  resourceIdExists,
  trackExceptionOnFirestore,
  trackSentryException,
  wasAudioPlayingInTheLastThreeSeconds,
} from "../utils/helpers";
import { BuildData, ImageDimensions, SettingsData } from "../utils/types";
import { AppetizeCloneSession } from "../utils/appetize-clone";

const useCommandHandler = ({
  appetizeCloneSession,
  session,
  gptStepsRef,
  audioFramesRef,
  buildData,
  settingsData,
  fromEditor,
}: {
  session?: MutableRefObject<any>;
  appetizeCloneSession?: MutableRefObject<AppetizeCloneSession | undefined>;
  gptStepsRef: any;
  audioFramesRef: any;
  buildData: BuildData;
  settingsData: SettingsData;
  fromEditor: boolean;
}) => {
  const handleExecuteCommand = async (callback: () => Promise<any>) => {
    try {
      await callback();
    } catch (e) {
      trackSentryException(e);
      await trackExceptionOnFirestore({ error: e, source: "appetize" });
      throw e;
    }
  };

  const handleSetLocation = useCallback(
    async (cmd: string) => {
      const regexMatch = [
        ...cmd.matchAll(
          /lat=([+-]?[0-9]*[.]?[0-9]+).?\s?long=([+-]?[0-9]*[.]?[0-9]+)/g
        ),
      ]?.[0];

      if (regexMatch) {
        const [whole, latitude, longitude] = regexMatch;

        await handleExecuteCommand(() =>
          session.current.data.setLocation(Number(latitude), Number(longitude))
        );
        console.log(`Changing location to ${latitude}, ${longitude}`);
      } else {
        console.log("Failed to extract coordinates from command: ", cmd);
      }
    },
    [session.current]
  );

  const handleTabOn = useCallback(
    async (
      cmd: string,
      imageDimensions: ImageDimensions,
      duration?: number
    ) => {
      const usingAppetizeClone =
        settingsData.enableAppetizeClone && !fromEditor;

      const coordinates = getCoordinatesForDevice(
        usingAppetizeClone
          ? appetizeCloneSession.current!.device
          : session.current?.data?.device,
        imageDimensions,
        cmd
      );

      if (coordinates !== null) {
        const { deviceX, deviceY } = coordinates;
        console.log(`tapping at x=${deviceX} y=${deviceY}`);
        await handleExecuteCommand(() =>
          usingAppetizeClone
            ? appetizeCloneSession.current!.commands.tap({
                coordinates: { x: deviceX, y: deviceY },
                duration: duration ?? 200,
              })
            : session.current.data.tap({
                coordinates: { x: deviceX, y: deviceY },
                duration: duration ?? 200,
              })
        );
      } else {
        console.log(`Regex not matched! cmd = [${cmd}]. Tapping on element`);
        if (cmd.includes("tabOn.id")) {
          const text = cmd.split("tabOn.id:")[1].trim();
          const match = text.match(/"([^"]*)"/)?.[1];

          await handleExecuteCommand(() =>
            usingAppetizeClone
              ? appetizeCloneSession.current!.commands.tapOnElement({
                  id: match,
                  duration: duration ?? 200,
                })
              : session.current.data.tap(
                  {
                    element: {
                      attributes: {
                        ...(session.current.data?.config?.platform === "android"
                          ? { "resource-id": match }
                          : { accessibilityIdentifier: match }),
                      },
                    },
                  },
                  {
                    matchIndex: 0,
                    // timeout: 3000,
                  }
                )
          );
        } else {
          const text = cmd.split("tabOn:")[1].trim();
          const match = text.match(/"([^"]*)"/)?.[1];

          await handleExecuteCommand(() =>
            usingAppetizeClone
              ? appetizeCloneSession.current!.commands.tapOnElement({
                  text: match,
                  duration: duration ?? 200,
                })
              : session.current.data.tap(
                  {
                    element: {
                      attributes: {
                        text: match,
                      },
                    },
                  },
                  {
                    matchIndex: 0,
                    // timeout: 3000,
                  }
                )
          );
        }
      }
    },
    [settingsData.enableAppetizeClone, fromEditor]
  );

  const handleDoubleTap = useCallback(
    async (cmd: string, imageDimensions: ImageDimensions) => {
      const usingAppetizeClone =
        settingsData.enableAppetizeClone && !fromEditor;
      const isAndroid = session.current.data?.config?.platform === "android";

      const coordinates = getCoordinatesForDevice(
        usingAppetizeClone
          ? appetizeCloneSession.current!.device
          : session.current?.data?.device,
        imageDimensions,
        cmd
      );

      const tapWithADB = async (deviceX: number, deviceY: number) => {
        const mul = 3;
        const command = `input tap ${mul * deviceX} ${mul * deviceY}`;

        console.log("> Sending ADB command:", command);
        await session.current.data.adbShellCommand(command);
        await delay(100);
      };

      if (coordinates !== null) {
        const { deviceX, deviceY } = coordinates;

        console.log(`Double tapping at x=${deviceX} y=${deviceY}`);
        for (let i = 0; i < 5; i++) {
          await handleExecuteCommand(() =>
            usingAppetizeClone
              ? appetizeCloneSession.current!.commands.tap({
                  coordinates: { x: deviceX, y: deviceY },
                  duration: 200,
                })
              : isAndroid
              ? tapWithADB(deviceX, deviceY)
              : session.current.data.tap({
                  coordinates: { x: deviceX, y: deviceY },
                })
          );
          console.log("tap");
        }
      }
    },
    [fromEditor, settingsData.enableAppetizeClone]
  );

  const handleWait = useCallback(
    async (cmd: string) => {
      const [seconds] = cmd.match(/\d+/g);
      console.log("waiting for ", seconds * 1000, "ms");
      // as there is a risk of appetize closing the session because of inactivity and their heartbeat method not working, we thought about tapping somewhere however this is causing problem with the gigxr build
      // console.log("tapping on 50%:0%");
      // await session.current.data.tap({ position: { x: "50%", y: "0%" } });
      await delay(1000 * seconds);
    },
    [session.current]
  );

  const handleGoTo = useCallback(
    async (cmd: string) => {
      const destination = cmd.split("go to:")[1].trim();

      if (destination.toLowerCase().includes("home")) {
        console.log(`Hitting HOME`);

        const usingAppetizeClone =
          settingsData.enableAppetizeClone && !fromEditor;

        await handleExecuteCommand(() =>
          usingAppetizeClone
            ? appetizeCloneSession.current!.commands.tapHomeScreen()
            : session.current.data.keypress("HOME")
        );
      }
    },
    [session.current, settingsData]
  );

  const handleSlide = useCallback(
    async (cmd: string, imageDimensions: ImageDimensions) => {
      const regexMatch = [
        ...cmd.matchAll(/slide:? (left|right|up|down) (\d+)%.*;(\d+);(\d+);/g),
      ]?.[0];
      if (regexMatch) {
        const direction = regexMatch[1];
        const percentage = parseInt(regexMatch[2]);
        const xPos = parseInt(regexMatch[3]);
        const yPos = parseInt(regexMatch[4]);

        console.log(
          `sliding ${direction} ${percentage}% at x=${xPos} y=${yPos}`
        );

        if (settingsData.enableAppetizeClone && !fromEditor) {
          const startCoordinates = { x: 0, y: 0 };
          const endCoordinates = { x: 0, y: 0 };

          const coordinates = getCoordinatesForDevice(
            appetizeCloneSession.current!.device,
            imageDimensions,
            cmd
          );

          const deviceX = coordinates?.deviceX ?? 0;
          const deviceY = coordinates?.deviceY ?? 0;

          const slideLengthY =
            appetizeCloneSession.current!.device.screen.height *
            (percentage / 100);
          const slideLengthX =
            appetizeCloneSession.current!.device.screen.width *
            (percentage / 100);

          // Calculate swipe direction and movement based on input
          if (["up", "down"].includes(direction)) {
            startCoordinates.x = deviceX;
            endCoordinates.x = deviceX; // Keep X coordinate constant for vertical slides
            if (direction === "up") {
              startCoordinates.y = deviceY;
              endCoordinates.y = deviceY - slideLengthY;
            } else {
              startCoordinates.y = deviceY;
              endCoordinates.y = deviceY + slideLengthY;
            }
          } else {
            startCoordinates.y = deviceY; // Starting Y coordinate
            endCoordinates.y = deviceY; // Keep Y coordinate constant for horizontal slides
            if (direction === "left") {
              startCoordinates.x = deviceX;
              endCoordinates.x = deviceX - slideLengthX;
            } else {
              startCoordinates.x = deviceX;
              endCoordinates.x = deviceX + slideLengthX;
            }
          }

          // Execute the swipe using the new swipe method
          await handleExecuteCommand(() =>
            appetizeCloneSession.current.commands.swipe({
              startCoordinates,
              endCoordinates,
            })
          );
        } else {
          await handleExecuteCommand(() =>
            session.current.data.swipe({
              gesture: (g) => g[direction](`${percentage}%`),
              position: {
                x: `${(100 * Number(xPos)) / imageDimensions.width}%`,
                y: `${(100 * Number(yPos)) / imageDimensions.height}%`,
              },
              duration: 2000, // optional, in ms
            })
          );
        }
      } else {
        console.error(`Regex not matched! Command = ${cmd}`);
      }
    },
    [session.current, settingsData.enableAppetizeClone, fromEditor]
  );

  const handleType = useCallback(
    async (cmd: string) => {
      const text = cmd.split("type:")[1].trim();
      const character = text.match(/"([^"]*)"/)?.[1] ?? text.replace(/"/g, "");

      console.log(`typing ${character}`);
      const usingAppetizeClone =
        settingsData.enableAppetizeClone && !fromEditor;

      if (settingsData.splitTextWhenTyping) {
        for (let i = 0; i < character.length; i++) {
          await handleExecuteCommand(() =>
            usingAppetizeClone
              ? appetizeCloneSession.current.commands.type({
                  text: character[i],
                })
              : session.current.data.type(character[i])
          );
        }
      } else {
        await handleExecuteCommand(() =>
          usingAppetizeClone
            ? appetizeCloneSession.current.commands.type({
                text: character,
              })
            : session.current.data.type(character)
        );
        if (usingAppetizeClone) {
          // wait 1s before next action because the appetizeClone /text endpoint returns 200 before whole text is typed
          await delay(1000);
        }
      }
    },
    [settingsData]
  );

  const handleKeypress = useCallback(
    async (cmd: string) => {
      const key = cmd.split("press:")[1].trim();

      if (key.toLowerCase() === "enter") {
        const usingAppetizeClone =
          settingsData.enableAppetizeClone && !fromEditor;

        console.log(`Hitting Enter`);
        await handleExecuteCommand(() =>
          usingAppetizeClone
            ? appetizeCloneSession.current.commands.keyStroke({
                keycode: 66,
              })
            : session.current.data.keypress("Enter")
        );
      }
    },
    [session.current, settingsData]
  );

  const handleSwipeOrScroll = useCallback(
    async (cmd: string) => {
      const regexMatch = [
        ...cmd.matchAll(
          /(swipe|scroll): (right|left|up|down)\s?((\d+) times)?/g
        ),
      ]?.[0];

      if (regexMatch) {
        const swipeOrScroll = regexMatch[1];
        const initialDirection = regexMatch[2];
        const numberOfInteractions = parseInt(regexMatch[4] ?? "1");

        let movementDirection: string = "up";
        movementDirection =
          swipeOrScroll == "scroll"
            ? invertDirection(initialDirection)
            : initialDirection;

        // swipe up at middle of the screen
        const verticalMovement = ["up", "down"].includes(movementDirection);

        for (let i = 0; i < numberOfInteractions; i++) {
          console.log(
            `Performing ${swipeOrScroll} ${initialDirection} ${
              i + 1
            }/${numberOfInteractions}`
          );

          if (settingsData.enableAppetizeClone && !fromEditor) {
            // Coordinates for swiping
            const startCoordinates = { x: 0, y: 0 };
            const endCoordinates = { x: 0, y: 0 };

            // Calculate swipe based on movement direction
            const screenWidth =
              appetizeCloneSession.current.device.screen.width;
            const screenHeight =
              appetizeCloneSession.current.device.screen.height;

            if (verticalMovement) {
              startCoordinates.x = screenWidth / 2;
              endCoordinates.x = screenWidth / 2;
              if (movementDirection === "up") {
                startCoordinates.y = screenHeight * 0.75;
                endCoordinates.y = screenHeight * 0.25;
              } else {
                startCoordinates.y = screenHeight * 0.25;
                endCoordinates.y = screenHeight * 0.75;
              }
            } else {
              startCoordinates.y = screenHeight / 2;
              endCoordinates.y = screenHeight / 2;
              if (movementDirection === "left") {
                startCoordinates.x = screenWidth * 0.9;
                endCoordinates.x = screenWidth * 0.1;
              } else {
                startCoordinates.x = screenWidth * 0.1;
                endCoordinates.x = screenWidth * 0.9;
              }
            }

            await handleExecuteCommand(() =>
              appetizeCloneSession.current.commands.swipe({
                startCoordinates,
                endCoordinates,
              })
            );
          } else {
            await handleExecuteCommand(() =>
              session.current.data.swipe({
                gesture: (g) =>
                  g[movementDirection](verticalMovement ? "50%" : "100%"),
                position: {
                  x: verticalMovement
                    ? "50%"
                    : movementDirection === "left"
                    ? "90%"
                    : "10%",
                  y: verticalMovement
                    ? movementDirection === "down"
                      ? "60%"
                      : "40%"
                    : "50%",
                },
                duration: 3000, // optional, in ms
              })
            );
          }
        }
      } else {
        console.log("Regex not matched! cmd = ", cmd);
      }
    },
    [settingsData.enableAppetizeClone, fromEditor]
  );

  const handleRemoveText = useCallback(
    async (cmd: string) => {
      const usingAppetizeClone =
        settingsData.enableAppetizeClone && !fromEditor;
      let keycode: number = undefined;
      if (usingAppetizeClone) {
        keycode =
          appetizeCloneSession.current!.device.platform === "android" ? 67 : 42;
      }

      let text = cmd.split(":")[1].trim();
      text = text.replace(/"/g, "");
      console.log("removing " + text);

      for (let i = 0; i < text.length; i++) {
        await delay(100 * i);
        console.log("Hitting backspace");
        await handleExecuteCommand(() =>
          usingAppetizeClone
            ? appetizeCloneSession.current!.commands.keyStroke({
                keycode,
              })
            : session.current.data.keypress("Backspace")
        );
      }
    },
    [session.current]
  );

  const handleOpenDeepLink = useCallback(
    async (cmd: string) => {
      let link =
        cmd.split("deeplink:")[1]?.trim() ?? cmd.split("openLink:")[1]?.trim();
      link = link.replace(/"/g, ""); // Remove all double quotes
      console.log("Opening deeplink ", link);

      const usingAppetizeClone =
        settingsData.enableAppetizeClone && !fromEditor;

      await handleExecuteCommand(() =>
        usingAppetizeClone
          ? appetizeCloneSession.current!.commands.openDeepLinkUrl(link)
          : session.current.data.openUrl(link)
      );
    },
    [session.current, settingsData, fromEditor]
  );

  const handleShakeDevice = useCallback(
    async () =>
      await handleExecuteCommand(async () => {
        if (session.current.data?.config?.platform === "android") {
          const command = "input keyevent 82";
          console.log("Shaking the device with command: ", command);
          await session.current.data.adbShellCommand(command);
        } else {
          await session.current.data.shake();
        }
      }),
    [session.current, buildData]
  );

  const handleBackAndroid = useCallback(
    async () =>
      await handleExecuteCommand(async () => {
        if (session.current.data?.config?.platform === "android") {
          const command = "input keyevent 4";
          console.log("Going back with command: ", command);
          await session.current.data.adbShellCommand(command);
        } else {
          throw new Error("Back button is not supported on this platform");
        }
      }),
    [session.current]
  );

  const handleRestartApp = useCallback(async () => {
    console.log(">> Restarting the app");
    await handleExecuteCommand(() => session.current.data.restartApp());
  }, [session.current]);

  const handleExecute = useCallback(
    async (cmd: string) => {
      const regexMatch = [
        ...cmd.matchAll(/.*(GET|DELETE).*url=(.*);json selector=(.*)/g),
      ]?.[0];
      const jsonlessRegexMatch = [
        ...cmd.matchAll(/.*(GET|DELETE).*url=(.*)/g),
      ]?.[0];
      let [whole, method, url, json_selector] = ["", "", "", ""];
      if (regexMatch) {
        [whole, method, url, json_selector] = regexMatch;
      } else if (jsonlessRegexMatch) {
        [whole, method, url] = jsonlessRegexMatch;
      } else {
        throw new Error(`Incorrect command passed: ${cmd}`);
      }

      console.log(
        `Executing ${method} network call to ${url} with json selector = ${json_selector}`
      );

      try {
        const response_json = await executeCurlCommand(
          `curl -X ${method} ${url}`,
          buildData?.organisationId!
        );

        console.log("Response ", response_json);
        const data = getPropByString(response_json.data, json_selector);
        console.log("Data", data);

        gptStepsRef.current[gptStepsRef.current.length - 1] = [
          ...gptStepsRef.current[gptStepsRef.current.length - 1],
          `remember: "network data value":"${JSON.stringify(data)}"`,
        ];
      } catch (e) {
        trackSentryException(e);
        if (e instanceof SyntaxError) {
          console.error(
            "Error occured while handling json selector for network request",
            e
          );
        } else {
          throw e;
        }
      }
    },
    [session.current, buildData]
  );

  const handleVerifyEmail = useCallback(
    async (cmd: string) => {
      const email = cmd.match(/email=(.*);link_pattern=(.*)/)?.[1];
      const link_pattern = cmd.match(/email=(.*);link_pattern=(.*)/)?.[2];
      if (email === undefined || link_pattern === undefined) {
        throw new Error(
          `Incorrect data passed: email=${email}, json link_pattern=${link_pattern}`
        );
      }
      console.log(
        `Veryfying email ${email} with link_pattern = ${link_pattern}`
      );
      let link;
      for (let i = 0; i < 2; i++) {
        try {
          link = await callEmailReaderAPI(email, link_pattern);
          if (link) {
            gptStepsRef.current[gptStepsRef.current.length - 1] = [
              ...gptStepsRef.current[gptStepsRef.current.length - 1],
              `remember: "email data value":"${JSON.stringify(link)}"`,
            ];
            break;
          }
        } catch (e) {
          trackSentryException(e);
          console.log("Error occurred during execution of network call", e);
          console.log("Waiting 10 seconds and retrying...");
          await new Promise((r) => setTimeout(r, 1000 * 10));
        }
      }
      if (!link) {
        throw new Error(`Incorrect link = ${link}`);
      }
    },
    [session.current]
  );

  const handleCheckAudio = useCallback(() => {
    if (wasAudioPlayingInTheLastThreeSeconds(audioFramesRef.current)) {
      gptStepsRef.current[gptStepsRef.current.length - 1] = [
        ...gptStepsRef.current[gptStepsRef.current.length - 1],
        `remember: "audio was being played"`,
      ];
    } else {
      gptStepsRef.current[gptStepsRef.current.length - 1] = [
        ...gptStepsRef.current[gptStepsRef.current.length - 1],
        `remember: "audio was not being played"`,
      ];
    }
  }, [session.current]);

  const handleLaunchApp = useCallback(
    async (cmd: String) => {
      const appId = cmd.split("launchApp:")[1].trim().replace(/"/g, "");
      console.log(`>> Launching app ${appId}`);
      await handleExecuteCommand(() => session.current.data.launchApp(appId));
    },
    [session.current]
  );

  const handleAssertVisible = useCallback(
    async (cmd: string, uiElements: any[]) => {
      if (session.current.data?.config?.platform === "android") {
        const text = cmd.split("assertVisible.id:")[1].trim();
        const match = text.match(/"([^"]+)"\s*(.*)\s*Result=true/);
        const id = match?.[1];
        const hintForModel = match?.[2];
        if (id === undefined) {
          throw new Error(`Incorrect command passed: ${cmd}`);
        }
        if (!resourceIdExists(uiElements, id)) {
          if (hintForModel !== undefined && hintForModel.length > 0) {
            throw new Error(
              `UI elements check failed: Asserting ${hintForModel} is visible`
            );
          } else {
            throw new Error(`Element with id ${id} not found`);
          }
        }
      } else {
        throw new Error("assertVisible is not supported on this platform");
      }
    },
    [session.current]
  );

  const handleScrollUntilIdVisible = useCallback(
    async (cmd: string, uiElements: any[]) => {
      if (session.current.data?.config?.platform === "android") {
        const text = cmd.split(/scroll(?:Up|Down)UntilVisible\.id:/)[1].trim();
        const isUp = cmd.includes("scrollUpUntilVisible");

        const match = text.match(/"([^"]+)"\s*(.*)\s*Result=true/);
        const id = match?.[1];
        const hintForModel = match?.[2];
        if (id === undefined) {
          throw new Error(`Incorrect command passed: ${cmd}`);
        }
        let element = findNodeByConfig(uiElements, { targetId: id });
        let found = false;
        for (let i = 0; i <= 5; i++) {
          if (i > 0) {
            await handleSwipeOrScroll(`scroll: ${isUp ? "up" : "down"}`);
            const updatedElements = await session.current.data.getUI({
              timeout: 5000,
            });
            element = findNodeByConfig(updatedElements, { targetId: id });
          }
          if (element != null && element.bounds.height > 0) {
            found = true;
            break;
          }
        }
        if (!found) {
          if (hintForModel !== undefined && hintForModel.length > 0) {
            throw new Error(
              `UI elements check failed: Asserting ${hintForModel} is visible after scrolling down 5 times`
            );
          } else {
            throw new Error(
              `Element with id ${id} not found after scrolling down 5 times`
            );
          }
        }
      } else {
        throw new Error(
          "handleScrollUntilVisible.id is not supported on this platform"
        );
      }
    },
    [session.current]
  );

  const handleScrollUntilTextVisible = useCallback(
    async (cmd: string, uiElements: any[]) => {
      if (session.current.data?.config?.platform === "android") {
        const text = cmd
          .split(/scroll(?:Up|Down)UntilVisible\.text:/)[1]
          .trim();
        const isUp = cmd.includes("scrollUpUntilVisible");

        const match = text.match(/"([^"]+)"\s*(.*)\s*Result=true/);
        const id = match?.[1];
        const hintForModel = match?.[2];
        if (id === undefined) {
          throw new Error(`Incorrect command passed: ${cmd}`);
        }
        let element = findNodeByConfig(uiElements, { text: id });
        let found = false;
        for (let i = 0; i <= 5; i++) {
          if (i > 0) {
            await handleSwipeOrScroll(`scroll: ${isUp ? "up" : "down"}`);
            const updatedElements = await session.current.data.getUI({
              timeout: 5000,
            });
            element = findNodeByConfig(updatedElements, { text: id });
          }
          if (element != null && element.bounds.height > 0) {
            found = true;
            break;
          }
        }
        if (!found) {
          if (hintForModel !== undefined && hintForModel.length > 0) {
            throw new Error(
              `UI elements check failed: Asserting ${hintForModel} is visible after scrolling down 5 times`
            );
          } else {
            throw new Error(
              `Element with text ${id} not found after scrolling down 5 times`
            );
          }
        }
      } else {
        throw new Error(
          "handleScrollUntilVisible.text is not supported on this platform"
        );
      }
    },
    [session.current]
  );

  const commandHandler = useCallback(
    async ({
      cmd,
      imageDimensions,
      uiElements,
    }: {
      cmd: string;
      imageDimensions: ImageDimensions;
      uiElements: any[];
    }) => {
      if (cmd.includes("tabOn:") || cmd.includes("tabOn.id:")) {
        await handleTabOn(cmd, imageDimensions);
      } else if (cmd.includes("set location:")) {
        await handleSetLocation(cmd);
      } else if (cmd.includes("tap in sequence:")) {
        await handleTabOn(cmd, imageDimensions);
      } else if (cmd.includes("double tap:")) {
        await handleDoubleTap(cmd, imageDimensions);
      } else if (cmd.includes("long press:")) {
        await handleTabOn(cmd, imageDimensions, 2000);
      } else if (cmd.includes("wait:")) {
        await handleWait(cmd);
      } else if (cmd.includes("type:")) {
        await handleType(cmd);
      } else if (cmd.includes("press:")) {
        await handleKeypress(cmd);
      } else if (cmd.includes("scroll:")) {
        await handleSwipeOrScroll(cmd);
      } else if (cmd.includes("removeText:")) {
        await handleRemoveText(cmd);
      } else if (cmd.includes("open deeplink:") || cmd.includes("openLink:")) {
        await handleOpenDeepLink(cmd);
      } else if (cmd.includes("verify email:")) {
        await handleVerifyEmail(cmd);
      } else if (cmd.includes("swipe")) {
        await handleSwipeOrScroll(cmd);
      } else if (cmd.includes("slide")) {
        await handleSlide(cmd, imageDimensions);
      } else if (cmd.includes("execute:")) {
        await handleExecute(cmd);
      } else if (cmd.includes("check: audio")) {
        handleCheckAudio();
      } else if (cmd.includes("go to:")) {
        await handleGoTo(cmd);
      } else if (cmd.includes("shake the device")) {
        await handleShakeDevice();
      } else if (cmd.includes("restart")) {
        await handleRestartApp();
      } else if (cmd.includes("launchApp:")) {
        await handleLaunchApp(cmd);
      } else if (cmd.includes("pressBackButton")) {
        await handleBackAndroid();
      } else if (cmd.includes("assertVisible.id:")) {
        await handleAssertVisible(cmd, uiElements);
      } else if (
        cmd.includes("scrollUpUntilVisible.text:") ||
        cmd.includes("scrollDownUntilVisible.text:")
      ) {
        await handleScrollUntilTextVisible(cmd, uiElements);
      } else if (
        cmd.includes("scrollUpUntilVisible.id:") ||
        cmd.includes("scrollDownUntilVisible.id:")
      ) {
        await handleScrollUntilIdVisible(cmd, uiElements);
      }
    },
    [
      handleTabOn,
      handleDoubleTap,
      handleWait,
      handleType,
      handleRemoveText,
      handleExecute,
      handleSwipeOrScroll,
      handleVerifyEmail,
      handleOpenDeepLink,
      handleCheckAudio,
      handleShakeDevice,
      session?.current,
      appetizeCloneSession?.current,
    ]
  );

  return commandHandler;
};

export default useCommandHandler;
