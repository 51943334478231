import React, { useCallback } from "react";
import { atomWithReset } from "jotai/utils";
import { useAtom } from "jotai";
import { Alert, Snackbar } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";

const CustomAlert = () => {
  const [state, setState] = useAtom(customAlertStateAtom);

  const closeHandler = useCallback(() => {
    setState((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <Snackbar
      open={state.open}
      onClose={closeHandler}
      className={"absolute  z-10"}
      style={{ maxWidth: "384px" }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={state.maintainDisplayed ? null : 3000}
    >
      <Alert severity={state.type} onClose={closeHandler}>
        {state.title !== undefined && (
          <AlertTitle
            sx={{
              ...(state.description == null &&
                state.listContent == null && { marginBottom: 0 }),
            }}
          >
            {state.title}
          </AlertTitle>
        )}
        {state.description !== undefined && state.description}
        {state.listContent !== undefined && state.listContent.length > 0 && (
          <ul className="mt-2 ml-2 list-disc list-inside">
            {state.listContent.map((listItem, index) => (
              <li key={index}>{listItem}</li>
            ))}
          </ul>
        )}
      </Alert>
    </Snackbar>
  );
};

const customAlertStateAtom = atomWithReset<{
  type?: "error" | "info" | "success" | "warning";
  title?: string;
  description?: string;
  listContent?: string[];
  open: boolean;
  maintainDisplayed?: boolean;
}>({
  open: false,
  maintainDisplayed: false,
});

export { CustomAlert, customAlertStateAtom };
