import { Button, Paper, Tooltip } from "@mui/material";
import { Delete, FileCopy, Stop, VideoCameraFront } from "@mui/icons-material";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { createSimpleHtmlList } from "../../utils/helpers";

const TestEditorAssistant = (props: {
  promptGeneratorEnabled: boolean;
  handleRecordActionsButton: () => void;
  session: any;
  enrichedPrompts: string[];
  resetPromptGenerator: () => void;
  sortedInteractionIds: string[];
  promptGenerationScreenshots: { [id: number]: string[] };
  enrichedInteractions: {
    [id: number]: { rawLog: string; enrichedLog: string }[];
  };
  enrichedFromApiInteractions: {
    [id: number]: { rawLog: string; enrichedLog: string }[];
  };
  scrollableRef: React.RefObject<HTMLDivElement>;
  annotatedScreenshots: { [id: number]: string[] } | undefined;
}) => {
  const enrichedPromptsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleCopyListener = (event: ClipboardEvent) => {
      event.preventDefault();
      if (event.clipboardData) {
        const selectedText = document.getSelection()?.toString() ?? "";
        const regex = /(\d+)\.\s+(.*)/g;
        let match;
        let modifiedItems = [];

        while ((match = regex.exec(selectedText)) !== null) {
          modifiedItems.push(match[2]); // Pushing only the text part, excluding the numbering
        }

        if (modifiedItems.length > 0) {
          navigator.clipboard.write([
            new ClipboardItem({
              "text/html": new Blob([createSimpleHtmlList(modifiedItems)], {
                type: "text/html",
              }),
            }),
          ]);
        } else {
          navigator.clipboard.writeText(selectedText);
        }
      }
    };

    enrichedPromptsListRef.current?.addEventListener(
      "copy",
      handleCopyListener
    );

    return () =>
      enrichedPromptsListRef.current?.removeEventListener(
        "copy",
        handleCopyListener
      );
  }, []);

  const handleCopy = useCallback(
    () =>
      navigator.clipboard.write([
        new ClipboardItem({
          "text/html": new Blob([createSimpleHtmlList(props.enrichedPrompts)], {
            type: "text/html",
          }),
        }),
      ]),
    [props.enrichedPrompts]
  );

  const enrichedPromptsList = useMemo(
    () =>
      props.enrichedPrompts.map(
        (enrichedPrompt, index) => `${index + 1}. ${enrichedPrompt}\n`
      ),
    [props.enrichedPrompts]
  );

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className={"py-4 w-full"}>
          <div className="flex gap-4 px-4 items-center basis-3/4 justify-between">
            <h3 className="text-[16px] break-words inline-block text-[#797979]">
              Generated Prompt
            </h3>
            <div className={"flex gap-2 flex-nowrap"}>
              <Tooltip
                title={`${
                  props.promptGeneratorEnabled
                    ? "Stop recording"
                    : "Record interactions with the app"
                }`}
                placement="top"
              >
                <span>
                  <Button
                    variant={"contained"}
                    onClick={props.handleRecordActionsButton}
                    disabled={
                      props.session === null ||
                      (props.session.data?.config?.platform === "android" &&
                        props.session?.data?.config.enableAdb)
                    }
                    color={props.promptGeneratorEnabled ? "error" : "primary"}
                  >
                    {props.promptGeneratorEnabled ? (
                      <Stop />
                    ) : (
                      <VideoCameraFront />
                    )}
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={"Copy generated prompt"} placement="top">
                <span>
                  <Button
                    onClick={handleCopy}
                    disabled={
                      props.session === null ||
                      props.enrichedPrompts?.length === 0
                    }
                    variant={"contained"}
                    color={"primary"}
                  >
                    <FileCopy />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={"Clear generated prompt"} placement="top">
                <span>
                  <Button
                    onClick={props.resetPromptGenerator}
                    disabled={
                      props.session === null ||
                      props.enrichedPrompts?.length === 0
                    }
                    variant={"contained"}
                    color={"primary"}
                  >
                    <Delete />
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="relative rounded-md shadow-md mt-2.5">
          <div
            className={
              "w-full p-4  rounded-b-[16px] h-[360px] overflow-auto whitespace-pre-line"
            }
            ref={enrichedPromptsListRef}
          >
            {enrichedPromptsList}
          </div>
        </div>
      </Paper>
      <h3 className="text-white text-[16px] ml-4 mt-8 mb-2">
        Recorded Actions
      </h3>
      <div className="">
        <div className="flex flex-col flex-1 w-full rounded-t-[16px]">
          {props.sortedInteractionIds.length > 0 && (
            <div
              className={
                "flex flex-col gap-1 flex-grow-1 rounded-b-[16px] bg-black"
              }
              ref={props.scrollableRef}
            >
              {props.sortedInteractionIds.map((sortedInteractionId) => {
                const parsedInteractionId = parseInt(sortedInteractionId);
                return props.promptGenerationScreenshots[
                  parsedInteractionId
                ].map((_, index) => {
                  const invertedIndex =
                    props.promptGenerationScreenshots[parsedInteractionId]
                      .length -
                    1 -
                    index;
                  const currentEnrichedInteraction =
                    props.enrichedInteractions[parsedInteractionId][
                      invertedIndex
                    ];
                  const currentEnrichedInteractionFromApi =
                    props.enrichedFromApiInteractions[parsedInteractionId]?.[
                      invertedIndex
                    ];
                  const nonAnnotatedImage =
                    props.promptGenerationScreenshots[parsedInteractionId][
                      invertedIndex
                    ];
                  const annotatedImage =
                    props.annotatedScreenshots &&
                    props.annotatedScreenshots[parsedInteractionId][
                      invertedIndex
                    ];

                  return (
                    <div
                      className="grid grid-cols-2 text-left text-[#232323]  divide-x divide-[#656565]"
                      key={sortedInteractionId + index}
                    >
                      <div className={"p-5"}>
                        <img src={annotatedImage ?? nonAnnotatedImage} />
                      </div>
                      <div className={"p-5 text-white"}>
                        <div>
                          <p className="font-semibold underline">
                            Raw interaction:
                          </p>
                          <p>
                            {currentEnrichedInteractionFromApi?.rawLog ??
                              currentEnrichedInteraction.rawLog}
                          </p>
                        </div>
                        <div>
                          <p className="font-semibold underline">
                            Enriched interaction:
                          </p>
                          <p>
                            {currentEnrichedInteraction.enrichedLog !== null &&
                            currentEnrichedInteraction.enrichedLog.length > 0
                              ? currentEnrichedInteraction.enrichedLog
                              : currentEnrichedInteractionFromApi?.enrichedLog ??
                                "Generating..."}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TestEditorAssistant;
