import React, { useMemo } from "react";
import { SessionInfoTabs } from "./session_info_tabs";
import { useLoaderData } from "react-router-dom";
import {
  TestSuiteRunRecordingData,
  TestSuiteData,
  type TestSuiteLoaderApp,
} from "../../utils/types";
import { useAtomValue } from "jotai";
import { capitalizeFirstLetter, getPromptStepsText } from "../../utils/helpers";
import { DateTime } from "luxon";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";
import { styled as materialStyled } from "@mui/material/styles";

const RecordingInformation = ({
  runRecordingData,
}: {
  runRecordingData: TestSuiteRunRecordingData;
}) => {
  const loaderData = useLoaderData() as TestSuiteLoaderApp;
  const testSuiteDataQuery = useAtomValue(loaderData.testSuiteDataAtom);
  const testSuiteData = testSuiteDataQuery.data as TestSuiteData;

  const parentRun = runRecordingData.runs[runRecordingData.runs.length - 1];

  const titleSeverity = useMemo(() => {
    const status = parentRun.runData!.status!;
    if (status === "failed") {
      return "error";
    } else if (status === "blocked" || status === "canceled") {
      return "warning";
    }
    return "success";
  }, [parentRun]);

  return (
    <ContentWrapper>
      <CustomAlert severity={titleSeverity}>
        <AlertTitle>{parentRun.runData!.title}</AlertTitle>
        {parentRun.runData?.statusMessage}
      </CustomAlert>
      <SessionSection>
        <Accordion elevation={2}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Test Session Information</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: "256px", overflow: "auto" }}>
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="text-left">Property</th>
                  <th className="text-left">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pr-4">Status</td>
                  <td>{parentRun.runData?.status}</td>
                </tr>
                {parentRun.runData!.statusMessage.length! > 0 && (
                  <tr>
                    <td className="pr-4">Status Message</td>
                    <td>{parentRun.runData?.statusMessage}</td>
                  </tr>
                )}
                <tr>
                  <td className="pr-4">Prompt</td>
                  <td>
                    {runRecordingData.runs.map((run, index) => (
                      <div className={"flex flex-col"}>
                        <p className={"font-bold"}>
                          {`Test #${index + 1}`} - {run.runData?.title}
                        </p>
                        {getPromptStepsText(run.promptSteps)
                          .split("\n")
                          .map((text, textIndex) => (
                            <p key={textIndex}>{text}</p>
                          ))}
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td className="pr-4">Locale</td>
                  <td>{parentRun.locale}</td>
                </tr>
                {parentRun.runData?.coordinates?.latitude != null &&
                  parentRun.runData?.coordinates?.longitude && (
                    <tr>
                      <td className="pr-4">Position</td>
                      <td>
                        {parentRun.runData.coordinates.latitude},
                        {parentRun.runData.coordinates.longitude}
                      </td>
                    </tr>
                  )}
                {testSuiteData.appVersionCode != null && (
                  <tr>
                    <td className="pr-4">App Version Code</td>
                    <td>{testSuiteData.appVersionCode}</td>
                  </tr>
                )}
                {testSuiteData.appVersionName != null && (
                  <tr>
                    <td className="pr-4">App Version Name</td>
                    <td>{testSuiteData.appVersionName}</td>
                  </tr>
                )}
                {testSuiteData.app_name != null && (
                  <tr>
                    <td className="pr-4">App Name</td>
                    <td>{testSuiteData.app_name}</td>
                  </tr>
                )}
                {testSuiteData.bundle != null && (
                  <tr>
                    <td className="pr-4">App Bundle</td>
                    <td>{testSuiteData.bundle}</td>
                  </tr>
                )}
                {parentRun.platform != null && (
                  <tr>
                    <td className="pr-4">Platform</td>
                    <td>{capitalizeFirstLetter(parentRun.platform)}</td>
                  </tr>
                )}
                <tr>
                  <td className="pr-4">OS</td>
                  <td>{capitalizeFirstLetter(parentRun.os)}</td>
                </tr>
                <tr>
                  <td className="pr-4">Device</td>
                  <td>{capitalizeFirstLetter(parentRun.device)}</td>
                </tr>
                <tr>
                  <td className="pr-4">Meta Data</td>
                  <td>
                    {testSuiteData &&
                      Object.keys(testSuiteData.metaData).length > 0 && (
                        <>
                          {Object.keys(testSuiteData.metaData).map((key) => (
                            <div key={key}>
                              {key}: {testSuiteData.metaData[key]}
                            </div>
                          ))}
                        </>
                      )}
                    {testSuiteData &&
                      Object.keys(testSuiteData.metaData).length === 0 && (
                        <p>-</p>
                      )}
                  </td>
                </tr>
                {parentRun.startedTimeInEpoch != null && (
                  <tr>
                    <td className="pr-4">Started at</td>
                    <td>
                      {DateTime.fromMillis(
                        parentRun.startedTimeInEpoch * 1000
                      ).toFormat("yyyy-MM-dd hh:mm a")}
                    </td>
                  </tr>
                )}
                {parentRun.runData?.finishedTimeInEpoch != null && (
                  <tr>
                    <td className="pr-4">Finished at</td>
                    <td>
                      {DateTime.fromMillis(
                        parentRun.runData.finishedTimeInEpoch * 1000
                      ).toFormat("yyyy-MM-dd hh:mm a")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      </SessionSection>
      <LogsSection>
        <SessionInfoTabs runRecordingData={runRecordingData} />
      </LogsSection>
    </ContentWrapper>
  );
};

const CustomAlert = materialStyled(Alert)(() => ({
  marginInline: "16px",
  marginTop: "8px",
}));

const ContentWrapper = styled.div`
  grid-area: content;
  display: grid;
  grid-template-areas:
    "title"
    "session"
    "logs";
  column-gap: 12px;
  grid-template-rows: auto auto minmax(0, 1fr);
  height: 100%;
  gap: 16px;
`;

const SessionSection = styled.div`
  grid-area: session;
  padding-inline: 16px;
`;

const LogsSection = styled.div`
  grid-area: logs;
  display: grid;
  grid-template-areas:
    "tabs"
    "tab-content";
  grid-template-rows: auto minmax(0, 1fr);
`;

export default RecordingInformation;
