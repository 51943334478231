import { atomFamily } from "jotai/utils";
import { atomWithQuery } from "jotai-tanstack-query";
import { RunExecution, type TestTemplate } from "../utils/types";
import {
  and,
  collection,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { customFirestore } from "../firebase";
import { getGptDriverInput, getPromptStepsText } from "../utils/helpers";
import { kAllTestsId } from "../routes/tests-home/tests-tree-view";
import { companyDataAtom } from "../routes/test-run/test-run-atoms";

interface TestListData {
  values: TestTemplate[];
  availableTags: string[];
}

const getTestsAtom = atomFamily(
  ({
    organizationId,
    folderId,
  }: {
    organizationId: string;
    folderId?: string;
  }) =>
    atomWithQuery((get) => {
      const companyAtomValue = get(companyDataAtom(organizationId));

      return {
        queryKey: ["getTests", organizationId, folderId],
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        enabled: companyAtomValue.data != null,
        queryFn: async ({ queryKey: [, id, folderId] }) => {
          let queryConstraints = [
            or(
              where("organisationId", "==", id),
              where("organizationId", "==", id)
            ),
          ];
          if (folderId != null && folderId != kAllTestsId) {
            queryConstraints = [
              and(
                where("folderId", "==", folderId),
                or(
                  where("organisationId", "==", id),
                  where("organizationId", "==", id)
                )
              ),
            ];
          }

          const testsListQuery = query(
            collection(customFirestore, "custom-tests"),
            ...queryConstraints,
            orderBy("title", "asc")
          );

          const testsListQuerySnapshots = await getDocs(testsListQuery);

          if (testsListQuerySnapshots.empty) {
            return { values: [], availableTags: [] };
          }
          const settingsData = companyAtomValue.data!.settings;
          const availableTags = settingsData?.tags ?? [];
          const runHistoryEnabled = settingsData?.runHistoryEnabled ?? false;
          let runHistories: { [index: string]: any[] } = {};

          if (runHistoryEnabled) {
            await Promise.all(
              testsListQuerySnapshots.docs.map(async (test) => {
                const runHistoriesQuery = query(
                  collection(
                    customFirestore,
                    "custom-tests",
                    test.id,
                    "run-history"
                  ),
                  orderBy("finishedTimeInEpoch", "desc"),
                  limit(10)
                );

                runHistories[test.id] = (await getDocs(runHistoriesQuery)).docs
                  .map((doc) => doc.data() as RunExecution)
                  .reverse();
              })
            );
          }

          const values: TestTemplate[] = testsListQuerySnapshots.docs.map(
            (test) => {
              const testData = test.data() as TestTemplate;
              testData.id = test.id;
              testData.runHistory = runHistories[test.id] ?? [];
              testData.parsedCommands = getPromptStepsText(
                getGptDriverInput(testData.commands)
              );

              return testData;
            }
          );

          return { values, availableTags };
        },
      };
    }),
  (a, b) => a.folderId == b.folderId && a.organizationId == b.organizationId
);

export { getTestsAtom, type TestListData };
