import { QueryObserverResult } from "@tanstack/query-core";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  gridFilteredSortedRowEntriesSelector,
  gridQuickFilterValuesSelector,
  GridRowSelectionModel,
  GridToolbar,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import { Dispatch, SetStateAction } from "react";
import * as React from "react";
import CustomNoRowsOverlay from "./custom-now-rows-overlay";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { GridFilterModel } from "@mui/x-data-grid/models/gridFilterModel";
import { GridCallbackDetails } from "@mui/x-data-grid/models/api";

const GenericDatatable = ({
  query,
  columns,
  initialState,
  rowSelectionModel,
  setRowSelectionModel,
  toolbarActions,
  noRowsTitle,
  apiRef,
  columnVisibilityModel,
  setColumnVisibilityModel,
  onFilterModelChange,
  enableFolders,
  data,
  setDisplayUncategorizedOnly,
  displayUncategorizedOnly,
}: {
  query: QueryObserverResult;
  columns: GridColDef[];
  initialState: GridInitialStateCommunity;
  rowSelectionModel?: GridRowSelectionModel;
  setRowSelectionModel?: Dispatch<SetStateAction<GridRowSelectionModel>>;
  columnVisibilityModel?: GridColumnVisibilityModel;
  setColumnVisibilityModel?: Dispatch<
    SetStateAction<GridColumnVisibilityModel>
  >;
  toolbarActions?: React.JSXElementConstructor<any>;
  noRowsTitle?: string;
  apiRef?: React.MutableRefObject<GridApiCommunity>;
  enableFolders: boolean;
  onFilterModelChange?: (
    model: GridFilterModel,
    details: GridCallbackDetails<"filter">
  ) => void;
  data?: any[];
  displayUncategorizedOnly: boolean;
  setDisplayUncategorizedOnly: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <DataGrid
      autoHeight={false}
      apiRef={apiRef}
      className={"bg-white"}
      columns={columns}
      rows={data}
      loading={query.isLoading}
      checkboxSelection
      disableRowSelectionOnClick
      initialState={initialState}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={setColumnVisibilityModel}
      onFilterModelChange={onFilterModelChange}
      rowSelectionModel={rowSelectionModel}
      showCellVerticalBorder={true}
      slots={{
        toolbar: toolbarActions,
        noRowsOverlay: CustomNoRowsOverlay,
      }}
      onRowSelectionModelChange={setRowSelectionModel}
      slotProps={{
        noRowsOverlay: { title: noRowsTitle },
        toolbar: {
          rowSelectionModel,
          setRowSelectionModel,
          enableFolders,
          setDisplayUncategorizedOnly,
          displayUncategorizedOnly,
        },
      }}
    />
  );
};

export default GenericDatatable;
