import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  runTransaction,
  where,
  Transaction,
  deleteField,
} from "firebase/firestore";
import { customFirestore } from "../../firebase";
import {
  ParsedTestFolderItem,
  ParsedTestPreview,
  TestFolder,
} from "../../utils/types";
import * as React from "react";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "../../auth/AuthContext";
import clsx from "clsx";
import { animated, useSpring } from "@react-spring/web";
import { alpha, styled as materialStyled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import ArticleIcon from "@mui/icons-material/Article";
import List from "@mui/icons-material/List";
import FolderRounded from "@mui/icons-material/FolderRounded";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { treeItemClasses } from "@mui/x-tree-view/TreeItem";
import {
  unstable_useTreeItem2 as useTreeItem2,
  UseTreeItem2Parameters,
} from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import { atomWithReset, RESET } from "jotai/utils";
import { useSetAtom } from "jotai";
import {
  Add,
  CreateNewFolder,
  Delete,
  Edit,
  MoreVert,
} from "@mui/icons-material";
import { useTreeViewApiRef } from "@mui/x-tree-view";
import { Button, IconButton, MenuItem } from "@mui/material";
import AddFolderDialog, { addFolderStateAtom } from "./add-folder-dialog";
import styled from "@emotion/styled";
import { AsyncConfirmationModal } from "../../components/asyncConfirmationModal";
import { customAlertStateAtom } from "../../components/custom-alert";
import { getTestsAtom } from "../../atoms/testing-settings-atoms";
import { arrayRemove } from "@firebase/firestore";
import { trackSentryException } from "../../utils/helpers";
import StyledMenu from "../../components/styledMenu";
import EditFolderDialog, { editFolderStateAtom } from "./edit-folder-dialog";

const kAllTestsId = "allTests";

type FileType = "test" | "folder" | "all";

declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

const StyledTreeItemRoot = materialStyled(TreeItem2Root)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[400],
  position: "relative",
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3.5),
  },
})) as unknown as typeof TreeItem2Root;

const CustomTreeItemContent = materialStyled(TreeItem2Content)(({ theme }) => ({
  flexDirection: "row-reverse",
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  [`&.Mui-expanded`]: {
    "&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon":
      {
        color:
          theme.palette.mode === "light"
            ? theme.palette.primary.main
            : theme.palette.primary.dark,
      },
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: "16px",
      top: "44px",
      height: "calc(100% - 48px)",
      width: "1.5px",
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
    },
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color:
      theme.palette.mode === "light" ? theme.palette.primary.main : "white",
  },
  [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = materialStyled(Typography)({
  color: "inherit",
  fontSize: "0.8125rem",
}) as unknown as typeof Typography;

interface CustomLabelProps {
  children: React.ReactNode;
  icon?: React.ElementType;
  expandable?: boolean;
}

function CustomLabel({
  icon: Icon,
  expandable,
  children,
  ...other
}: CustomLabelProps) {
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {Icon && (
        <Box
          component={Icon}
          className="labelIcon"
          color="inherit"
          sx={{ mr: 1, fontSize: "1.2rem" }}
        />
      )}

      <StyledTreeItemLabelText variant="body2">
        {children}
      </StyledTreeItemLabelText>
    </TreeItem2Label>
  );
}

const isExpandable = (reactChildren: React.ReactNode) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

const getIconFromFileType = (fileType: FileType) => {
  switch (fileType) {
    case "test":
      return ArticleIcon;
    case "folder":
      return FolderRounded;
    case "all":
      return List;
    default:
      return ArticleIcon;
  }
};

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
    Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {}

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps & {
    setDeleteFolderDialogState: Dispatch<
      SetStateAction<{
        open: boolean;
        folderId?: string;
      }>
    >;
  },
  ref: React.Ref<HTMLLIElement>
) {
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    setDeleteFolderDialogState,
    ...other
  } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId) as ParsedTestFolderItem;
  const expandable = isExpandable(children);

  let icon;
  if (expandable) {
    icon = FolderRounded;
  } else if (item.type) {
    icon = getIconFromFileType(item.type as FileType);
  }

  const setAddFolderState = useSetAtom(addFolderStateAtom);
  const setEditFolderState = useSetAtom(editFolderStateAtom);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(menuAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)}>
        <div className={"flex gap-1"}>
          <CustomTreeItemContent
            {...getContentProps({
              className: clsx("content", {
                "Mui-expanded": status.expanded,
                "Mui-selected": status.selected,
                "Mui-focused": status.focused,
                "Mui-disabled": status.disabled,
              }),
            })}
          >
            <TreeItem2IconContainer {...getIconContainerProps()}>
              <TreeItem2Icon status={status} />
            </TreeItem2IconContainer>
            {item.descendantTestsAmount > 0 && (
              <Typography variant="caption" color="inherit">
                {item.descendantTestsAmount}
              </Typography>
            )}
            <CustomLabel
              {...getLabelProps({
                icon,
                expandable: expandable && status.expanded,
              })}
            />
          </CustomTreeItemContent>
          {item.type == "folder" && status.selected && (
            <>
              <IconButton size={"small"} onClick={handleClick}>
                <MoreVert />
              </IconButton>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={menuAnchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setAddFolderState({ parentFolder: itemId, open: true });
                    handleClose();
                  }}
                  disableRipple
                >
                  <Add />
                  Add
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setEditFolderState({
                      open: true,
                      folder: item,
                    });
                    handleClose();
                  }}
                  disableRipple
                >
                  <Edit />
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeleteFolderDialogState({
                      open: true,
                      folderId: itemId,
                    });
                    handleClose();
                  }}
                  disableRipple
                >
                  <Delete />
                  Delete
                </MenuItem>
              </StyledMenu>
            </>
          )}
        </div>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

const getSubFolders = (
  folders: TestFolder[],
  folderId: string
): ParsedTestFolderItem => {
  const folder = folders.find((folder) => folder.id == folderId)!;

  const nestedFolders: ParsedTestFolderItem[] =
    folder.subFolders
      ?.map((folderId) => getSubFolders(folders, folderId))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const tests: ParsedTestPreview[] =
    folder.tests
      ?.map((test) => ({
        id: test.id,
        label: test.title,
        updatedAt: test.updatedAt,
        type: "test",
        parentFolderId: folder.id,
      }))
      .sort((a, b) => {
        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }) ?? [];

  const descendantTestsAmount = nestedFolders?.reduce(
    (prev, current) => prev + current.descendantTestsAmount,
    0
  );

  return {
    id: folder.id,
    label: folder.name,
    type: "folder",
    children: [...nestedFolders, ...tests],
    descendantTestsAmount: descendantTestsAmount + tests.length,
  };
};

const getFolderItems = async (organisationId: string) => {
  const foldersQuery = query(
    collection(customFirestore, "folders"),
    where("organisationId", "==", organisationId),
    orderBy("name")
  );

  const querySnapshots = await getDocs(foldersQuery);

  const folders = querySnapshots.docs.map<TestFolder>(
    (doc) =>
      ({
        id: doc.id,
        ...doc.data(),
      } as TestFolder)
  );

  const rootFolders = folders.filter((folder) => folder.parentFolderId == null);

  const parsedFolders = rootFolders.map((folder) =>
    getSubFolders(folders, folder.id)
  );

  return parsedFolders;
};

const TestsTreeView = ({ expandFolders }: { expandFolders: boolean }) => {
  const { user } = useContext<any>(AuthContext);

  const setFoldersState = useSetAtom(foldersStateAtom);
  const setAddFolderState = useSetAtom(addFolderStateAtom);

  const { data } = useQuery({
    queryKey: ["getFolders"],
    queryFn: async () => {
      try {
        const parsedData = await getFolderItems(user.companyId);

        const folders = [
          {
            id: kAllTestsId,
            label: "All tests",
            type: "all",
            children: [],
            descendantTestsAmount: 0,
          },
          ...parsedData,
        ];

        setFoldersState((prev) => ({
          selectedFolderId: prev.selectedFolderId ?? folders.at(0)!.id,
          selectedTestId: undefined,
          folders,
        }));
        return folders;
      } catch (e) {
        console.error(e);
      }
    },
  });

  useEffect(() => {
    return () => setFoldersState(RESET);
  }, []);

  const treeViewApiRef = useTreeViewApiRef();

  const setCustomAlertState = useSetAtom(customAlertStateAtom);
  const queryClient = useQueryClient();

  const [deleteFolderDialogState, setDeleteFolderDialogState] = useState<{
    open: boolean;
    folderId?: string;
  }>({ open: false });

  const deleteFolderMutation = useMutation({
    mutationKey: ["deleteFolderMutation"],
    mutationFn: async () => {
      const folder: ParsedTestFolderItem = treeViewApiRef.current?.getItem(
        deleteFolderDialogState.folderId
      );

      const getTransactionItems = async (
        folderId: string,
        transaction: Transaction
      ): Promise<{ tests: string[]; folders: TestFolder[] }> => {
        const folderSnapshot = await transaction.get(
          doc(customFirestore, "folders", folderId)
        );

        if (folderSnapshot.exists()) {
          const folder = {
            ...(folderSnapshot.data() as TestFolder),
            id: folderSnapshot.id,
          };
          const nestedTransactionItems = await Promise.all(
            folder.subFolders?.map((subFolderId) =>
              getTransactionItems(subFolderId, transaction)
            ) ?? []
          );
          const nestedTests = nestedTransactionItems
            ?.map((transactionItem) => transactionItem.tests)
            .flat();
          const nestedFolders = nestedTransactionItems
            ?.map((transactionItem) => transactionItem.folders)
            .flat();

          return {
            tests: [
              ...(folder.tests?.map((test) => test.id) ?? []),
              ...nestedTests,
            ],
            folders: [folder, ...nestedFolders],
          };
        }

        return {
          tests: [],
          folders: [],
        };
      };

      const transactionItems = await runTransaction(
        customFirestore,
        async (transaction) => {
          const transactionItems = await getTransactionItems(
            folder.id,
            transaction
          );

          transactionItems.tests.forEach((testId) => {
            transaction.update(doc(customFirestore, "custom-tests", testId), {
              folderId: deleteField(),
            });
          });
          transactionItems.folders.forEach((folder, index) => {
            if (index == 0 && folder.parentFolderId != null) {
              transaction.update(
                doc(customFirestore, "folders", folder.parentFolderId),
                {
                  subFolders: arrayRemove(folder.id),
                }
              );
            }
            transaction.delete(doc(customFirestore, "folders", folder.id));
          });
          return transactionItems;
        }
      );

      getTestsAtom.remove({
        organizationId: user.companyId,
        folderId: kAllTestsId,
      });
      transactionItems.folders.forEach((folder, index) => {
        if (index == 0 && folder.parentFolderId != null) {
          getTestsAtom.remove({
            organizationId: user.companyId,
            folderId: folder.parentFolderId,
          });
        }
        getTestsAtom.remove({
          organizationId: user.companyId,
          folderId: folder.id,
        });
      });
      await queryClient.invalidateQueries({
        queryKey: ["getFolders"],
      });

      setCustomAlertState({
        open: true,
        type: "success",
        title: "Folder has been removed",
      });

      setDeleteFolderDialogState({ open: false });
    },
    onError: (error) => {
      console.log(error);
      trackSentryException(error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: "An error occurred while removing folder",
      });
    },
  });

  return (
    <>
      <EditFolderDialog />
      <AddFolderDialog />
      <AsyncConfirmationModal
        title={"Delete Folder"}
        description={`Files within this folder will be moved to the "Uncategorized" folder. Do you want to proceed with deleting the folder?`}
        actionTitle={"Delete"}
        backTitle={"Cancel"}
        open={deleteFolderDialogState.open}
        actionHandler={deleteFolderMutation.mutateAsync}
        actionIcon={<Delete />}
        closeHandler={() => {
          setDeleteFolderDialogState({ open: false, folderId: undefined });
        }}
        submitting={deleteFolderMutation.isPending}
      />
      {data != null && (
        <FolderListWrapper expandFolders={expandFolders}>
          <RichTreeView
            apiRef={treeViewApiRef}
            items={data}
            sx={{
              height: "fit-content",
              flexGrow: 1,
              maxWidth: 400,
              overflowY: "auto",
              ul: {
                paddingInlineStart: "32px",
              },
            }}
            onSelectedItemsChange={(event, itemId) => {
              const item = treeViewApiRef.current?.getItem(itemId);
              setFoldersState((prev) => ({
                ...prev,
                selectedFolderId:
                  item.type === "test" ? item.parentFolderId! : item.id,
                selectedTestId: item.type === "test" ? item.id : undefined,
              }));
            }}
            defaultSelectedItems={data.at(0)!.id}
            slots={{ item: CustomTreeItem }}
            slotProps={{
              item: {
                setDeleteFolderDialogState,
              },
            }}
          />
          <AddFolderButton
            variant="text"
            size={"small"}
            startIcon={<CreateNewFolder />}
            onClick={() => {
              setAddFolderState({ open: true });
            }}
          >
            Add Folder
          </AddFolderButton>
        </FolderListWrapper>
      )}
    </>
  );
};

const AddFolderButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  fontWeight: 400,
  marginLeft: "1px",
  ".MuiButton-startIcon>*:nth-of-type(1)": {
    fontSize: "19.2px ",
  },
}));

const FolderListWrapper = styled.div(
  ({ expandFolders }: { expandFolders: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    opacity: expandFolders ? 1 : 0,
    transition: "opacity 100ms",
    transitionDelay: !expandFolders ? "0ms" : "200ms",
  })
);

const foldersStateAtom = atomWithReset<{
  selectedFolderId?: string;
  selectedTestId?: string;
  folders?: ParsedTestFolderItem[];
}>({
  selectedFolderId: kAllTestsId,
  folders: [],
});
export { foldersStateAtom, kAllTestsId };
export default TestsTreeView;
