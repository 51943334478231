import { useLoaderData, useParams } from "react-router-dom";
import { useAtomValue, useSetAtom } from "jotai";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import {
  BuildData,
  type LoaderApp,
  type Test,
  UnparsedTest,
  Dependency,
  SessionConfigData,
  CompanyData,
} from "../utils/types";
import styled from "@emotion/styled";

import Simulator from "../components/simulator";
import { testEditorPropertiesAtom } from "../atoms/test-editor-atom";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import {
  customFirestore,
  customStorage,
  tipTapCollabJWTGenerator,
} from "../firebase";
import { atomWithReset, RESET } from "jotai/utils";
import {
  bufferToBase64,
  getTipTapExtensions,
  getTipTapSchema,
  trackSentryException,
} from "../utils/helpers";

import { appetizeClientAtom, buildLogsAtomWithId, sessionAtom } from "../atoms";
import { DeepLinkDialog } from "../components/deeplinkDialog";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import { getDownloadURL, ref } from "firebase/storage";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CropScreenshotDialog from "../components/cropScreenshotDialog";
import { Autorenew, CameraAlt, Storage } from "@mui/icons-material";
import { Node } from "prosemirror-model";
import TestEditorGpt from "../components/test-editor-gpt/test_editor_gpt";
import { generateJSON } from "@tiptap/react";
import { Alert, CircularProgress, Tooltip } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import { useAtom } from "jotai";
import { companyDataAtom } from "./test-run/test-run-atoms";
import { kAndroidOsName } from "../constants/appetize-constants";
import buildDataAtom from "../atoms/buildDataAtom";
import { DeviceProvider } from "./sessions/DeviceContext";
import DeviceWrapperAspectRatio from "./sessions/DeviceWrapperAspectRatio";
import InteractableDevice from "./sessions/InteractableDevice";
import { AuthContext } from "../auth/AuthContext";
import { customAlertStateAtom } from "../components/custom-alert";

const TestBuilderPage = () => {
  const { buildId } = useParams();
  const { testId } = useParams();
  const setTestEditorProperties = useSetAtom(testEditorPropertiesAtom);
  const setSession = useSetAtom(sessionAtom);
  const queryClient = useQueryClient();
  const { user } = useContext<any>(AuthContext);
  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  useEffect(() => {
    return () => {
      setSession(null);
      setTestEditorProperties(RESET);
      buildLogsAtomWithId.remove(buildId);
      queryClient.removeQueries({ queryKey: ["deviceScreen"] });
    };
  }, [user]);

  const {
    data: buildData,
    isLoading: isLoadingBuild,
    isError: isErrorBuild,
    error: errorBuild,
  } = useAtomValue(
    buildDataAtom({ buildId: buildId, organizationId: user.companyId })
  );

  useEffect(() => {
    if (buildData != null && buildId != buildData?.id) {
      const description =
        buildId == null
          ? "Since you haven't selected a build, we picked the last uploaded build for you"
          : "Since the build you selected doesn't exist, we picked the last uploaded build for you";
      const title =
        buildId == null ? "No build selected" : "Build doesn't exist";
      setCustomAlertState({
        open: true,
        type: "info",
        title,
        description,
        maintainDisplayed: true,
      });
    }
  }, [buildId, buildData]);

  const {
    data: companyData,
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
    error: errorCompany,
  } = useAtomValue(companyDataAtom(buildData?.organisationId));

  const {
    isLoading: isLoadingTest,
    data: testData,
    isError: isErrorTest,
    error: errorTest,
  } = useQuery<Test | null>({
    queryKey: ["getTestForTestEditor", testId!],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    gcTime: 0,
    enabled: companyData != null && buildData != null,
    queryFn: async ({ queryKey: [, id] }): Promise<Test | null> => {
      if (testId != null) {
        const docRef = doc(customFirestore, "custom-tests", testId!);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const unparsedTest: UnparsedTest = {
            ...(docSnap.data() as UnparsedTest),
            id: docSnap.id,
          };

          const parsedSettings = {
            launchParams: unparsedTest.settings?.launchParams ?? [],
            preRequests:
              unparsedTest.settings?.preRequests?.map((preRequest) => ({
                ...preRequest,
                delayBeforeRequest: preRequest.delayBeforeRequest ?? 0,
              })) ?? [],
            inputKeys: unparsedTest.settings?.inputKeys ?? [],
          };

          let parsedEnrichedInteractionsLogs = {
            promptGenerationScreenshots: {},
            enrichedInteractionLogs: {},
          } as {
            promptGenerationScreenshots: { [index: string]: string[] };
            enrichedInteractionLogs: {
              [index: string]: {
                enrichedLog: string;
                rawLog: string;
              }[];
            };
          };

          if (unparsedTest.enrichedInteractionsLogsPath != null) {
            const downloadUrl = await getDownloadURL(
              ref(customStorage, unparsedTest.enrichedInteractionsLogsPath)
            );

            const response = await axios.get(downloadUrl);
            const unparsedResponse = response.data as {
              [index: string]: {
                screenshot: string;
                screenshotUrl?: string;
                enrichedLog: string;
                rawLog: string;
              }[];
            };

            const startTime = Date.now();
            parsedEnrichedInteractionsLogs = await Object.keys(
              unparsedResponse
            ).reduce(async (prev, current) => {
              const parsedIndex = parseInt(current);
              const awaitedPrev = await prev;
              return {
                promptGenerationScreenshots: {
                  ...awaitedPrev.promptGenerationScreenshots,
                  [parsedIndex]: await Promise.all(
                    unparsedResponse[current].map(async (enrichedLog) => {
                      if (enrichedLog.screenshotUrl != null) {
                        return enrichedLog.screenshotUrl;
                      }

                      return await getDownloadURL(
                        ref(customStorage, enrichedLog.screenshot)
                      );
                    })
                  ),
                },
                enrichedInteractionLogs: {
                  ...awaitedPrev.enrichedInteractionLogs,
                  [parsedIndex]: unparsedResponse[current].map(
                    (enrichedLog) => ({
                      enrichedLog: enrichedLog.enrichedLog,
                      rawLog: enrichedLog.rawLog,
                    })
                  ),
                },
              };
            }, Promise.resolve({ promptGenerationScreenshots: {}, enrichedInteractionLogs: {} }));
            console.log(
              `Finished awaiting. Waiting took`,
              (Date.now() - startTime) / 1000
            );
          }

          const subCollectionRef = collection(docRef, "dependencies");
          const subCollectionSnapshot = await getDocs(subCollectionRef);
          const parsedDependencies = subCollectionSnapshot.docs.map<Dependency>(
            (doc) => {
              return { ...(doc.data() as Dependency), id: doc.id };
            }
          ) as Dependency[];

          let prosemirrorContent: Node;
          const testCommands = unparsedTest.commands;

          const tipTapSchema = getTipTapSchema();
          try {
            prosemirrorContent = Node.fromJSON(
              tipTapSchema,
              JSON.parse(testCommands)
            );
          } catch (e) {
            trackSentryException(e);
            prosemirrorContent = Node.fromJSON(
              tipTapSchema,
              generateJSON(
                `<p>${testCommands.replaceAll("\n", "<br/>")}</p>`,
                getTipTapExtensions()
              )
            );
          }
          setTestEditorProperties((prev) => ({
            ...prev,
            originalTestCommands: prosemirrorContent,
            testCommands: prosemirrorContent,
            originalRecordingTitle: unparsedTest.title,
            recordingTitle: unparsedTest.title,
          }));

          return {
            id: unparsedTest.id,
            title: unparsedTest.title,
            commands: unparsedTest.commands,
            organisationId: unparsedTest.organisationId,
            dependencyInputValues: unparsedTest.dependencyInputValues,
            enrichedInteractionsLogsPath:
              unparsedTest.enrichedInteractionsLogsPath,
            folderId: unparsedTest.folderId,
            cachedSteps: unparsedTest.cachedSteps,
            enrichedInteractionsLogs:
              parsedEnrichedInteractionsLogs.enrichedInteractionLogs,
            promptGenerationScreenshots:
              parsedEnrichedInteractionsLogs.promptGenerationScreenshots,
            settings: parsedSettings,
            dependencies: parsedDependencies,
          };
        } else {
          throw new Error("Test doesn't exist");
        }
      }
      return null;
    },
  });

  const tipTapJWTQuery = useQuery({
    queryKey: ["tip-tap-jwt"],
    queryFn: async () => {
      const response = await tipTapCollabJWTGenerator();
      return response.data;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    gcTime: 300000,
  });

  const isLoadingSimulator = useMemo(() => {
    return isLoadingBuild || isLoadingTest || isLoadingCompany;
  }, [isLoadingBuild, isLoadingTest, isLoadingCompany]);

  const isErrorSimulator = useMemo(() => {
    return isErrorTest || isErrorBuild || isErrorCompany;
  }, [isErrorTest, isErrorBuild, isErrorCompany]);

  const sessionConfig = useMemo<SessionConfigData | undefined>(() => {
    if (companyData == null || buildData == null) {
      return undefined;
    }

    const platform = buildData!.platform!.toLocaleLowerCase();
    return {
      platform,
      os:
        platform === kAndroidOsName.toLowerCase()
          ? companyData!.settings.defaultSessionConfig.osAndroid
          : companyData!.settings.defaultSessionConfig.osIos,
      device:
        platform === kAndroidOsName.toLowerCase()
          ? companyData!.settings.defaultSessionConfig.deviceAndroid
          : companyData!.settings.defaultSessionConfig.deviceIos,
      language: companyData!.settings.defaultSessionConfig.language,
      orientation: companyData!.settings.defaultSessionConfig.orientation,
      region: companyData!.settings.defaultSessionConfig.region,
    };
  }, [companyData, buildData]);

  return (
    <ContentWrapper>
      <SimulatorSection>
        <TestBuilderCustomAlert />
        {isLoadingSimulator && (
          <SpinnerWrapper>
            <CircularProgress />
          </SpinnerWrapper>
        )}

        {isErrorSimulator && (
          <p>
            Error:{" "}
            {errorBuild?.message ?? errorCompany?.message ?? errorTest?.message}
          </p>
        )}

        {/* if loading is finished, no error occured, and data is available display the test emulator */}
        {!isLoadingSimulator &&
          !isErrorSimulator &&
          buildData != null &&
          companyData != null && (
            <DeviceProvider settingsData={companyData.settings}>
              <SimulatorWrapper
                test={testData}
                buildData={buildData}
                companyData={companyData}
                sessionConfig={sessionConfig}
              />
            </DeviceProvider>
          )}
        <Overlay id="overlay">
          <ScanningLine />
        </Overlay>
      </SimulatorSection>

      <EditorSection>
        {!isLoadingSimulator &&
          !isErrorSimulator &&
          buildData != null &&
          companyData != null &&
          tipTapJWTQuery.data != null && (
            <TestEditorGpt
              test={testData}
              tipTapJWTData={tipTapJWTQuery.data!}
              buildData={buildData}
              companyData={companyData}
              sessionConfig={sessionConfig!}
            />
          )}
      </EditorSection>
    </ContentWrapper>
  );
};

const SimulatorWrapper = ({
  test,
  buildData,
  companyData,
  sessionConfig,
}: {
  test: Test;
  buildData: BuildData;
  companyData: CompanyData;
  sessionConfig?: SessionConfigData;
}) => {
  const session = useAtomValue(sessionAtom);
  const [savingScreenshot, setSavingScreenshot] = useState(false);
  const [openCropScreenshotDialog, setOpenCropScreenshotDialog] =
    useState(false);

  const [cropScreenshot, setCropScreenshot] = useState<string>("");
  const loaderData = useLoaderData() as LoaderApp;
  const buildLogsData = useAtomValue(loaderData.buildLogsData);
  const videoFrames = buildLogsData.videoFrames;

  const saveScreenshot = useCallback(async () => {
    setOpenCropScreenshotDialog(true);
    const lastVideoFrame = videoFrames
      .filter((value) => value?.relativeTimestamp < Date.now())
      .at(-1);
    const lastScreenshot = `data:image/jpeg;base64,${bufferToBase64(
      lastVideoFrame?.buffer
    )}`;
    setCropScreenshot(lastScreenshot);
  }, [session, videoFrames]);

  const rotateHandler = useCallback(() => {
    session.data?.rotate("left");
  }, [session]);

  const [uiInspectorEnabled, setUiInspectorEnabled] = useState(false);

  const [openResetDialog, setOpenResetDialog] = useState(false);
  const handleOpenResetDialog = useCallback(() => setOpenResetDialog(true), []);

  const appResetHandler = useCallback(() => {
    setOpenResetDialog(false);
    session.data?.restartApp();
  }, [session]);

  const appetizeClient = useAtomValue(appetizeClientAtom);

  const sessionResetHandler = useCallback(async () => {
    setOpenResetDialog(false);
    await session.data?.end();
    await appetizeClient?.startSession();
  }, [session, appetizeClient]);

  const [activeSessionRunning, setActiveSessionRunning] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    appetizeClient?.on("session", (session: any) => {
      setActiveSessionRunning(true);
    });
    session?.data?.on("end", (event: any) => {
      queryClient.invalidateQueries({ queryKey: ["deviceScreen"] });
      setActiveSessionRunning(false);
      setUiInspectorEnabled(false);
    });
  }, [session, appetizeClient]);

  return (
    <>
      {companyData != null && (
        <>
          <CropScreenshotDialog
            open={openCropScreenshotDialog}
            setOpen={setOpenCropScreenshotDialog}
            imageSrc={cropScreenshot}
            companyData={companyData}
            deviceName={sessionConfig?.device ?? "--"}
          />
          <ResetDialog
            open={openResetDialog}
            setOpen={setOpenResetDialog}
            sessionResetHandler={sessionResetHandler}
            appResetHandler={appResetHandler}
          />
          <DeviceWrapperAspectRatio activeSessionRunning={activeSessionRunning}>
            <InteractableDevice
              enableGestureControl={uiInspectorEnabled}
              setEnableGestureControl={setUiInspectorEnabled}
              activeSessionRunning={activeSessionRunning}
            >
              <Simulator
                company={companyData}
                sessionConfig={sessionConfig!}
                buildId={buildData.id}
                appetizePublicKey={buildData.pubKey!}
                params={
                  test?.settings?.launchParams?.reduce(
                    (prev, current) => ({
                      ...prev,
                      [current.key]: current.value,
                    }),
                    {}
                  ) ?? {}
                }
              />
            </InteractableDevice>
          </DeviceWrapperAspectRatio>
          <ActionsWrapper>
            <Tooltip title={"Create a template"} placement="left">
              <span>
                <ActionButton
                  onClick={saveScreenshot}
                  disabled={!activeSessionRunning}
                >
                  {savingScreenshot ? <CircularProgress /> : <CameraAlt />}
                </ActionButton>
              </span>
            </Tooltip>
            <DeepLinkDialog
              activeSessionRunning={activeSessionRunning}
              session={session}
            />
            <Tooltip title={"Rotate device"} placement="left">
              <span>
                <ActionButton
                  onClick={rotateHandler}
                  disabled={!activeSessionRunning}
                >
                  <ScreenRotationIcon />
                </ActionButton>
              </span>
            </Tooltip>
            <Tooltip title={"Show restart options"} placement="left">
              <span>
                <ActionButton
                  onClick={handleOpenResetDialog}
                  disabled={!activeSessionRunning}
                >
                  <Autorenew />
                </ActionButton>
              </span>
            </Tooltip>
            {companyData.settings.displayNetworkLogsOnEditor && (
              <Tooltip title={"Display network logs"} placement="left">
                <ActionButton
                  disabled={!activeSessionRunning}
                  onClick={() => {
                    window.open(session?.data?.networkInspectorUrl, "_blank");
                  }}
                >
                  <Storage />
                </ActionButton>
              </Tooltip>
            )}
            {companyData.settings.useAppetizeCommands && (
              <Tooltip title={"Display UI inspector"} placement="left">
                <span>
                  <ActionButton
                    onClick={() => {
                      setUiInspectorEnabled((prevState) => !prevState);
                    }}
                    disabled={!activeSessionRunning}
                  >
                    <LocationSearchingIcon
                      color={uiInspectorEnabled ? "success" : "inherit"}
                    />
                  </ActionButton>
                </span>
              </Tooltip>
            )}
          </ActionsWrapper>
        </>
      )}
    </>
  );
};

const HoverOverlay = styled.div(
  ({ width, height }: { width: number; height: number }) => {
    return {
      width: width + "px",
      height: height + "px",
      position: "absolute",
      marginInline: "auto",
      left: "50%",
      transform: "translate(-50%, 0)",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    };
  }
);

const ContentWrapper = styled.div`
  display: grid;
  grid-template-areas: "simulator editor";
  grid-template-columns: 45% 55%;
  background-color: #e8e8e8;
  height: 100%;
`;

const SimulatorSection = styled.div`
  grid-area: simulator;
  position: relative;
  padding-block: 32px;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ActionButton = styled.button`
  color: white;
  padding: 16px;
  border-radius: 9999px;
  background-color: #4c63ff;

  &:disabled {
    color: #455a64;
    cursor: not-allowed;
    background-color: #bdbdbd;
  }

  &:hover {
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }
`;

const EditorSection = styled.div`
  grid-area: editor;
  background-color: #313131;
  padding: 16px;
  overflow: auto;
`;

const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  display: none;
`;

const ScanningLine = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background-color: #22c55e; /* green-500 */
  @keyframes scanning {
    0% {
      top: 0;
    }
    50% {
      top: 100%;
    }
    100% {
      top: 0;
    }
  }
  animation: scanning 5s linear forwards;
`;

const ResetDialog = ({
  open,
  setOpen,
  sessionResetHandler,
  appResetHandler,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  sessionResetHandler: any;
  appResetHandler: any;
}) => {
  const handleOpen = useCallback(() => setOpen((prev) => !prev), [setOpen]);

  return (
    <Dialog size={"xs"} open={open} handler={handleOpen}>
      <DialogHeader className={"justify-between"}>
        Restart option
        <IconButton
          color="blue-gray"
          size="sm"
          variant="text"
          onClick={handleOpen}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody>Do you want to restart the emulator or the app?</DialogBody>
      <DialogFooter className={"flex flex-row gap-2"}>
        <Button
          variant="text"
          className={
            "text-[#4C63FF] ring-2 ring-[#4C63FF] bg-white flex flex-row gap-2 items-center"
          }
          onClick={sessionResetHandler}
        >
          Restart Emulator
        </Button>
        <Button
          variant="filled"
          className={"flex bg-[#4C63FF] items-center gap-3"}
          onClick={appResetHandler}
        >
          Restart App
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

const TestBuilderCustomAlert = () => {
  const [state, setState] = useAtom(testBuilderCustomAlertStateAtom);
  const closeHandler = useCallback(() => {
    setState((prev) => ({ ...prev, open: false }));
  }, []);

  // Automatically close after 3 seconds if autoclose is true
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (state.open && state.autoclose) {
      timer = setTimeout(() => {
        closeHandler();
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [state.open, state.autoclose, closeHandler]);

  useEffect(() => {
    return () => {
      setState({ open: false });
    };
  }, []);

  return (
    <div
      className={`absolute z-20 pt-2 px-4 w-full ${
        state.open ? "visible" : "invisible"
      }`}
    >
      <Alert severity={state.type} onClose={closeHandler}>
        <AlertTitle>{state.title !== undefined && state.title}</AlertTitle>
        {state.description !== undefined && state.description}
        {state.listContent !== undefined && state.listContent.length > 0 && (
          <ul className="mt-2 ml-2 list-disc list-inside">
            {state.listContent.map((listItem, index) => (
              <li key={index}>{listItem}</li>
            ))}
          </ul>
        )}
      </Alert>
    </div>
  );
};

const testBuilderCustomAlertStateAtom = atomWithReset<{
  type?: "error" | "info" | "success" | "warning";
  title?: string;
  description?: string;
  listContent?: string[];
  open: boolean;
  autoclose: boolean;
}>({
  open: false,
  autoclose: false,
});

export {
  TestBuilderPage,
  TestBuilderCustomAlert,
  testBuilderCustomAlertStateAtom,
};
