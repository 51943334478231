import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { atomWithReset } from "jotai/utils";
import { useAtom, useSetAtom } from "jotai";
import React, { useCallback, useEffect, useState } from "react";
import { Close, Edit } from "@mui/icons-material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { doc } from "firebase/firestore";
import { customFirestore } from "../../firebase";
import { updateDoc } from "@firebase/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import { customAlertStateAtom } from "../../components/custom-alert";
import TextField from "@mui/material/TextField";
import { trackSentryException } from "../../utils/helpers";
import { ParsedTestFolderItem } from "../../utils/types";

const EditFolderDialog = () => {
  const [editFolderState, setEditFolderState] = useAtom(editFolderStateAtom);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(
      editFolderState.open && editFolderState.folder
        ? editFolderState.folder!.label
        : ""
    );
  }, [editFolderState]);

  const handleClose = useCallback((event: {}, reason: string) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return;
    setEditFolderState({ open: false, folder: undefined });
  }, []);

  const setCustomAlertState = useSetAtom(customAlertStateAtom);
  const queryClient = useQueryClient();

  const editFolderMutation = useMutation({
    mutationKey: ["addFolderMutation"],
    mutationFn: async () => {
      const folderDoc = doc(
        customFirestore,
        "folders",
        editFolderState.folder!.id
      );

      await updateDoc(folderDoc, { name: title });

      await queryClient.invalidateQueries({
        queryKey: ["tests-folders-query"],
      });
      setCustomAlertState({
        open: true,
        type: "success",
        title: "Folder edited successfully",
      });
      setEditFolderState({ open: false, folder: undefined });
    },
    onError: (error) => {
      console.log(error);
      trackSentryException(error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: "An error occurred while editing folder",
      });
    },
  });

  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth={true}
      open={editFolderState.open}
      onClose={handleClose}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ paddingRight: "8px", paddingTop: "10px" }}
      >
        <Box display="flex" alignItems="top">
          <Box flexGrow={1} paddingRight={"6px"} paddingTop={"4px"}>
            Edit Folder
          </Box>
          <Box>
            <Tooltip title={"Close"}>
              <span>
                <IconButton
                  disabled={editFolderMutation.isPending}
                  onClick={() => handleClose({}, "closeIconButtonClose")}
                >
                  <Close />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={"flex flex-col gap-3.5"}>
        <TextField
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          autoFocus
          label={"Folder name"}
          value={title}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={editFolderMutation.isPending}
          onClick={() => editFolderMutation.mutate()}
          loadingPosition="start"
          startIcon={<Edit />}
          variant="contained"
        >
          Edit Folder
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const editFolderStateAtom = atomWithReset<{
  folder?: ParsedTestFolderItem;
  open: boolean;
}>({ open: false });

export default EditFolderDialog;
export { editFolderStateAtom };
