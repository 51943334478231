import React, {  useEffect } from "react";
import { firebaseAuth, uiConfig } from "../firebase";
import "firebaseui/dist/firebaseui.css";
import { auth } from "firebaseui";
import gptDriverLogoWhite from "../assets/images/gpt-driver-logo-white.png";

const RenderLoginUI = () => {
  useEffect(() => {
    if (document.getElementById("firebaseui-auth-container") != null) {
      console.log("Setting ui");
      const ui = auth.AuthUI.getInstance() ?? new auth.AuthUI(firebaseAuth);
      // @ts-expect-error
      ui.start("#firebaseui-auth-container", uiConfig);
    }
  }, []);

  return (
    <>
      <div className="h-full w-full flex bg-white">
        <div className="flex flex-initial flex-col w-[400px] justify-center bg-[#4C63FF] text-white p-8">
          <img
            src={gptDriverLogoWhite}
            alt="GPT Driver"
            className="w-32 mb-10"
          />
          <h1 className="mb-7 font-semibold text-3xl leading-relaxed">
            Boost productivity
            <br />
            with AI doing
            <br />
            mobile app QA
          </h1>
          <p className="leading-7">
            Test more of your app, with greater accuracy, across more devices
            <br /> at a fraction of the cost.
          </p>
        </div>
        <div className="flex-auto flex flex-col justify-center text-center">
          <h2 className="font-semibold text-2xl text-black mb-4">
            Sign in to GPT Driver
          </h2>
          <div id="firebaseui-auth-container"></div>
        </div>
      </div>
    </>
  );
};


export { RenderLoginUI };
