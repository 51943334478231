import DialogWindow from "./dialogWindow";
import React from "react";

interface RemoveButtonProps {
  className?: string
  dialogTitle: string,
  buttonTitle: string,
  handleSubmit: any,
  innerButtonClassname: string,
  outerButtonClassname: string,
  children?: React.ReactNode
}

const RemoveButton = ({ className, dialogTitle, buttonTitle, handleSubmit, innerButtonClassname, outerButtonClassname, children }: RemoveButtonProps) => {
  return (
    <DialogWindow
      className={className}
      dialogTitle={dialogTitle}
      innerButtonClassname={innerButtonClassname}
      outerButtonClassname={outerButtonClassname}
      buttonTitle={buttonTitle}
      handleConfirm={handleSubmit}
      buttonChildren={children}
    />
  )
}

export default RemoveButton