import { Switch, FormControlLabel } from "@mui/material";
import { useField } from "formik";

const FormikSwitch = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormControlLabel
      {...field}
      {...props}
      control={<Switch />}
      label={label}
    />
  );
};

export default FormikSwitch;
