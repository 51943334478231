import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#4C63FF",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#FF4C4C",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#17BD27",
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export { lightTheme, darkTheme };
