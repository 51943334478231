import TouchApp from "@mui/icons-material/TouchApp";
import {
  Abc,
  Backspace,
  CalendarToday,
  Casino,
  Check,
  CropTwoTone,
  DataObject,
  FindInPage,
  HourglassBottom,
  KeyboardReturn,
  Launch,
  Link,
  RestartAlt,
  SwipeDown,
  SwipeLeft,
  SwipeUp,
} from "@mui/icons-material";
import React from "react";
import { type TemplateWithLabel } from "../utils/types";

const TIPTAP_COMMANDS_SUGGESTIONS = [
  {
    title: "Tap on with AI",
    autocompleteTemplate: "Tap on <element>",
    alternatives: ["tap", "click"],
    icon: <TouchApp />,
  },
  {
    title: "Tap on id",
    autocompleteTemplate: 'tapOn.id: "<element id>"',
    alternatives: ["tap", "click"],
    icon: <TouchApp />,
  },
  {
    title: "Tap on text",
    autocompleteTemplate: 'tapOn: "<element>"',
    alternatives: ["tap", "click"],
    icon: <TouchApp />,
  },
  {
    title: "Type text",
    autocompleteTemplate: 'type: "<text>"',
    alternatives: ["type", "write"],
    icon: <Abc />,
  },
  {
    title: "Scroll",
    autocompleteTemplate: "scroll: <direction>",
    alternatives: ["scroll", "swipe", "slide"],
    icon: <SwipeUp />,
  },
  {
    title: "Scroll up until id visible",
    autocompleteTemplate: 'scrollUpUntilVisible.id: "<element id>"',
    alternatives: ["scroll", "swipe", "slide"],
    icon: <SwipeUp />,
  },
  {
    title: "Scroll down until id visible",
    autocompleteTemplate: 'scrollDownUntilVisible.id: "<element id>"',
    alternatives: ["scroll", "swipe", "slide"],
    icon: <SwipeDown />,
  },
  {
    title: "Scroll up until text visible",
    autocompleteTemplate: 'scrollUpUntilVisible.text: "<text>"',
    alternatives: ["scroll", "swipe", "slide"],
    icon: <SwipeUp />,
  },
  {
    title: "Scroll down until text visible",
    autocompleteTemplate: 'scrollDownUntilVisible.text: "<text>"',
    alternatives: ["scroll", "swipe", "slide"],
    icon: <SwipeDown />,
  },
  {
    title: "Swipe",
    autocompleteTemplate: "swipe: <direction>",
    alternatives: ["swipe", "scroll", "slide"],
    icon: <SwipeLeft />,
  },
  {
    title: "Swipe on element (slide)",
    autocompleteTemplate: "slide: <direction> <percentage>; <xPos>; <yPos>",
    alternatives: ["swipe", "slide", "scroll"],
    icon: <SwipeLeft />,
  },
  {
    title: "Check",
    autocompleteTemplate:
      "Check if you see <text/element>, if so continue with next step",
    alternatives: ["check", "verify", "assert"],
    icon: <Check />,
  },
  {
    title: "Open deeplink",
    autocompleteTemplate: 'openLink: "<url>"',
    alternatives: ["open", "deeplink"],
    icon: <Link />,
  },
  {
    title: "Wait",
    autocompleteTemplate: "wait: <number>",
    alternatives: ["wait", "sleep"],
    icon: <HourglassBottom />,
  },
  {
    title: "Assert visible",
    autocompleteTemplate: 'assertVisible.id: "<element id>"',
    alternatives: ["assert", "visible"],
    icon: <FindInPage />,
  },
  {
    title: "Press back button",
    autocompleteTemplate: "pressBackButton",
    alternatives: ["press", "back", "return"],
    icon: <KeyboardReturn />,
  },
  {
    title: "Launch app",
    autocompleteTemplate: 'launchApp: "<bundle>"',
    alternatives: ["launch", "app", "start"],
    icon: <Launch />,
  },
  {
    title: "Restart app",
    autocompleteTemplate: "restartApp",
    alternatives: ["restart", "app", "reset"],
    icon: <RestartAlt />,
  },
  {
    title: "Remove text",
    autocompleteTemplate: 'removeText: "<text>"',
    alternatives: ["remove", "erase", "delete"],
    icon: <Backspace />,
  },
];

const tiptapDoubleCurlySuggestions = (acceptedDynamicValues: string[]) => [
  {
    title: "Date",
    autocompleteTemplate: "{{currentDate}}",
    alternatives: ["date", "day"],
    icon: <CalendarToday />,
  },
  {
    title: "Date formatted",
    autocompleteTemplate: "{{currentDateFormatted}}",
    alternatives: ["date", "day"],
    icon: <CalendarToday />,
  },
  {
    title: "Timestamp",
    autocompleteTemplate: "{{timestamp}}",
    alternatives: ["timestamp"],
    icon: <Abc />,
  },
  {
    title: "Random number with x-digits",
    autocompleteTemplate: "{{randomNumber(<x>)}}",
    alternatives: ["random", "number", "randomNumber"],
    icon: <Casino />,
  },
  ...acceptedDynamicValues
    .filter(
      (value) =>
        !["timestamp", "currentDate", "currentDateFormatted"].includes(value)
    )
    .map((envVar) => ({
      title: `Env var <${envVar}>`,
      autocompleteTemplate: `{{${envVar}}}`,
      alternatives: [envVar],
      icon: <DataObject />,
    })),
];

const tiptapTemplatesSuggestions = (templates: TemplateWithLabel[]) => [
  ...templates.map((template) => ({
    title: `${template.label}`,
    autocompleteTemplate: template.label,
    alternatives: [template.label],
    icon: <CropTwoTone />,
    imageUrl: template.imageUrl,
  })),
];

export {
  TIPTAP_COMMANDS_SUGGESTIONS,
  tiptapDoubleCurlySuggestions,
  tiptapTemplatesSuggestions,
};
