import { Feedback, FieldWithKey, JiraAuthInputs, JiraIssuePayload, JiraProjectMetadata, JiraUserInfo } from "./types";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { customFirestore } from "../firebase";

const HOSTNAME = "https://admin.flutterboost.com"

const getJiraUsers = async (data: JiraAuthInputs): Promise<JiraUserInfo[]> => {
  const response = await fetch(`${HOSTNAME}/api/jira/users`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  const users: { data: JiraUserInfo[] } = await response.json()
  if (users?.data) {
    return users.data.filter((row) => row.accountType !== 'app')
  }

  return []
}

const getJiraProjectMetadata = async (data: JiraAuthInputs): Promise<JiraProjectMetadata[] | undefined> => {
  const response = await fetch(`${HOSTNAME}/api/jira/metadata`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })

  const responseData = await response.json()

  if (responseData && responseData?.data?.projects) {
    return responseData.data.projects
  }

  return undefined
}

const postJiraIssue = async (authData: JiraAuthInputs, payload: { fields: any, update: any }) => {
  return await fetch(`${HOSTNAME}/api/jira/create-issue`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ auth: authData, payload: payload }),
  });
}


const prepareJiraIssuePayload = (requiredFields: FieldWithKey[], payload: JiraIssuePayload) => {
  function plain_text_to_adf(text: string): object {
    return {
      "version": 1,
      "type": "doc",
      "content": [
        {
          "type": "paragraph",
          "content": [
            {
              "type": "text",
              "text": text
            }
          ]
        }
      ]
    };
  }

  const preparedPayload: { fields: any, update: any } = {
    fields: {
      assignee: {
        id: payload.assignee
      },
      project: {
        id: payload.project
      },
      issuetype: {
        id: payload.issuetype
      },
      summary: payload.summary,
    },
    update: {}
  }
  const fieldsWithoutId = ['Summary', 'Epic Name', 'Description']
  for (const fieldWithKey of requiredFields) {
    let value: any;
    if (fieldsWithoutId.includes(fieldWithKey.field.name)) {
      if (fieldWithKey.field.name === "Description"){
        value = plain_text_to_adf(payload[fieldWithKey.fieldKey] ?? "")
      } else {
        value = payload[fieldWithKey.fieldKey]

      }
    } else {
      value = { [fieldWithKey.field.name === 'Parent' ? "key" : "id"]: payload[fieldWithKey.fieldKey] }
    }
    preparedPayload.fields[fieldWithKey.fieldKey] = value
  }

  return preparedPayload
}

const getJiraIntegrationData = async (companyId: string): Promise<JiraAuthInputs | undefined> => {
  console.log("Checking if Jira integrated...")
  let authData: JiraAuthInputs | undefined = undefined
  const q = await query(collection(customFirestore, "companies"), where("id", "==", companyId));
  const querySnapshot = await getDocs(q)

  if (!querySnapshot.empty) {
    const companyDoc = querySnapshot.docs[0]
    authData = companyDoc.data()?.jira_integration
  } else {
    console.error(`No company with id ${companyId} found!`)
  }

  console.log(`Jira integration ${(authData === undefined) ? "NOT " : ""}found`)
  return authData
}

const getCompanyIdFromUpload = async (uploadId: string): Promise<string | undefined> => {

  const companyDoc = await getDoc(doc(customFirestore, "uploads", uploadId));
  return companyDoc.data()?.organisationId
}

const addJiraIssueLinkToFeedback = async (feedbackId: string, jiraIssueURL: string) => {
  console.log("Adding jira issue link to feedback", feedbackId)
  await updateDoc(doc(customFirestore, "feedbacks", feedbackId), {jiraIssueUrl: jiraIssueURL})
}

const getJiraIssueLinkFromFeedback = async (feedbackId: string) => {
  console.log("Fetching JIRA link from firestore")
  const companyDoc = await getDoc(doc(customFirestore, "feedbacks", feedbackId));
  return (companyDoc.data() as Feedback).jiraIssueUrl
}

export {
  getJiraUsers,
  getJiraProjectMetadata,
  postJiraIssue,
  prepareJiraIssuePayload,
  getJiraIntegrationData,
  addJiraIssueLinkToFeedback,
  getJiraIssueLinkFromFeedback,
  getCompanyIdFromUpload
}