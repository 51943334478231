import { Tooltip } from "@material-tailwind/react";
import { MapPinIcon } from "@heroicons/react/24/solid";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { AuthContext } from "../auth/AuthContext";
import { testDeviceConfigurationsAtom } from "../atoms/test-device-configurations-atoms";
import {
  AppetizeOSVersions,
  type TestDeviceConfiguration,
} from "../utils/types";
import TestDeviceConfigurationDialog from "./test-device-configuration-dialog";
import { deleteDoc, doc } from "firebase/firestore";
import { customFirestore } from "../firebase";
import { toast } from "react-toastify";
import { TOAST_OPTIONS, trackSentryException } from "../utils/helpers";
import { useQueryClient } from "@tanstack/react-query";
import { Add, Delete } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { CircularProgress, IconButton } from "@mui/material";

const TestDeviceConfig = ({
  appetizeOSVersions,
}: {
  appetizeOSVersions: AppetizeOSVersions;
}) => {
  const { user } = useContext<any>(AuthContext);

  const testDeviceConfigurations = useAtomValue(
    testDeviceConfigurationsAtom(user.companyId)
  );

  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);
  return (
    <div className={"flex flex-col rounded-[8px] bg-[#FCFCFC]"}>
      {testDeviceConfigurations.data != null && (
        <div className={"flex justify-end pt-1"}>
          <Button variant={"outlined"} onClick={openDialog} startIcon={<Add />}>
            Add device
          </Button>
        </div>
      )}
      {testDeviceConfigurations.isLoading && (
        <div
          className={"flex flex-col h-full items-center justify-center w-full"}
        >
          <CircularProgress />
        </div>
      )}
      {testDeviceConfigurations.isError && (
        <p>Error: {testDeviceConfigurations.error.message}</p>
      )}

      {testDeviceConfigurations.data != null && (
        <>
          <TestDeviceConfigurationDialog
            open={open}
            setOpen={setOpen}
            appetizeOSVersions={appetizeOSVersions}
          />
          <Success configurations={testDeviceConfigurations.data} />
        </>
      )}
    </div>
  );
};

type DynamicObject = Record<string, []>;

const Success = ({
  configurations,
}: {
  configurations: TestDeviceConfiguration[];
}) => {
  const queryClient = useQueryClient();
  const parsedConfigurations = useMemo(() => {
    return configurations.reduce<DynamicObject>(
      (prev, current) =>
        ({
          ...prev,
          [current.platform]: [
            ...(prev[current.platform] != null ? prev[current.platform] : []),
            current,
          ],
        } as DynamicObject),
      {}
    );
  }, [configurations]) as Record<string, TestDeviceConfiguration[]>;

  const handleDeleteConfig = useCallback(
    async (id: string) => {
      try {
        await deleteDoc(
          doc(customFirestore, "custom-test-device-configurations", id)
        );
        toast.success(`Configuration deleted successfully!`, TOAST_OPTIONS);

        await queryClient.invalidateQueries({
          queryKey: ["getTestDeviceConfigurations"],
        });
      } catch (e) {
        trackSentryException(e);
        toast.error(`An error occurred`, {
          ...TOAST_OPTIONS,
          autoClose: false,
        });
      }
    },
    [queryClient]
  );

  return (
    <>
      {Object.keys(parsedConfigurations).map((key, index) => (
        <div
          className={
            "flex flex-col gap-3 mt-3 p-4 border-solid border-[#0000001f] border overflow-auto rounded h-[180px]"
          }
          key={index}
        >
          {parsedConfigurations[key].map((config, index) => (
            <div className="grid grid-cols-8 text-sm" key={index}>
              <div className="col-span-1 my-auto">
                {key} {config.osVersion}
              </div>
              <div className="col-span-3 my-auto">{config.device}</div>
              <div className="col-span-1 my-auto">{config.locale}</div>
              <div className="col-span-1 my-auto">
                {config.coordinates != null &&
                  !isNaN(config.coordinates.latitude) &&
                  !isNaN(config.coordinates.longitude) && (
                    <Tooltip
                      content={`${config.coordinates.latitude}, ${config.coordinates.longitude}`}
                    >
                      <MapPinIcon
                        className={"h-5 w-5 text-blue-600 my-auto"}
                      ></MapPinIcon>
                    </Tooltip>
                  )}
              </div>
              <div className="col-span-1 my-auto">{config.orientation}</div>
              <div className="col-span-1 flex justify-end items-center">
                <IconButton
                  onClick={async () => {
                    await handleDeleteConfig(config.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default TestDeviceConfig;
