import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6 text-center text-sm mt-8">
      <p>© 2023 Made with ❤️ and a lot of ☕️ <br/>
        C2 Food Ventures Inc.️ backed by Y Combinator<br/>
        <a href="https://www.mobileboost.io/terms" target="_blank">
          T&C
        </a>{" "}
        |{" "}
        <a href="https://www.mobileboost.io/privacy" target="_blank">
          Privacy
        </a>
      </p>
    </footer>
  );
};

export default Footer;
