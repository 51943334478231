import { useLoaderData } from "react-router-dom";
import { useAtomValue } from "jotai";
import React, { useMemo } from "react";
import { SessionConfigData, TestSuiteLoaderApp } from "../../utils/types";
import Simulator from "../../components/simulator";
import TestRunGptEditor from "../../components/test-run-gpt-editor";
import buildDataAtom from "../../atoms/buildDataAtom";
import { companyDataAtom } from "./test-run-atoms";
import { CircularProgress } from "@mui/material";

const TestRunBuilderPage = () => {
  const loaderData = useLoaderData() as TestSuiteLoaderApp;
  const testRunDataQuery = useAtomValue(loaderData.testRunDataAtom);
  const testSuiteDataQuery = useAtomValue(loaderData.testSuiteDataAtom);

  const {
    data: buildData,
    isLoading: isLoadingBuild,
    isError: isErrorBuild,
    error: errorBuild,
  } = useAtomValue(
    buildDataAtom({ buildId: testSuiteDataQuery.data?.uploadId })
  );

  const {
    data: companyData,
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
    error: errorCompany,
  } = useAtomValue(companyDataAtom(buildData?.organisationId));

  const isLoadingSimulator = useMemo(() => {
    return (
      isLoadingBuild ||
      testRunDataQuery.isLoading ||
      testSuiteDataQuery.isLoading ||
      isLoadingCompany
    );
  }, [
    isLoadingBuild,
    testSuiteDataQuery.isLoading,
    testRunDataQuery.isLoading,
    isLoadingCompany,
  ]);

  const isErrorSimulator = useMemo(() => {
    return (
      testRunDataQuery.isError ||
      testSuiteDataQuery.isError ||
      isErrorBuild ||
      isErrorCompany
    );
  }, [
    testRunDataQuery.isError,
    testSuiteDataQuery.isError,
    isErrorBuild,
    isErrorCompany,
  ]);

  const sessionConfig = useMemo<SessionConfigData | undefined>(() => {
    if (testRunDataQuery.data == null || companyData == null) {
      return undefined;
    }

    const parentRun = testRunDataQuery.data.runs.at(-1)!;
    return {
      platform: parentRun.platform,
      os: parentRun.os,
      device: parentRun.device,
      language: parentRun.locale,
      orientation: parentRun.orientation,
      region: companyData?.settings.defaultSessionConfig.region,
    };
  }, [testRunDataQuery.data, companyData]);

  const simulatorParams = useMemo(
    () =>
      testRunDataQuery.data?.runs?.reduce((parentAcc, run) => {
        run.settings?.launchParams?.forEach((param) => {
          parentAcc[param.key] = param.value;
        });

        return parentAcc;
      }, {}) ?? {},
    [testRunDataQuery.data]
  );

  return (
    <div className="flex-1 flex flex-col bg-[#E6E6E6] h-full">
      <div className="flex flex-row flex-1 h-[calc(100vh_-_60px)]">
        <div className="flex order-1 basis-[65%]">
          {isLoadingSimulator && (
            <div
              className={
                "flex flex-col h-full items-center justify-center w-full"
              }
            >
              <CircularProgress />
            </div>
          )}

          {isErrorSimulator && (
            <p>
              Error:{" "}
              {testSuiteDataQuery.error?.message ??
                testRunDataQuery.error?.message ??
                errorBuild?.message ??
                errorCompany?.message}
            </p>
          )}

          {/* if loading is finished, no error occured, and data is available display the test emulator */}
          {!isLoadingSimulator &&
            !isErrorSimulator &&
            companyData != null &&
            testSuiteDataQuery.data != null &&
            testRunDataQuery.data != null && (
              <div className="w-full relative">
                {companyData.settings.enableAppetizeClone ? (
                  <div id="deviceWrapper" />
                ) : (
                  <Simulator
                    buildId={testSuiteDataQuery.data!.uploadId}
                    appetizePublicKey={buildData!.pubKey!}
                    company={companyData!}
                    sessionConfig={sessionConfig!}
                    params={simulatorParams}
                  />
                )}

                <div
                  className="absolute inset-0 bg-black bg-opacity-30 h-full w-full hidden"
                  id="overlay"
                >
                  <div
                    className="absolute left-0 w-full h-0.5 bg-green-500"
                    style={{
                      animation: "scanning 5s linear forwards",
                    }}
                  />
                </div>
                <div className={"absolute"} id={"heartbeat"}></div>
                <style>{`
        @keyframes scanning {
          0% { top: 0; }
          50% { top: 100%; }
          100% { top: 0; }
        }
      `}</style>
              </div>
            )}
        </div>
        <div className="flex flex-col p-4 order-2 basis-[35%] bg-[#313131]">
          {!isLoadingSimulator &&
            !isErrorSimulator &&
            companyData != null &&
            buildData != null &&
            testRunDataQuery.data != null && (
              <TestRunGptEditor
                runs={testRunDataQuery.data.runs}
                buildData={buildData!}
                companyData={companyData!}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export { TestRunBuilderPage };
