import { AppetizeOSVersions } from "../utils/types";

const iosDevices = [
  "iphone15promax",
  "iphone15pro",
  "iphone14promax",
  "iphone14pro",
  "iphone13pro",
  "iphone13promax",
  "iphone12mini",
  "iphone8",
  "ipadair2",
  "ipadair4thgeneration",
  "ipad9thgeneration",
  "ipadpro129inch5thgeneration",
  "ipadmini6thgeneration",
];

const androidDevices = [
  "pixel7",
  "nexus5",
  "nexus7",
  "nexus9",
  "pixel4",
  "pixel4xl",
  "pixel6pro",
  "galaxytabs7",
];

const kIosOsName = "iOS";
const kAndroidOsName = "Android";

// TODO get it from firestore
const kLanguages = [
  "ar_EG", // Arabic (Egypt)
  "ar_SA", // Arabic (Saudi Arabia)
  "bn_IN", // Bengali (India)
  "cs_CZ", // Czech (Czech Republic)
  "da_DK", // Danish (Denmark)
  "de_DE", // German (Germany)
  "el_GR", // Greek (Greece)
  "en_CA", // English (Canada)
  "en_GB", // English (United Kingdom)
  "en_US", // English (United States)
  "es_ES", // Spanish (Spain)
  "fr_FR", // French (France)
  "hi_IN", // Hindi (India)
  "hu_HU", // Hungarian (Hungary)
  "id_ID", // Indonesian (Indonesia)
  "it_IT", // Italian (Italy)
  "ja_JP", // Japanese (Japan)
  "ko_KR", // Korean (South Korea)
  "nl_NL", // Dutch (Netherlands)
  "pl_PL", // Polish (Poland)
  "pt_PT", // Portuguese (Portugal)
  "ro_RO", // Romanian (Romania)
  "ru_RU", // Russian (Russia)
  "sv_SE", // Swedish (Sweden)
  "ta_SG", // Tamil (Singapore)
  "te_IN", // Telugu (India)
  "th_TH", // Thai (Thailand)
  "tl_PH", // Tagalog (Philippines)
  "tr_TR", // Turkish (Turkey)
  "uk_UA", // Ukrainian (Ukraine)
  "vi_VN", // Vietnamese (Vietnam)
  "zh_CN", // Chinese Simplified (China)
];

const kOrientations = ["portrait", "landscape"];

const kDelayAtSessionStartTime = 15000;
const kEditorDelayAtSessionStartTime = 7000;

const kDefaultAppetizeOSVersions: AppetizeOSVersions = {
  ios: {
    iphone8: ["15.5", "16.2"],
    iphone8plus: ["15.5", "16.2"],
    iphone11pro: ["15.5", "16.2", "17.2", "18.0"],
    iphone12: ["15.5", "16.2", "17.2", "18.0"],
    iphone13pro: ["15.5", "16.2", "17.2", "18.0"],
    iphone13promax: ["15.5", "16.2", "17.2", "18.0"],
    iphone14pro: ["16.2", "17.2", "18.0"],
    iphone14promax: ["16.2", "17.2", "18.0"],
    iphone15pro: ["17.2", "18.0"],
    iphone15promax: ["17.2", "18.0"],
    iphone16pro: ["18.0"],
    iphone16promax: ["18.0"],
    ipadair4thgeneration: ["15.5", "16.2", "17.2", "18.0"],
    ipadpro129inch5thgeneration: ["15.5", "16.2", "17.2", "18.0"],
    ipad9thgeneration: ["15.5", "16.2", "17.2"],
    ipadmini6thgeneration: ["18.0"],
  },
  android: {
    nexus5: ["8.1", "9.0", "10.0", "11.0"],
    pixel4: ["10.0", "11.0", "12.0"],
    pixel4xl: ["10.0", "11.0", "12.0"],
    pixel6: ["12.0", "13.0", "14.0"],
    pixel6pro: ["12.0", "13.0", "14.0"],
    pixel7: ["13.0", "14.0"],
    pixel7pro: ["13.0", "14.0"],
    pixel8: ["14.0"],
    pixel8pro: ["14.0"],
    galaxytabs7: ["10.0", "11.0", "12.0", "13.0"],
  },
};

export {
  iosDevices,
  androidDevices,
  kIosOsName,
  kAndroidOsName,
  kLanguages,
  kDelayAtSessionStartTime,
  kDefaultAppetizeOSVersions,
  kOrientations,
  kEditorDelayAtSessionStartTime,
};
