import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { ReactElement } from "react";

const DetailModal = ({
  open,
  children,
  handleClose,
  title,
}: {
  open: boolean;
  children: ReactElement;
  handleClose: () => void;
  title: string;
}) => {
  return (
    <Dialog
      maxWidth={"lg"}
      fullWidth
      open={open}
      onClose={handleClose}
      scroll={"paper"}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailModal;
