import { PlainCard } from "./settings_components";
import Button from "@mui/material/Button";

import React, { useCallback, useState } from "react";
import { EnvVar, TestTemplate } from "../utils/types";
import { getCompanySettings } from "../utils/firebase_operations";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { timestampToDate, trackSentryException } from "../utils/helpers";
import { QueryClient } from "@tanstack/query-core";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
} from "@firebase/firestore";
import { customFirestore } from "../firebase";
import { Form, Formik } from "formik";
import { useAtomValue } from "jotai";
import { getTestsAtom } from "../atoms/testing-settings-atoms";
import FormikCheckbox from "../formikComponents/formik-checkbox";
import FormikTextInput from "../formikComponents/formik-text-input";
import { CircularProgress } from "@mui/material";
import { companyDataAtom } from "../routes/test-run/test-run-atoms";

const EditEnvVarDialog = ({
  envVarKey,
  savingHandler,
  affectedTests,
}: {
  envVarKey: string;
  savingHandler: (envVarKey: string, newValue: string) => Promise<void>;
  affectedTests: string[];
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <Button variant="outlined" onClick={toggleDialog} size={"large"}>
        Edit
      </Button>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div
            className="bg-black absolute top-0 left-0 w-full h-full opacity-40"
            onClick={toggleDialog}
          />
          <div className="bg-white rounded-lg z-50 relative">
            <div className="flex justify-between items-center border-b p-4">
              <h2 className="font-semibold text-lg">Enter New Value</h2>
            </div>
            <div
              className={
                "flex justify-between items-center border-b p-4 gap-x-4"
              }
            >
              <Formik
                initialValues={{ envVarValue: "" }}
                validate={(values) => {
                  const errors: { envVarValue?: string } = {};
                  if (!values.envVarValue) {
                    errors.envVarValue = "Required";
                  }
                  return errors;
                }}
                onSubmit={async (values) => {
                  if (affectedTests.length > 0) {
                    let message = `Editing this Env Var will affect ${affectedTests.length} test(s): \n`;
                    for (const title of affectedTests) {
                      message += title + "\n";
                    }
                    console.log("affected tests", affectedTests);
                    if (window.confirm(message)) {
                      toggleDialog();
                      await savingHandler(envVarKey, values.envVarValue);
                    }
                  } else {
                    toggleDialog();
                    await savingHandler(envVarKey, values.envVarValue);
                  }
                }}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <FormikTextInput
                      name={`envVarValue`}
                      label={"Value"}
                      placeholder={"Value"}
                      type={"text"}
                      required
                    />
                    <div className="pt-4 flex justify-end gap-x-2">
                      <Button
                        variant={"outlined"}
                        color={"error"}
                        onClick={toggleDialog}
                      >
                        Cancel
                      </Button>
                      <Button
                        type={"submit"}
                        disabled={!isValid || !dirty}
                        color={"primary"}
                        variant={"outlined"}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const AddEnvVarDialog = ({
  queryClient,
  companyId,
  envVars,
}: {
  queryClient: QueryClient;
  companyId: string | undefined;
  envVars: EnvVar[];
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  const handleSaving = useCallback(
    async (envVar: EnvVar) => {
      try {
        console.log("Saving", envVar);

        const settingsDoc = doc(customFirestore, "settings", companyId!);
        await updateDoc(settingsDoc, { envVars: arrayUnion(envVar) });

        await queryClient.invalidateQueries({
          queryKey: ["getEnvVars", companyId],
        });
        companyDataAtom.remove(companyId!);
        queryClient.removeQueries({
          queryKey: ["getCompanyData"],
        });
        toast.success(`Env var saved successfully`);
      } catch (e) {
        trackSentryException(e);
        alert(`An error occurred adding the env var: ${e}`);
        console.error("An error occurred adding the env var", e);
      }
    },
    [companyId, queryClient]
  );

  return (
    <div className="relative">
      <Button variant="outlined" onClick={toggleDialog} size={"large"}>
        + Add Env Variable
      </Button>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div
            className="bg-black absolute top-0 left-0 w-full h-full opacity-40"
            onClick={toggleDialog}
          />
          <div className="bg-white rounded-lg z-50 relative">
            <div className="flex justify-between items-center border-b p-4">
              <h2 className="font-semibold text-lg">Enter Env Variable</h2>
            </div>
            <div
              className={
                "flex justify-between items-center border-b p-4 gap-x-4"
              }
            >
              <Formik
                initialValues={{
                  envVarKey: "",
                  envVarValue: "",
                  envVarIsSecret: false,
                }}
                validate={(values) => {
                  const errors: { envVarKey?: string; envVarValue?: string } =
                    {};
                  if (!values.envVarKey) {
                    errors.envVarKey = "Required";
                  } else if (!values.envVarValue) {
                    errors.envVarValue = "Required";
                  } else if (
                    envVars.map((saved) => saved.key).includes(values.envVarKey)
                  ) {
                    errors.envVarKey = "Env Var with this key already exists";
                  }
                  return errors;
                }}
                onSubmit={async (values) => {
                  toggleDialog();
                  await handleSaving({
                    key: values.envVarKey,
                    value: values.envVarValue,
                    lastEditTimestamp: Math.floor(Date.now() / 1000),
                    isSecret: values.envVarIsSecret,
                  });
                }}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <div className={"flex gap-3"}>
                      <FormikTextInput
                        name={`envVarKey`}
                        label={"Key"}
                        placeholder={"Key"}
                        type={"text"}
                        required
                      />
                      <FormikTextInput
                        name={`envVarValue`}
                        label={"Value"}
                        placeholder={"Value"}
                        type={"text"}
                        required
                      />
                    </div>
                    <div className={"pl-0.5 pt-2"}>
                      <FormikCheckbox
                        name={`envVarIsSecret`}
                        label={
                          "Secure Entry: Omit Value from Interfaces and Logs"
                        }
                        type={"checkbox"}
                      />
                    </div>
                    <div className="pt-4 flex justify-end gap-x-2">
                      <Button
                        variant={"outlined"}
                        color={"error"}
                        onClick={toggleDialog}
                      >
                        Cancel
                      </Button>
                      <Button
                        type={"submit"}
                        disabled={!isValid || !dirty}
                        color={"primary"}
                        variant={"outlined"}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const EnvVarsPanel = ({ companyId }: { companyId: string | undefined }) => {
  const [envVars, setEnvVars] = useState<EnvVar[]>([]);

  const testListQuery = useAtomValue(
    getTestsAtom({ organizationId: companyId! })
  );

  const queryClient = useQueryClient();

  const envVarsQuery = useQuery({
    queryKey: ["getEnvVars", companyId],
    queryFn: async () => {
      const firestoreSettings = await getCompanySettings(companyId);

      if (firestoreSettings) {
        const envVariables = (firestoreSettings.envVars as EnvVar[]) ?? [];
        console.log({ envVariables });
        setEnvVars(envVariables);
        return envVariables;
      } else {
        return [];
      }
    },
    refetchOnWindowFocus: false,
  });

  const getListOfAffectedTests = useCallback(
    (envVarKey: string) => {
      const testsList = testListQuery.data?.values;
      return (
        testsList
          ?.filter((test) => test.commands.includes(`{{env.${envVarKey}}}`))
          .map((test) => test.title) ?? []
      );
    },
    [testListQuery]
  );

  const handleEditing = useCallback(
    async (envVarKey: string, newValue: string) => {
      try {
        const settingsDoc = doc(customFirestore, "settings", companyId!);
        const envVars: EnvVar[] =
          (await getDoc(settingsDoc)).data()?.envVars ?? [];
        const foundEnvVar = envVars.find((envVar) => envVar.key == envVarKey);

        if (foundEnvVar) {
          const updatedEnvVar: EnvVar = {
            key: envVarKey,
            value: newValue,
            lastEditTimestamp: Math.floor(Date.now() / 1000),
            isSecret: foundEnvVar.isSecret ?? false,
          };
          await updateDoc(settingsDoc, { envVars: arrayRemove(foundEnvVar) });
          await updateDoc(settingsDoc, { envVars: arrayUnion(updatedEnvVar) });
          await queryClient.invalidateQueries({
            queryKey: ["getEnvVars", companyId],
          });
          companyDataAtom.remove(companyId!);
          queryClient.removeQueries({
            queryKey: ["getCompanyData"],
          });
          toast.success(`Env var "${envVarKey}" updated successfully`);
        } else {
          toast.error(`Env Var with key ${envVarKey} not found`);
        }
      } catch (error: any) {
        trackSentryException(error);
        console.error("An error occurred during editing", error);
        toast.error("An error occurred during editing");
      }
    },
    [envVars, queryClient, companyId]
  );

  const handleDeleting = useCallback(
    async (envVarKey: string) => {
      try {
        console.log(`Deleting env var ${envVarKey} for company ${companyId}`);
        const settingsDoc = doc(customFirestore, "settings", companyId!);
        const envVars: EnvVar[] =
          (await getDoc(settingsDoc)).data()?.envVars ?? [];
        const foundEnvVar = envVars.find((envVar) => envVar.key == envVarKey);

        if (foundEnvVar) {
          await updateDoc(settingsDoc, { envVars: arrayRemove(foundEnvVar) });
          await queryClient.invalidateQueries({
            queryKey: ["getEnvVars", companyId],
          });
          companyDataAtom.remove(companyId!);
          queryClient.removeQueries({
            queryKey: ["getCompanyData"],
          });
          toast.success(`Env var "${envVarKey}" deleted successfully`);
        } else {
          toast.error(`Env Var with key ${envVarKey} not found`);
        }
      } catch (error: any) {
        trackSentryException(error);
        console.error("An error occurred during deleting", error);
        toast.error("An error occurred during deleting");
      }
    },
    [envVars, queryClient, companyId]
  );

  return (
    <PlainCard>
      <div className="grid grid-cols-2">
        <h1 className="text-3xl pl-4 pb-2 font-bold">Env Variables</h1>
        <div className="place-self-end mt-2 pr-2">
          <AddEnvVarDialog
            queryClient={queryClient}
            companyId={companyId}
            envVars={envVars}
          />
        </div>
      </div>
      <div className="pl-4 pr-4 pt-4 relative sm:rounded-lg overflow-auto h-[calc(100%-50.25px)]">
        <table className="w-full text-sm text-left text-gray-600">
          <thead className="text-xs text-gray-700 uppercase bg-sky-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Key
              </th>
              <th scope="col" className="px-6 py-3">
                Value
              </th>
              <th scope="col" className="px-6 py-3">
                Last Edited At
              </th>
              <th scope="col" className="py-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {!envVarsQuery.isLoading && !testListQuery.isLoading && (
              <>
                {envVars
                  .sort((a, b) => a.key.localeCompare(b.key))
                  .map(
                    (
                      { key, value, lastEditTimestamp, isSecret },
                      index: number
                    ) => {
                      return (
                        <tr
                          key={index}
                          className="bg-white border-b hover:bg-sky-50"
                        >
                          <td className="px-6 py-4">{key}</td>
                          <td className="px-6 py-4">
                            <input
                              disabled={true}
                              type={isSecret === true ? "password" : "text"}
                              value={isSecret === true ? "******" : value}
                            />
                          </td>
                          <td className="px-6 py-4">
                            <p>{timestampToDate(lastEditTimestamp)}</p>
                          </td>
                          <td className="py-4">
                            <div className={"flex gap-x-2"}>
                              <EditEnvVarDialog
                                envVarKey={key}
                                savingHandler={handleEditing}
                                affectedTests={getListOfAffectedTests(key)}
                              />
                              <Button
                                variant={"outlined"}
                                onClick={async () => {
                                  const affectedTestsTitles =
                                    getListOfAffectedTests(key);
                                  let message = `Delete env var ${key}?`;
                                  if (affectedTestsTitles.length > 0) {
                                    message += `\nDeleting this env var will affect ${affectedTestsTitles.length} test(s): \n`;
                                    for (const title of affectedTestsTitles) {
                                      message += title + "\n";
                                    }
                                  }
                                  if (window.confirm(message)) {
                                    await handleDeleting(key);
                                  }
                                }}
                                color={"error"}
                              >
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
              </>
            )}
          </tbody>
        </table>
        {envVarsQuery.isLoading ||
          testListQuery.isLoading ||
          envVarsQuery.isError ||
          (testListQuery.isError && (
            <div
              className={
                "flex flex-col h-full items-center justify-center w-full"
              }
            >
              {(envVarsQuery.isLoading || testListQuery.isLoading) && (
                <CircularProgress />
              )}
              {(envVarsQuery.isError || testListQuery.isError) && (
                <p className="mt-4 flex text-center font-semibold text-red-400">
                  An error occurred while fetching Env Variables:{" "}
                  {envVarsQuery.error && (envVarsQuery.error as any)?.message}
                  {testListQuery.error && (testListQuery.error as any)?.message}
                </p>
              )}
            </div>
          ))}
      </div>
    </PlainCard>
  );
};

export { EnvVarsPanel };
