import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { DateTime } from "luxon";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { Session } from "../../utils/types";
import { customFirestore } from "../../firebase";
import { AuthContext } from "../../auth/AuthContext";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  styled as styledMaterial,
  TableRow,
  Button,
} from "@mui/material";
import { TableVirtuoso, TableComponents } from "react-virtuoso";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { AutoAwesome } from "@mui/icons-material";

const SessionsPage = () => {
  const { user } = useContext<any>(AuthContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Session[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const firebaseQuery = query(
      collection(customFirestore, `sessions`),
      where("organisationId", "==", user.companyId),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(firebaseQuery, (snapshot) => {
      if (snapshot.empty) {
        setData([]);
      }

      const sessions = snapshot.docs.map((session) => ({
        ...session.data(),
        id: session.id,
      })) as Session[];

      setLoading(false);
      setData(sessions);
    });
    return () => {
      unsubscribe();
    };
  }, [user.companyId]);

  return (
    <div className="flex-1 flex flex-col bg-[#D9D9D9] h-full">
      <div className="flex flex-row flex-1 h-[calc(100vh_-_60px)]">
        {loading && (
          <div
            className={
              "flex flex-col h-full items-center justify-center w-full"
            }
          >
            <CircularProgress />
          </div>
        )}
        {error && <p>Error: {error}</p>}
        {!loading && !error && data != null && (
          <ReportSuccess sessions={data} />
        )}
      </div>
    </div>
  );
};

function ReportSuccess({ sessions }: { sessions: Session[] }) {
  const virtuosoTableComponents = useMemo<TableComponents<Session>>(() => {
    return {
      Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
      )),
      Table: (props) => (
        <Table
          sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
          {...props}
        />
      ),
      TableHead,
      TableRow: ({ item: _item, ...props }) => (
        <RowContent {...props} sessionData={_item} />
      ),
      TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => {
        if (sessions.length === 0) {
          return (
            <div
              className={
                "absolute w-full h-[calc(100%_-_57px)] top-1/2 bottom-1/2"
              }
            >
              <p className={"text-center text-[14px]"}>
                No test runs in the gpt driver client have been started yet
              </p>
            </div>
          );
        }
        return <TableBody {...props} ref={ref}></TableBody>;
      }),
    };
  }, [sessions]);

  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <ReportsSuccessPageWrapper>
      <ReportsSuccessPageHeader>
        <p className={"text-[24px] font-medium"}>Sessions</p>
        <Button
          startIcon={<AutoAwesome />}
          variant={"contained"}
          size={"large"}
          onClick={() => {
            navigateTo("/gpt-driver/sessions/assistant");
          }}
        >
          Assistant
        </Button>
      </ReportsSuccessPageHeader>
      <TableVirtuoso
        data={sessions}
        components={virtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
      />
    </ReportsSuccessPageWrapper>
  );
}

const ReportsSuccessPageHeader = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
`;

const ReportsSuccessPageWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "sessions";
  width: 100%;
  grid-template-rows: auto 1fr;
  padding: 16px;
  margin: 16px;
  background-color: #e8e8e8;
  border-radius: 8px;
  gap: 24px;
`;

const columns = [
  {
    field: "createdAt",
    headerName: "Time",
    filterable: false,
    sortable: false,
  },
  {
    field: "platform",
    headerName: "Platform",
    filterable: true,
    sortable: true,
  },
  {
    field: "os",
    headerName: "OS",
    filterable: true,
    sortable: true,
  },
  {
    field: "device",
    headerName: "Device",
    filterable: true,
    sortable: true,
  },
  {
    field: "status",
    headerName: "Status",
    filterable: true,
    sortable: true,
  },
];

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.field}
          variant="head"
          sx={{
            backgroundColor: "background.paper",
          }}
        >
          {column.headerName}
        </TableCell>
      ))}
    </TableRow>
  );
}

function RowContent(props: { sessionData: Session }) {
  const { sessionData, ...rest } = props;

  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <TableRow
      className={"cursor-pointer"}
      {...rest}
      onClick={() => {
        navigateTo(`/gpt-driver/sessions/${sessionData.id}`);
      }}
    >
      <TableCell key={"createdAt"}>
        {DateTime.fromMillis(sessionData.createdAt.toMillis()).toFormat(
          "yyyy-MM-dd hh:mm a"
        )}
      </TableCell>
      <TableCell key={"platform"}>
        {sessionData?.deviceConfig?.platform}
      </TableCell>
      <TableCell key={"os"}>{sessionData?.deviceConfig?.os}</TableCell>
      <TableCell key={"device"}>{sessionData?.deviceConfig?.device}</TableCell>
      <StatusTableCell status={sessionData.status} key={"status"}>
        {sessionData.status}
      </StatusTableCell>
    </TableRow>
  );
}

const StatusTableCell = styledMaterial(TableCell)(({ theme, status }) => ({
  color:
    status === "success"
      ? theme.palette.success.main
      : status === "failed"
      ? theme.palette.error.main
      : theme.palette.text.primary,
}));

export default SessionsPage;
