import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  GridActionsCellItem,
  type GridColDef,
  type GridRenderCellParams,
  type GridRowId,
} from "@mui/x-data-grid";
import CursorPaginatedDatatable from "../../components/cursor-paginated-datatable";
import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { customFirestore } from "../../firebase";
import { useCallback, useContext, useMemo, useState } from "react";
import { AuthContext } from "../../auth/AuthContext";
import TestingAppBar from "../../components/testing_app_bar";
import { BuildUploadData, type PaginatedResponse } from "../../utils/types";
import {
  Android,
  Apple,
  Delete,
  DeleteOutline,
  PriorityHigh,
  Window,
} from "@mui/icons-material";
import { type GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import UploadBuild from "./upload-build";
import Box from "@mui/material/Box";
import { Avatar, Tooltip } from "@mui/material";
import { DateTime, Interval } from "luxon";
import { grey } from "@mui/material/colors";
import { callDeleteUploadAPI, trackSentryException } from "../../utils/helpers";
import { AsyncConfirmationModal } from "../../components/asyncConfirmationModal";
import { useSetAtom } from "jotai/index";
import { customAlertStateAtom } from "../../components/custom-alert";
import styled from "@emotion/styled";
import { styled as muiStyled } from "@mui/material/styles";

const PAGE_SIZE = 10;

const BuildsHome = () => {
  const mapPageToNextCursor = React.useRef<Record<number, GridRowId>>({});
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const { user } = useContext<any>(AuthContext);

  const queryOptions = React.useMemo(
    () => ({
      cursor: mapPageToNextCursor.current[paginationModel.page - 1],
      pageSize: paginationModel.pageSize,
      companyId: user.companyId,
    }),
    [paginationModel, user.companyId]
  );

  const queryResults = useQuery({
    queryKey: ["getPaginatedBuilds", queryOptions],
    queryFn: async () => {
      const testsRef = collection(customFirestore, "uploads");

      const q = query(
        testsRef,
        where("organisationId", "==", queryOptions.companyId),
        orderBy("epoch", "desc"),
        ...(queryOptions.cursor != null
          ? [startAfter(queryOptions.cursor)]
          : []),
        limit(queryOptions.pageSize)
      );

      const querySnapshots = await getDocs(q);

      const data = querySnapshots.docs.map((doc) => {
        const data = doc.data() as BuildUploadData;
        data.id = doc.id;
        return data;
      });

      const countQuery = query(
        testsRef,
        where("organisationId", "==", queryOptions.companyId)
      );

      const countQuerySnapshot = await getCountFromServer(countQuery);

      return {
        pageInfo: {
          totalRowCount: countQuerySnapshot.data().count,
          nextCursor:
            querySnapshots.docs.length > 0 ? querySnapshots.docs.at(-1) : null,
        },
        data,
      } as PaginatedResponse<BuildUploadData>;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const [deleteBuildDialogState, setDeleteBuildDialogState] =
    useState<BuildUploadData | null>(null);

  const handleDeleteBuild = useCallback(
    (uploadObject: BuildUploadData) => {
      setDeleteBuildDialogState(uploadObject);
    },
    [setDeleteBuildDialogState]
  );

  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  const queryClient = useQueryClient();

  const resetDeleteBuildDialogState = useCallback(() => {
    setDeleteBuildDialogState(null);
  }, []);

  const deleteBuildMutation = useMutation({
    mutationKey: ["deleteBuildMutation"],
    mutationFn: async () => {
      await callDeleteUploadAPI(deleteBuildDialogState!.id);
    },
    onError: (error) => {
      console.error("Error deleting", error);
      trackSentryException(error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: `${error}`,
      });
    },
    onSuccess: async () => {
      setCustomAlertState({
        open: true,
        type: "success",
        title: "Build has been deleted successfully",
      });
      await queryClient.invalidateQueries({ queryKey: ["getPaginatedBuilds"] });
    },
    onSettled: () => {
      resetDeleteBuildDialogState();
    },
  });

  const tableConfig = useMemo(() => {
    const currentDate = DateTime.now();
    const relativeDatesInterval = Interval.fromDateTimes(
      currentDate.minus({ day: 6 }),
      currentDate
    );

    return {
      columns: [
        {
          field: "app_name",
          headerName: "App",
          sortable: false,
          width: 360,
          filterable: false,
          renderCell: (params: GridRenderCellParams<BuildUploadData>) => {
            const [errorLoadingIcon, setErrorLoadingIcon] = useState(false);

            return (
              <div className={"flex w-full gap-4 items-center"}>
                {errorLoadingIcon ? (
                  <Avatar sx={{ bgcolor: grey.A200, width: 32, height: 32 }}>
                    <PriorityHigh />
                  </Avatar>
                ) : params.row?.is_group === true ? (
                  <Window className={"ml-1"} />
                ) : (
                  <img
                    src={params.row.iconUrl}
                    className={"h-8 w-8"}
                    onError={({ currentTarget }) => {
                      console.log("error loading icon", currentTarget);
                      currentTarget.onerror = null; // prevents looping
                      setErrorLoadingIcon(true);
                    }}
                  />
                )}
                <p>
                  {params.row.status === "processed"
                    ? params.value
                    : params.row.status === "error"
                    ? "Unknown"
                    : "Processing..."}
                </p>
              </div>
            );
          },
        },
        {
          field: "bundle",
          headerName: "Bundle",
          sortable: false,
          width: 300,
          filterable: false,
          renderCell: (params: GridRenderCellParams<BuildUploadData>) => {
            return (
              <div>
                {params.row.status === "processed"
                  ? params.value
                      .split("\n")
                      .map((line: string, index: number) => (
                        <p key={index}>{line}</p>
                      ))
                  : params.row.status === "error"
                  ? "Unknown"
                  : "Processing..."}
              </div>
            );
          },
        },
        {
          field: "appVersionName",
          headerName: "Version",
          sortable: false,
          filterable: false,
          renderCell: (params: GridRenderCellParams<BuildUploadData>) => {
            return (
              <div>
                {params.value.split("\n").map((line: string, index: number) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            );
          },
        },
        {
          field: "appVersionCode",
          headerName: "Code",
          sortable: false,
          filterable: false,
          renderCell: (params: GridRenderCellParams<BuildUploadData>) => {
            return (
              <div>
                {params.value.split("\n").map((line: string, index: number) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            );
          },
        },
        {
          field: "id",
          headerName: "Build Id",
          sortable: false,
          filterable: false,
          width: 224,
        },
        {
          field: "platform",
          headerName: "Platform",
          sortable: false,
          filterable: false,

          renderCell: (params: GridRenderCellParams<BuildUploadData>) => {
            const isAndroid = params.value.trim().toLowerCase() === "android";
            return (
              <div className={"flex justify-center w-full items-center h-full"}>
                <Tooltip title={isAndroid ? "Android" : "iOS"}>
                  {isAndroid ? <Android /> : <Apple />}
                </Tooltip>
              </div>
            );
          },
          width: 80,
        },
        {
          field: "epoch",
          headerName: "Uploaded At",
          sortable: false,
          filterable: false,
          valueGetter: (value) => {
            return DateTime.fromMillis(value * 1000);
          },
          renderCell: (params: GridRenderCellParams<BuildUploadData>) => {
            return (
              <div
                className={"h-full flex flex-col items-start justify-center"}
              >
                {relativeDatesInterval.contains(params.value) ? (
                  <Tooltip title={params.value.toFormat("yyyy-MM-dd hh:mm a")}>
                    <p className={"text-xs w-full"}>
                      {params.value.toRelative()}
                    </p>
                  </Tooltip>
                ) : (
                  <p className={"text-xs"}>
                    {params.value.toFormat("yyyy-MM-dd hh:mm a")}
                  </p>
                )}
              </div>
            );
          },
          flex: 1,
        },

        {
          field: "actions",
          type: "actions",
          getActions: ({ row }: { row: BuildUploadData }) => [
            <GridActionsCellItem
              icon={<DeleteOutline />}
              label="Delete"
              onClick={() => handleDeleteBuild(row)}
              color="inherit"
            />,
          ],
          width: 50,
        },
      ] as GridColDef[],
      initialState: {
        sorting: {
          sortModel: [{ field: "title", sort: "asc" }],
        },
        columns: {
          columnVisibilityModel: {
            createdAt: false,
          },
        },
      } as GridInitialStateCommunity,
    };
  }, [queryResults]);

  return (
    <>
      <AsyncConfirmationModal
        title={"Are you sure you want to delete the build?"}
        description={`Build: ${deleteBuildDialogState?.app_name} ${deleteBuildDialogState?.appVersionName} - ${deleteBuildDialogState?.appVersionCode}`}
        actionTitle={"Delete"}
        backTitle={"Close"}
        open={deleteBuildDialogState != null}
        actionHandler={deleteBuildMutation.mutateAsync}
        actionIcon={<Delete />}
        closeHandler={resetDeleteBuildDialogState}
        submitting={deleteBuildMutation.isPending}
      />
      <ContentWrapper>
        <PageTitle>Builds</PageTitle>
        <ActionButton>
          <UploadBuild />
        </ActionButton>
        <TableWrapper>
          <CursorPaginatedDatatable
            queryResults={queryResults}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            mapPageToNextCursor={mapPageToNextCursor}
            columns={tableConfig.columns}
            noRowsTitle={"No builds uploaded yet"}
          />
        </TableWrapper>
      </ContentWrapper>
    </>
  );
};

const TableWrapper = styled.div`
  grid-area: datatable;
  overflow: auto;
  height: 100%;
`;

const PageTitle = styled.p`
  grid-area: title;
  font-size: 24px;
  font-weight: 500;
`;

const ActionButton = styled.div(() => ({
  gridArea: "actions",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-start",
}));

const ContentWrapper = styled.div`
  grid-area: content;
  display: grid;
  grid-template-areas:
    "title actions"
    "datatable datatable";
  column-gap: 24px;
  grid-template-columns: 80px 1fr;
  grid-template-rows: 80px 1fr;
  background-color: #e8e8e8;
  padding: 16px;
  border-radius: 8px;
  margin: 16px;
  height: calc(100% - 32px);
`;

export default BuildsHome;
