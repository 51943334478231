import { Divider } from "@mui/material";
import React from "react";

const SectionDivider = ({ title }: { title: string }) => {
  return (
    <Divider
      sx={{
        "::before": { width: "2.5%" },
        ".MuiDivider-wrapper": { fontSize: 18, fontWeight: 600 },
      }}
      textAlign="left"
    >
      {title}
    </Divider>
  );
};

export default SectionDivider;
