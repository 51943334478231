import { atomWithReset } from "jotai/utils";
import { Node } from "prosemirror-model";
import { getTipTapSchema } from "../utils/helpers";
import { v4 as uuidv4 } from "uuid";

const defaultTest = Node.fromJSON(getTipTapSchema(), {
  type: "doc",
  content: [
    {
      type: "orderedList",
      attrs: { start: 1 },
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: { id: uuidv4() },
              content: [],
            },
          ],
        },
      ],
    },
  ],
});

interface TestEditorPropertiesType {
  originalTestCommands: Node;
  testCommands: Node;
  recordingTitle: string;
  originalRecordingTitle: string;
  hasGeneratedEnrichedLogs: boolean;
}

const testEditorPropertiesAtom = atomWithReset<TestEditorPropertiesType>({
  originalTestCommands: defaultTest,
  testCommands: defaultTest,
  recordingTitle: "Untitled",
  originalRecordingTitle: "",
  hasGeneratedEnrichedLogs: false,
});

export { testEditorPropertiesAtom };

export type { TestEditorPropertiesType };
