import { styled as materialStyled } from "@mui/material/styles";
import { Alert, AlertTitle, Paper, Typography } from "@mui/material";
import { Session as SessionType } from "../../utils/types";
import React, { useMemo } from "react";
import { DateTime } from "luxon";
import styled from "@emotion/styled";

const Info = ({ session }: { session: SessionType }) => {
  const parsedStatus = useMemo(() => {
    const status = session.status;
    if (status === "failed") {
      return "Failed";
    } else if (status == "inProgress") {
      return "In progress";
    }
    return "Succeeded";
  }, [session]);

  const titleSeverity = useMemo(() => {
    const status = session.status;
    if (status === "failed") {
      return "error";
    } else if (status == "inProgress") {
      return "info";
    }
    return "success";
  }, [session]);

  const statusMessage = useMemo(() => {
    if (session.status != "inProgress") {
      const lastCommand = session.interactionLogs
        ?.at(-1)
        ?.gptCommands?.at(0)
        ?.toLowerCase();

      return lastCommand?.startsWith("error detected") ||
        lastCommand?.startsWith("task complete")
        ? lastCommand
        : undefined;
    }
  }, [session]);

  return (
    <InfoWrapper variant={"outlined"}>
      <CustomAlert hasDescription={!!statusMessage} severity={titleSeverity}>
        <AlertTitle>{parsedStatus}</AlertTitle>
        {statusMessage && statusMessage}
      </CustomAlert>
      <SessionProperties>
        <Typography variant={"body1"}>Platform</Typography>
        <Typography variant={"body2"}>
          {session.deviceConfig.platform}
        </Typography>
        <Typography variant={"body1"}>Device</Typography>
        <Typography variant={"body2"}>{session.deviceConfig.device}</Typography>
        <Typography variant={"body1"}>OS</Typography>
        <Typography variant={"body2"}>{session.deviceConfig.os}</Typography>
        {/*<Typography variant={"body1"}>Language</Typography>*/}
        {/*<Typography variant={"body2"}>en_US</Typography>*/}
        {/*<Typography variant={"body1"}>App Version Code</Typography>*/}
        {/*<Typography variant={"body2"}>12312312</Typography>*/}
        {/*<Typography variant={"body1"}>App Version Name</Typography>*/}
        {/*<Typography variant={"body2"}>8.9.52.552</Typography>*/}
        {/*<Typography variant={"body1"}>App Name</Typography>*/}
        {/*<Typography variant={"body2"}>Spotify</Typography>*/}
        {/*<Typography variant={"body1"}>App Bundle</Typography>*/}
        {/*<Typography variant={"body2"}>com.spotify.music</Typography>*/}
        <Typography variant={"body1"}>Started at</Typography>
        <Typography variant={"body2"}>
          {DateTime.fromMillis(session!.createdAt.toMillis()).toFormat(
            "yyyy-MM-dd hh:mm:ss a"
          )}
        </Typography>
        <Typography variant={"body1"}>Finished at</Typography>
        <Typography variant={"body2"}>
          {DateTime.fromMillis(session!.lastUpdatedAt.toMillis()).toFormat(
            "yyyy-MM-dd hh:mm:ss a"
          )}
        </Typography>
      </SessionProperties>
    </InfoWrapper>
  );
};

const CustomAlert = materialStyled(Alert)(
  ({ hasDescription }: { hasDescription: boolean }) => ({
    ...(!hasDescription && { paddingBottom: "0px" }),
    borderRadius: "3px",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  })
);

const SessionProperties = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 4px;
  grid-template-columns: 1.5fr 2fr;
  padding: 16px;
  align-items: center;
  overflow-y: auto;
`;

const InfoWrapper = materialStyled(Paper)(() => ({
  gridArea: "info",
  display: "flex",
  flexDirection: "column",
}));

export default Info;
